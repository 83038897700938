/**
 * Settings provide general overarching settings split up in various specialzied partials
 *
 * @author christian.nies@uxi.de
 */
/* Base colors */
/**
 * _functions.scss partial
 *
 * Contains global functions.
 */
/**
 * Importing _family
 *
 * Family.scss is a set of Sass mixins which will help you to manage the style of :nth-child"ified elements, in an easy
 * way.
 *
 * @see https://lukyvj.github.io/family.scss/
 */
/**
 * _tools.scss partial
 *
 * Contains global mixins and functions.
 *
 * @author christian.nies@uxi.de
 */
/**
 * _generic.scss partial
 *
 * Contains general imports for global usaged. First layer to output CSS
 *
 * @author christian.nies@uxi.de
 */
/* apply a natural box layout model to all elements, but allowing components to change */
/* @see https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
html {
  box-sizing: border-box;
  font-size: 16px;
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
  -webkit-tap-highlight-color: transparent;
}
html.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */ /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

@media (max-width: 320px) {
  html {
    font-size: 14px;
  }
}
/**
 * Apply appropriate box sizing
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Importing helpers
 */
/**
 * helpers includes generic helpers for global use
 */
.centered {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.mobile-only {
  display: none;
  visibility: hidden;
}

@media (min-width: 321px) {
  .mobile-only {
    display: block;
    visibility: visible;
  }
}
.desktop-only {
  display: block;
  visibility: visible;
}

@media (max-width: 768px) {
  .desktop-only {
    display: none !important;
    visibility: hidden !important;
  }
}
.nowrap {
  white-space: nowrap;
}

.alignRight {
  margin-left: auto;
}

.centeredHV {
  display: flex;
  align-items: center;
}

.line-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.grad-metro-blue {
  background-image: linear-gradient(to bottom, #1b50a0 0%, #002d72 100%);
}

.grad-metro-blue-light {
  background-image: linear-gradient(to bottom, #f7faff 0%, #f2f7ff 100%);
}

.grad-blue {
  background-image: linear-gradient(to bottom right, #66a2fe 0%, #0064fe 100%);
}

.grad-green {
  background-image: linear-gradient(to bottom right, #85d9b4 0%, #34c082 100%);
}

/**
 * Importing font stack
 */
/* lato-300 - latin */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/lato-v22-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/Lato/lato-v22-latin-300.eot?#iefix") format("embedded-opentype"), url("/fonts/Lato/lato-v22-latin-300.woff2") format("woff2"), url("/fonts/Lato/lato-v22-latin-300.woff") format("woff"), url("/fonts/Lato/lato-v22-latin-300.ttf") format("truetype"), url("/fonts/Lato/lato-v22-latin-300.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/lato-v22-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/Lato/lato-v22-latin-regular.eot?#iefix") format("embedded-opentype"), url("/fonts/Lato/lato-v22-latin-regular.woff2") format("woff2"), url("/fonts/Lato/lato-v22-latin-regular.woff") format("woff"), url("/fonts/Lato/lato-v22-latin-regular.ttf") format("truetype"), url("/fonts/Lato/lato-v22-latin-regular.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-300italic - latin */
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 300;
  src: url("/fonts/Lato/lato-v22-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/Lato/lato-v22-latin-300italic.eot?#iefix") format("embedded-opentype"), url("/fonts/Lato/lato-v22-latin-300italic.woff2") format("woff2"), url("/fonts/Lato/lato-v22-latin-300italic.woff") format("woff"), url("/fonts/Lato/lato-v22-latin-300italic.ttf") format("truetype"), url("/fonts/Lato/lato-v22-latin-300italic.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-italic - latin */
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/Lato/lato-v22-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/Lato/lato-v22-latin-italic.eot?#iefix") format("embedded-opentype"), url("/fonts/Lato/lato-v22-latin-italic.woff2") format("woff2"), url("/fonts/Lato/lato-v22-latin-italic.woff") format("woff"), url("/fonts/Lato/lato-v22-latin-italic.ttf") format("truetype"), url("/fonts/Lato/lato-v22-latin-italic.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-700italic - latin */
@font-face {
  font-family: Lato;
  font-style: italic;
  font-weight: 700;
  src: url("/fonts/Lato/lato-v22-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/Lato/lato-v22-latin-700italic.eot?#iefix") format("embedded-opentype"), url("/fonts/Lato/lato-v22-latin-700italic.woff2") format("woff2"), url("/fonts/Lato/lato-v22-latin-700italic.woff") format("woff"), url("/fonts/Lato/lato-v22-latin-700italic.ttf") format("truetype"), url("/fonts/Lato/lato-v22-latin-700italic.svg#Lato") format("svg"); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/lato-v22-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/Lato/lato-v22-latin-700.eot?#iefix") format("embedded-opentype"), url("/fonts/Lato/lato-v22-latin-700.woff2") format("woff2"), url("/fonts/Lato/lato-v22-latin-700.woff") format("woff"), url("/fonts/Lato/lato-v22-latin-700.ttf") format("truetype"), url("/fonts/Lato/lato-v22-latin-700.svg#Lato") format("svg"); /* Legacy iOS */
}
/**
 * Importing colorsystem
 */
/**
 * Creates css classes for all colors defined in __settings:
 *
 *  e.g. `.blue` for blue text color and `.bg-blue` for a blue background.
 *
 */
.metro-blue {
  color: #002d72 !important;
}

.bg-metro-blue {
  background-color: #002d72 !important;
}

.border-metro-blue {
  border-color: #002d72 !important;
}

.metro-blue-tint-20 {
  color: #33578e !important;
}

.bg-metro-blue-tint-20 {
  background-color: #33578e !important;
}

.border-metro-blue-tint-20 {
  border-color: #33578e !important;
}

.metro-blue-tint-40 {
  color: #6681aa !important;
}

.bg-metro-blue-tint-40 {
  background-color: #6681aa !important;
}

.border-metro-blue-tint-40 {
  border-color: #6681aa !important;
}

.metro-blue-tint-60 {
  color: #99abc7 !important;
}

.bg-metro-blue-tint-60 {
  background-color: #99abc7 !important;
}

.border-metro-blue-tint-60 {
  border-color: #99abc7 !important;
}

.metro-blue-tint-80 {
  color: #ccd5e3 !important;
}

.bg-metro-blue-tint-80 {
  background-color: #ccd5e3 !important;
}

.border-metro-blue-tint-80 {
  border-color: #ccd5e3 !important;
}

.metro-blue-tint-88 {
  color: #e0e6ee !important;
}

.bg-metro-blue-tint-88 {
  background-color: #e0e6ee !important;
}

.border-metro-blue-tint-88 {
  border-color: #e0e6ee !important;
}

.metro-blue-tint-90 {
  color: #e6eaf1 !important;
}

.bg-metro-blue-tint-90 {
  background-color: #e6eaf1 !important;
}

.border-metro-blue-tint-90 {
  border-color: #e6eaf1 !important;
}

.metro-blue-tint-92 {
  color: #ebeef4 !important;
}

.bg-metro-blue-tint-92 {
  background-color: #ebeef4 !important;
}

.border-metro-blue-tint-92 {
  border-color: #ebeef4 !important;
}

.metro-blue-tint-95 {
  color: #f2f5f8 !important;
}

.bg-metro-blue-tint-95 {
  background-color: #f2f5f8 !important;
}

.border-metro-blue-tint-95 {
  border-color: #f2f5f8 !important;
}

.metro-blue-tint-96 {
  color: #f5f7f9 !important;
}

.bg-metro-blue-tint-96 {
  background-color: #f5f7f9 !important;
}

.border-metro-blue-tint-96 {
  border-color: #f5f7f9 !important;
}

.metro-blue-shade-20 {
  color: #00245b !important;
}

.bg-metro-blue-shade-20 {
  background-color: #00245b !important;
}

.border-metro-blue-shade-20 {
  border-color: #00245b !important;
}

.metro-blue-shade-40 {
  color: #001b44 !important;
}

.bg-metro-blue-shade-40 {
  background-color: #001b44 !important;
}

.border-metro-blue-shade-40 {
  border-color: #001b44 !important;
}

.metro-blue-shade-60 {
  color: #00122e !important;
}

.bg-metro-blue-shade-60 {
  background-color: #00122e !important;
}

.border-metro-blue-shade-60 {
  border-color: #00122e !important;
}

.metro-yellow {
  color: #ffed00 !important;
}

.bg-metro-yellow {
  background-color: #ffed00 !important;
}

.border-metro-yellow {
  border-color: #ffed00 !important;
}

.metro-yellow-tint-20 {
  color: #fff133 !important;
}

.bg-metro-yellow-tint-20 {
  background-color: #fff133 !important;
}

.border-metro-yellow-tint-20 {
  border-color: #fff133 !important;
}

.metro-yellow-tint-40 {
  color: #fff466 !important;
}

.bg-metro-yellow-tint-40 {
  background-color: #fff466 !important;
}

.border-metro-yellow-tint-40 {
  border-color: #fff466 !important;
}

.metro-yellow-tint-60 {
  color: #fff899 !important;
}

.bg-metro-yellow-tint-60 {
  background-color: #fff899 !important;
}

.border-metro-yellow-tint-60 {
  border-color: #fff899 !important;
}

.metro-yellow-tint-80 {
  color: #fffbcc !important;
}

.bg-metro-yellow-tint-80 {
  background-color: #fffbcc !important;
}

.border-metro-yellow-tint-80 {
  border-color: #fffbcc !important;
}

.metro-yellow-tint-88 {
  color: #fffde0 !important;
}

.bg-metro-yellow-tint-88 {
  background-color: #fffde0 !important;
}

.border-metro-yellow-tint-88 {
  border-color: #fffde0 !important;
}

.metro-yellow-tint-90 {
  color: #fffde6 !important;
}

.bg-metro-yellow-tint-90 {
  background-color: #fffde6 !important;
}

.border-metro-yellow-tint-90 {
  border-color: #fffde6 !important;
}

.metro-yellow-tint-92 {
  color: #fffeeb !important;
}

.bg-metro-yellow-tint-92 {
  background-color: #fffeeb !important;
}

.border-metro-yellow-tint-92 {
  border-color: #fffeeb !important;
}

.metro-yellow-tint-95 {
  color: #fffef2 !important;
}

.bg-metro-yellow-tint-95 {
  background-color: #fffef2 !important;
}

.border-metro-yellow-tint-95 {
  border-color: #fffef2 !important;
}

.metro-yellow-tint-96 {
  color: #fffef5 !important;
}

.bg-metro-yellow-tint-96 {
  background-color: #fffef5 !important;
}

.border-metro-yellow-tint-96 {
  border-color: #fffef5 !important;
}

.blue {
  color: #0064fe !important;
}

.bg-blue {
  background-color: #0064fe !important;
}

.border-blue {
  border-color: #0064fe !important;
}

.blue-tint-20 {
  color: #3383fe !important;
}

.bg-blue-tint-20 {
  background-color: #3383fe !important;
}

.border-blue-tint-20 {
  border-color: #3383fe !important;
}

.blue-tint-40 {
  color: #66a2fe !important;
}

.bg-blue-tint-40 {
  background-color: #66a2fe !important;
}

.border-blue-tint-40 {
  border-color: #66a2fe !important;
}

.blue-tint-60 {
  color: #99c1ff !important;
}

.bg-blue-tint-60 {
  background-color: #99c1ff !important;
}

.border-blue-tint-60 {
  border-color: #99c1ff !important;
}

.blue-tint-80 {
  color: #cce0ff !important;
}

.bg-blue-tint-80 {
  background-color: #cce0ff !important;
}

.border-blue-tint-80 {
  border-color: #cce0ff !important;
}

.blue-tint-88 {
  color: #e0ecff !important;
}

.bg-blue-tint-88 {
  background-color: #e0ecff !important;
}

.border-blue-tint-88 {
  border-color: #e0ecff !important;
}

.blue-tint-90 {
  color: #e6f0ff !important;
}

.bg-blue-tint-90 {
  background-color: #e6f0ff !important;
}

.border-blue-tint-90 {
  border-color: #e6f0ff !important;
}

.blue-tint-92 {
  color: #ebf3ff !important;
}

.bg-blue-tint-92 {
  background-color: #ebf3ff !important;
}

.border-blue-tint-92 {
  border-color: #ebf3ff !important;
}

.blue-tint-95 {
  color: #f2f7ff !important;
}

.bg-blue-tint-95 {
  background-color: #f2f7ff !important;
}

.border-blue-tint-95 {
  border-color: #f2f7ff !important;
}

.blue-tint-96 {
  color: #f5f9ff !important;
}

.bg-blue-tint-96 {
  background-color: #f5f9ff !important;
}

.border-blue-tint-96 {
  border-color: #f5f9ff !important;
}

.blue-shade-20 {
  color: #0050cb !important;
}

.bg-blue-shade-20 {
  background-color: #0050cb !important;
}

.border-blue-shade-20 {
  border-color: #0050cb !important;
}

.blue-shade-40 {
  color: #003c98 !important;
}

.bg-blue-shade-40 {
  background-color: #003c98 !important;
}

.border-blue-shade-40 {
  border-color: #003c98 !important;
}

.blue-shade-60 {
  color: #002866 !important;
}

.bg-blue-shade-60 {
  background-color: #002866 !important;
}

.border-blue-shade-60 {
  border-color: #002866 !important;
}

.green {
  color: #34c082 !important;
}

.bg-green {
  background-color: #34c082 !important;
}

.border-green {
  border-color: #34c082 !important;
}

.green-tint-20 {
  color: #5dcd9b !important;
}

.bg-green-tint-20 {
  background-color: #5dcd9b !important;
}

.border-green-tint-20 {
  border-color: #5dcd9b !important;
}

.green-tint-40 {
  color: #85d9b4 !important;
}

.bg-green-tint-40 {
  background-color: #85d9b4 !important;
}

.border-green-tint-40 {
  border-color: #85d9b4 !important;
}

.green-tint-60 {
  color: #aee6cd !important;
}

.bg-green-tint-60 {
  background-color: #aee6cd !important;
}

.border-green-tint-60 {
  border-color: #aee6cd !important;
}

.green-tint-80 {
  color: #d6f2e6 !important;
}

.bg-green-tint-80 {
  background-color: #d6f2e6 !important;
}

.border-green-tint-80 {
  border-color: #d6f2e6 !important;
}

.green-tint-88 {
  color: #e7f7f0 !important;
}

.bg-green-tint-88 {
  background-color: #e7f7f0 !important;
}

.border-green-tint-88 {
  border-color: #e7f7f0 !important;
}

.green-tint-90 {
  color: #ebf9f3 !important;
}

.bg-green-tint-90 {
  background-color: #ebf9f3 !important;
}

.border-green-tint-90 {
  border-color: #ebf9f3 !important;
}

.green-tint-92 {
  color: #effaf5 !important;
}

.bg-green-tint-92 {
  background-color: #effaf5 !important;
}

.border-green-tint-92 {
  border-color: #effaf5 !important;
}

.green-tint-95 {
  color: #f5fcf9 !important;
}

.bg-green-tint-95 {
  background-color: #f5fcf9 !important;
}

.border-green-tint-95 {
  border-color: #f5fcf9 !important;
}

.green-tint-96 {
  color: #f7fcfa !important;
}

.bg-green-tint-96 {
  background-color: #f7fcfa !important;
}

.border-green-tint-96 {
  border-color: #f7fcfa !important;
}

.green-shade-20 {
  color: #2a9a68 !important;
}

.bg-green-shade-20 {
  background-color: #2a9a68 !important;
}

.border-green-shade-20 {
  border-color: #2a9a68 !important;
}

.green-shade-40 {
  color: #1f734e !important;
}

.bg-green-shade-40 {
  background-color: #1f734e !important;
}

.border-green-shade-40 {
  border-color: #1f734e !important;
}

.green-shade-60 {
  color: #154d34 !important;
}

.bg-green-shade-60 {
  background-color: #154d34 !important;
}

.border-green-shade-60 {
  border-color: #154d34 !important;
}

.red {
  color: #de351b !important;
}

.bg-red {
  background-color: #de351b !important;
}

.border-red {
  border-color: #de351b !important;
}

.red-tint-20 {
  color: #e55d49 !important;
}

.bg-red-tint-20 {
  background-color: #e55d49 !important;
}

.border-red-tint-20 {
  border-color: #e55d49 !important;
}

.red-tint-40 {
  color: #eb8676 !important;
}

.bg-red-tint-40 {
  background-color: #eb8676 !important;
}

.border-red-tint-40 {
  border-color: #eb8676 !important;
}

.red-tint-60 {
  color: #f2aea4 !important;
}

.bg-red-tint-60 {
  background-color: #f2aea4 !important;
}

.border-red-tint-60 {
  border-color: #f2aea4 !important;
}

.red-tint-80 {
  color: #f8d7d1 !important;
}

.bg-red-tint-80 {
  background-color: #f8d7d1 !important;
}

.border-red-tint-80 {
  border-color: #f8d7d1 !important;
}

.red-tint-88 {
  color: #fbe7e4 !important;
}

.bg-red-tint-88 {
  background-color: #fbe7e4 !important;
}

.border-red-tint-88 {
  border-color: #fbe7e4 !important;
}

.red-tint-90 {
  color: #fcebe8 !important;
}

.bg-red-tint-90 {
  background-color: #fcebe8 !important;
}

.border-red-tint-90 {
  border-color: #fcebe8 !important;
}

.red-tint-92 {
  color: #fcefed !important;
}

.bg-red-tint-92 {
  background-color: #fcefed !important;
}

.border-red-tint-92 {
  border-color: #fcefed !important;
}

.red-tint-95 {
  color: #fdf5f4 !important;
}

.bg-red-tint-95 {
  background-color: #fdf5f4 !important;
}

.border-red-tint-95 {
  border-color: #fdf5f4 !important;
}

.red-tint-96 {
  color: #fef7f6 !important;
}

.bg-red-tint-96 {
  background-color: #fef7f6 !important;
}

.border-red-tint-96 {
  border-color: #fef7f6 !important;
}

.red-shade-20 {
  color: #b22a16 !important;
}

.bg-red-shade-20 {
  background-color: #b22a16 !important;
}

.border-red-shade-20 {
  border-color: #b22a16 !important;
}

.red-shade-40 {
  color: #852010 !important;
}

.bg-red-shade-40 {
  background-color: #852010 !important;
}

.border-red-shade-40 {
  border-color: #852010 !important;
}

.red-shade-60 {
  color: #59150b !important;
}

.bg-red-shade-60 {
  background-color: #59150b !important;
}

.border-red-shade-60 {
  border-color: #59150b !important;
}

.grey {
  color: #001432 !important;
}

.bg-grey {
  background-color: #001432 !important;
}

.border-grey {
  border-color: #001432 !important;
}

.grey-tint-20 {
  color: #33435b !important;
}

.bg-grey-tint-20 {
  background-color: #33435b !important;
}

.border-grey-tint-20 {
  border-color: #33435b !important;
}

.grey-tint-40 {
  color: #667284 !important;
}

.bg-grey-tint-40 {
  background-color: #667284 !important;
}

.border-grey-tint-40 {
  border-color: #667284 !important;
}

.grey-tint-60 {
  color: #99a1ad !important;
}

.bg-grey-tint-60 {
  background-color: #99a1ad !important;
}

.border-grey-tint-60 {
  border-color: #99a1ad !important;
}

.grey-tint-80 {
  color: #ccd0d6 !important;
}

.bg-grey-tint-80 {
  background-color: #ccd0d6 !important;
}

.border-grey-tint-80 {
  border-color: #ccd0d6 !important;
}

.grey-tint-88 {
  color: #e0e3e6 !important;
}

.bg-grey-tint-88 {
  background-color: #e0e3e6 !important;
}

.border-grey-tint-88 {
  border-color: #e0e3e6 !important;
}

.grey-tint-90 {
  color: #e6e8eb !important;
}

.bg-grey-tint-90 {
  background-color: #e6e8eb !important;
}

.border-grey-tint-90 {
  border-color: #e6e8eb !important;
}

.grey-tint-92 {
  color: #ebecef !important;
}

.bg-grey-tint-92 {
  background-color: #ebecef !important;
}

.border-grey-tint-92 {
  border-color: #ebecef !important;
}

.grey-tint-95 {
  color: #f2f3f5 !important;
}

.bg-grey-tint-95 {
  background-color: #f2f3f5 !important;
}

.border-grey-tint-95 {
  border-color: #f2f3f5 !important;
}

.grey-tint-96 {
  color: #f5f6f7 !important;
}

.bg-grey-tint-96 {
  background-color: #f5f6f7 !important;
}

.border-grey-tint-96 {
  border-color: #f5f6f7 !important;
}

.white {
  color: #ffffff !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.border-white {
  border-color: #ffffff !important;
}

.black {
  color: #000000 !important;
}

.bg-black {
  background-color: #000000 !important;
}

.border-black {
  border-color: #000000 !important;
}

.purple {
  color: #9b0053 !important;
}

.bg-purple {
  background-color: #9b0053 !important;
}

.border-purple {
  border-color: #9b0053 !important;
}

.purple-tint-20 {
  color: #af3375 !important;
}

.bg-purple-tint-20 {
  background-color: #af3375 !important;
}

.border-purple-tint-20 {
  border-color: #af3375 !important;
}

.purple-tint-40 {
  color: #c36698 !important;
}

.bg-purple-tint-40 {
  background-color: #c36698 !important;
}

.border-purple-tint-40 {
  border-color: #c36698 !important;
}

.purple-tint-60 {
  color: #d799ba !important;
}

.bg-purple-tint-60 {
  background-color: #d799ba !important;
}

.border-purple-tint-60 {
  border-color: #d799ba !important;
}

.purple-tint-80 {
  color: #ebccdd !important;
}

.bg-purple-tint-80 {
  background-color: #ebccdd !important;
}

.border-purple-tint-80 {
  border-color: #ebccdd !important;
}

.purple-tint-88 {
  color: #f3e0ea !important;
}

.bg-purple-tint-88 {
  background-color: #f3e0ea !important;
}

.border-purple-tint-88 {
  border-color: #f3e0ea !important;
}

.purple-tint-90 {
  color: #f5e6ee !important;
}

.bg-purple-tint-90 {
  background-color: #f5e6ee !important;
}

.border-purple-tint-90 {
  border-color: #f5e6ee !important;
}

.purple-tint-92 {
  color: #f7ebf1 !important;
}

.bg-purple-tint-92 {
  background-color: #f7ebf1 !important;
}

.border-purple-tint-92 {
  border-color: #f7ebf1 !important;
}

.purple-tint-95 {
  color: #faf2f6 !important;
}

.bg-purple-tint-95 {
  background-color: #faf2f6 !important;
}

.border-purple-tint-95 {
  border-color: #faf2f6 !important;
}

.purple-tint-96 {
  color: #fbf5f8 !important;
}

.bg-purple-tint-96 {
  background-color: #fbf5f8 !important;
}

.border-purple-tint-96 {
  border-color: #fbf5f8 !important;
}

.purple-shade-20 {
  color: #7c0042 !important;
}

.bg-purple-shade-20 {
  background-color: #7c0042 !important;
}

.border-purple-shade-20 {
  border-color: #7c0042 !important;
}

.purple-shade-40 {
  color: #5d0032 !important;
}

.bg-purple-shade-40 {
  background-color: #5d0032 !important;
}

.border-purple-shade-40 {
  border-color: #5d0032 !important;
}

.purple-shade-60 {
  color: #3e0021 !important;
}

.bg-purple-shade-60 {
  background-color: #3e0021 !important;
}

.border-purple-shade-60 {
  border-color: #3e0021 !important;
}

.orange {
  color: #ff9f00 !important;
}

.bg-orange {
  background-color: #ff9f00 !important;
}

.border-orange {
  border-color: #ff9f00 !important;
}

.orange-tint-20 {
  color: #ffb233 !important;
}

.bg-orange-tint-20 {
  background-color: #ffb233 !important;
}

.border-orange-tint-20 {
  border-color: #ffb233 !important;
}

.orange-tint-40 {
  color: #ffc566 !important;
}

.bg-orange-tint-40 {
  background-color: #ffc566 !important;
}

.border-orange-tint-40 {
  border-color: #ffc566 !important;
}

.orange-tint-60 {
  color: #ffd999 !important;
}

.bg-orange-tint-60 {
  background-color: #ffd999 !important;
}

.border-orange-tint-60 {
  border-color: #ffd999 !important;
}

.orange-tint-80 {
  color: #ffeccc !important;
}

.bg-orange-tint-80 {
  background-color: #ffeccc !important;
}

.border-orange-tint-80 {
  border-color: #ffeccc !important;
}

.orange-tint-88 {
  color: #fff3e0 !important;
}

.bg-orange-tint-88 {
  background-color: #fff3e0 !important;
}

.border-orange-tint-88 {
  border-color: #fff3e0 !important;
}

.orange-tint-90 {
  color: #fff5e6 !important;
}

.bg-orange-tint-90 {
  background-color: #fff5e6 !important;
}

.border-orange-tint-90 {
  border-color: #fff5e6 !important;
}

.orange-tint-92 {
  color: #fff7eb !important;
}

.bg-orange-tint-92 {
  background-color: #fff7eb !important;
}

.border-orange-tint-92 {
  border-color: #fff7eb !important;
}

.orange-tint-95 {
  color: #fffaf2 !important;
}

.bg-orange-tint-95 {
  background-color: #fffaf2 !important;
}

.border-orange-tint-95 {
  border-color: #fffaf2 !important;
}

.orange-tint-96 {
  color: #fffbf5 !important;
}

.bg-orange-tint-96 {
  background-color: #fffbf5 !important;
}

.border-orange-tint-96 {
  border-color: #fffbf5 !important;
}

.orange-shade-20 {
  color: #cc7f00 !important;
}

.bg-orange-shade-20 {
  background-color: #cc7f00 !important;
}

.border-orange-shade-20 {
  border-color: #cc7f00 !important;
}

.orange-shade-40 {
  color: #995f00 !important;
}

.bg-orange-shade-40 {
  background-color: #995f00 !important;
}

.border-orange-shade-40 {
  border-color: #995f00 !important;
}

.orange-shade-60 {
  color: #664000 !important;
}

.bg-orange-shade-60 {
  background-color: #664000 !important;
}

.border-orange-shade-60 {
  border-color: #664000 !important;
}

/**
 * Importing typography
 */
.m-bold {
  font-weight: bold;
}

.m-inverted {
  color: #ffffff !important;
}

.m-muted {
  color: #99a1ad !important;
}

/**
 * _elements.scss partial
 *
 * Contains style definitions for bare semantic HTML elements
 */
a {
  color: #0064fe;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  outline: none;
}
html:not(.touchevents) a:hover {
  text-decoration: underline;
}
html:not(.touchevents) a:focus {
  text-decoration: underline;
  outline: none;
}
a.active {
  pointer-events: none;
}
a.m-muted {
  text-decoration: underline;
  color: #001432;
}
html:not(.touchevents) a.m-muted:hover {
  color: #0064fe;
}

a.underline {
  text-decoration: underline;
}

li a {
  display: block;
  cursor: pointer;
}

body {
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
}

form {
  width: 100%;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

/**
 * All m-hX are set with and without .m prefix, to enable override when .m is set e.g. on the body
 */
.m h1,
.m .m-h1,
.m-h1 {
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.5rem;
  color: #002d72;
}
.m h1.m-intro,
.m .m-h1.m-intro,
.m-h1.m-intro {
  font-weight: 900;
  font-size: 5rem;
  line-height: 6rem;
}

.m h2,
.m .m-h2,
.m-h2 {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.625rem;
  color: #002d72;
}

.m h3,
.m .m-h3,
.m-h3 {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #002d72;
}

.m h4,
.m .m-h4,
.m-h4 {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #002d72;
}

.m h5,
.m .m-h5,
.m-h5 {
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: #001432;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #f2f3f5;
  margin: 1em 0;
  padding: 0;
}

main {
  flex-grow: 2;
  width: 100%;
  height: 100vh;
  margin-bottom: 2rem;
}

.m p,
.m-p {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #001432;
}

/* TODO: refactor, think of a better solution */
img.full-width {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

small {
  font-weight: normal;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: currentColor;
}

/**
 * _components.scss partial
 *
 * Importing and including specific UI components for both, CSS only and ReactJS implementation.
 */
.m-segmentedControl {
  margin-bottom: 1.5rem;
}
.m-segmentedControl-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-basis: 50%;
  border-radius: 0.1875rem;
  margin-bottom: 0.1875rem;
  overflow: hidden;
}
.m-segmentedControl-option {
  flex-grow: 1;
  flex-basis: 0;
  margin: 0;
  padding: 0.3125rem 0 0.4375rem 0;
  height: 1.75rem;
  outline: none;
  text-align: center;
  font-family: inherit;
  font-weight: normal;
  font-size: 0.75rem;
  border: 1px solid #0064fe;
  border-radius: 0;
  color: #0070f5;
  background-color: #ffffff;
  vertical-align: middle;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Don't use focus() mixin, to also disable outline for touch devices */
}
.m-segmentedControl-option + .m-segmentedControl-option {
  border-left: none;
}
.m-segmentedControl-option:focus {
  outline: none;
}
html:not(.touchevents) .m-segmentedControl-option:focus {
  color: #002d72;
  font-weight: bold;
}
html:not(.touchevents) .m-segmentedControl-option:hover {
  text-decoration: underline;
  outline: none;
}
.m-segmentedControl-option-isActive {
  background-color: #e6f0ff;
  color: #002d72;
  font-weight: bold;
  pointer-events: none;
  border: 1px solid #66a2fe;
  box-shadow: inset -1px -1px 0 1px #66a2fe;
}
.m-segmentedControl-option:disabled {
  opacity: 0.7;
  pointer-events: none;
  text-decoration: none;
}
.m-segmentedControl-option:first-child {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.m-segmentedControl-option:last-child {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}
.m-segmentedControl-hasWarning .m-segmentedControl-option {
  color: #852010;
  border: 1px solid #eb8676;
}
.m-segmentedControl-hasWarning .m-segmentedControl-option + .m-segmentedControl-option {
  border-left: none;
}
html:not(.touchevents) .m-segmentedControl-hasWarning .m-segmentedControl-option:focus {
  color: #852010;
  font-weight: normal;
}
.m-segmentedControl-hasWarning .m-segmentedControl-option-isActive {
  box-shadow: inset -1px -1px 0 1px #eb8676;
  background: none;
  font-weight: normal;
}
.m-segmentedControl-isVertical .m-segmentedControl-options {
  flex-direction: column;
}
.m-segmentedControl-isVertical .m-segmentedControl-option {
  flex-basis: 100%;
  border-radius: 0;
  border-top: 1px solid #0064fe;
  border-left: 1px solid #0064fe;
  border-right: 1px solid #0064fe;
  border-bottom: none;
}
.m-segmentedControl-isVertical .m-segmentedControl-option:first-child {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}
.m-segmentedControl-isVertical .m-segmentedControl-option:last-child {
  border-bottom-left-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
  border-bottom: 1px solid #0064fe;
}
.m-segmentedControl-isVertical .m-segmentedControl-option-isActive {
  border: 1px solid #66a2fe;
  box-shadow: inset 0 0 0 1px #66a2fe;
}
.m-segmentedControl-isVertical .m-segmentedControl-option-isActive + .m-segmentedControl-option:not(.m-segmentedControl-option-isActive) {
  border-top: none;
}
.m-segmentedControl-isVertical .m-segmentedControl-option-isActive:last-child {
  border-bottom: 1px solid #66a2fe;
}

.m-button {
  position: relative;
  height: auto;
  padding: 0.25rem 1rem;
  vertical-align: middle;
  border: 0;
  border-radius: 0.125rem;
  color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.5;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: background-color 125ms ease, box-shadow 125ms ease;
  /* Color override. Breaks consistency and should be used as less as possible. */
}
.m-button .m-icon {
  vertical-align: middle;
  font-size: 1.25rem;
  width: 1em;
  height: 1em;
  transform: translateY(-0.125rem);
}
.m-button-icon-left .m-icon {
  margin: 0 0.5rem 0 0;
}
.m-button-icon-right .m-icon {
  margin: 0 0 0 0.5rem;
}
.m-button-small .m-icon {
  font-size: 1rem;
}
.m-button-large .m-icon {
  font-size: 1.5rem;
}
.m-button.m-button-isDisabled, .m-button:disabled {
  box-shadow: none;
  cursor: not-allowed;
}
.m-button-disabledShaker {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}
.m-button-isShaking {
  animation: btn-shake 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
@keyframes btn-shake {
  0% {
    transform: translateX(3px);
  }
  20% {
    transform: translateX(-2.5px);
  }
  40% {
    transform: translateX(1.5px);
  }
  60% {
    transform: translateX(-1px);
  }
  80% {
    transform: translateX(0.5px);
  }
  100% {
    transform: translateX(0);
  }
}
.m-button-secondaryNotification {
  color: #002d72;
  background-color: #fffde6;
}
html:not(.touchevents) .m-button-secondaryNotification:hover {
  background-color: #fffbcc;
}
html:not(.touchevents) .m-button-secondaryNotification:focus {
  background-color: #fffbcc;
  /* Focus coming from mouse click  */
}
html:not(.touchevents) .m-button-secondaryNotification:focus:not(:focus-visible) {
  background-color: #fffde6;
}
.m-button-secondaryNotification:active {
  background-color: #fffbcc;
}
.m-button-primaryNotification {
  color: #002d72;
  background-color: #ffed00;
}
html:not(.touchevents) .m-button-primaryNotification:hover {
  background-color: #fff133;
}
html:not(.touchevents) .m-button-primaryNotification:focus {
  background-color: #fff133;
  /* Focus coming from mouse click  */
}
html:not(.touchevents) .m-button-primaryNotification:focus:not(:focus-visible) {
  background-color: #ffed00;
}
.m-button-primaryNotification:active {
  background-color: #fff133;
}
.m-button-primary, .m-button-primaryRaised {
  color: #ffffff;
  background-color: #0064fe;
}
html:not(.touchevents) .m-button-primary:hover, html:not(.touchevents) .m-button-primaryRaised:hover {
  background-color: #003c98;
}
html:not(.touchevents) .m-button-primary:focus, html:not(.touchevents) .m-button-primaryRaised:focus {
  background-color: #003c98;
  /* Focus coming from mouse click  */
}
html:not(.touchevents) .m-button-primary:focus:not(:focus-visible), html:not(.touchevents) .m-button-primaryRaised:focus:not(:focus-visible) {
  background-color: #0064fe;
}
.m-button-primary:active, .m-button-primaryRaised:active {
  background-color: #003c98;
}
.m-button-primaryRaised {
  box-shadow: 0 2px 4px 0 rgba(0, 45, 114, 0.2);
}
html:not(.touchevents) .m-button-primaryRaised:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 45, 114, 0.2);
}
.m-button-secondary, .m-button-tertiary {
  background-color: #e6f0ff;
}
html:not(.touchevents) .m-button-secondary:hover, html:not(.touchevents) .m-button-tertiary:hover {
  background-color: #cce0ff;
}
html:not(.touchevents) .m-button-secondary:focus, html:not(.touchevents) .m-button-tertiary:focus {
  background-color: #cce0ff;
  /* Focus coming from mouse click  */
}
html:not(.touchevents) .m-button-secondary:focus:not(:focus-visible), html:not(.touchevents) .m-button-tertiary:focus:not(:focus-visible) {
  background-color: #e6f0ff;
}
.m-button-secondary:active, .m-button-tertiary:active {
  background-color: #cce0ff;
}
.m-button-secondary {
  color: #002d72;
}
.m-button-tertiary {
  color: #0064fe;
  font-weight: normal;
}
.m-button-primary.m-button-isDisabled, .m-button-primary:disabled, .m-button-primaryRaised.m-button-isDisabled, .m-button-primaryRaised:disabled, .m-button-primaryWarning.m-button-isDisabled, .m-button-primaryWarning:disabled, .m-button-secondary.m-button-isDisabled, .m-button-secondary:disabled, .m-button-tertiary.m-button-isDisabled, .m-button-tertiary:disabled, .m-button-primaryNotification.m-button-isDisabled, .m-button-primaryNotification:disabled, .m-button-secondaryNotification.m-button-isDisabled, .m-button-secondaryNotification:disabled, .m-button-decision.m-button-isDisabled, .m-button-decision:disabled {
  color: #667284;
  background-color: #e6e8eb;
  background-image: repeating-linear-gradient(40deg, rgba(204, 208, 214, 0.5) 0, rgba(204, 208, 214, 0.5) 2px, rgba(204, 208, 214, 0) 2px, rgba(204, 208, 214, 0) 10px);
}
html:not(.touchevents) .m-button-primary.m-button-isDisabled:hover, html:not(.touchevents) .m-button-primary:disabled:hover, html:not(.touchevents) .m-button-primaryRaised.m-button-isDisabled:hover, html:not(.touchevents) .m-button-primaryRaised:disabled:hover, html:not(.touchevents) .m-button-primaryWarning.m-button-isDisabled:hover, html:not(.touchevents) .m-button-primaryWarning:disabled:hover, html:not(.touchevents) .m-button-secondary.m-button-isDisabled:hover, html:not(.touchevents) .m-button-secondary:disabled:hover, html:not(.touchevents) .m-button-tertiary.m-button-isDisabled:hover, html:not(.touchevents) .m-button-tertiary:disabled:hover, html:not(.touchevents) .m-button-primaryNotification.m-button-isDisabled:hover, html:not(.touchevents) .m-button-primaryNotification:disabled:hover, html:not(.touchevents) .m-button-secondaryNotification.m-button-isDisabled:hover, html:not(.touchevents) .m-button-secondaryNotification:disabled:hover, html:not(.touchevents) .m-button-decision.m-button-isDisabled:hover, html:not(.touchevents) .m-button-decision:disabled:hover {
  background-color: #e6e8eb;
  text-decoration: none;
  box-shadow: none;
}
.m-button-primaryWarning {
  color: #ffffff;
  background-color: #de351b;
}
html:not(.touchevents) .m-button-primaryWarning:hover {
  background-color: #b22a16;
}
html:not(.touchevents) .m-button-primaryWarning:focus {
  background-color: #b22a16;
  /* Focus coming from mouse click  */
}
html:not(.touchevents) .m-button-primaryWarning:focus:not(:focus-visible) {
  background-color: #de351b;
}
.m-button-primaryWarning:active {
  background-color: #b22a16;
}
.m-button-ghost, .m-button-ghostWarning, .m-button-ghostMuted {
  color: #0064fe;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  font-weight: bold;
}
html:not(.touchevents) .m-button-ghost:focus, html:not(.touchevents) .m-button-ghostWarning:focus, html:not(.touchevents) .m-button-ghostMuted:focus {
  /* Focus coming from mouse click  */
}
html:not(.touchevents) .m-button-ghost:focus:not(:focus-visible), html:not(.touchevents) .m-button-ghostWarning:focus:not(:focus-visible), html:not(.touchevents) .m-button-ghostMuted:focus:not(:focus-visible) {
  text-decoration: none;
}
.m-button-ghost.m-button-isDisabled, .m-button-ghost:disabled, .m-button-ghostWarning.m-button-isDisabled, .m-button-ghostWarning:disabled, .m-button-ghostMuted.m-button-isDisabled, .m-button-ghostMuted:disabled {
  color: #99a1ad;
}
html:not(.touchevents) .m-button-ghost.m-button-isDisabled:hover, html:not(.touchevents) .m-button-ghost:disabled:hover, html:not(.touchevents) .m-button-ghostWarning.m-button-isDisabled:hover, html:not(.touchevents) .m-button-ghostWarning:disabled:hover, html:not(.touchevents) .m-button-ghostMuted.m-button-isDisabled:hover, html:not(.touchevents) .m-button-ghostMuted:disabled:hover {
  text-decoration: none;
  background-color: transparent;
}
html:not(.touchevents) .m-button-ghost:hover, html:not(.touchevents) .m-button-ghostMuted:hover {
  background-color: #f2f7ff;
}
html:not(.touchevents) .m-button-ghost:focus, html:not(.touchevents) .m-button-ghostMuted:focus {
  background-color: #f2f7ff;
}
.m-button-ghostMuted {
  color: #001432;
  font-weight: bold;
  text-decoration: underline;
}
html:not(.touchevents) .m-button-ghostMuted:focus {
  /* Focus coming from mouse click  */
}
html:not(.touchevents) .m-button-ghostMuted:focus:not(:focus-visible) {
  text-decoration: underline;
}
html:not(.touchevents) .m-button-ghostMuted.m-button-isDisabled:hover, html:not(.touchevents) .m-button-ghostMuted:disabled:hover {
  color: #99a1ad;
  text-decoration: underline;
}
.m-button-ghostWarning {
  color: #de351b;
}
html:not(.touchevents) .m-button-ghostWarning:hover {
  text-decoration: underline;
}
html:not(.touchevents) .m-button-ghostWarning:focus {
  text-decoration: underline;
  /* Focus coming from mouse click  */
}
html:not(.touchevents) .m-button-ghostWarning:focus:not(:focus-visible) {
  text-decoration: none;
}
.m-button-decision {
  color: #3383fe;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #3383fe;
  display: block;
  width: 100%;
  font-weight: normal;
  min-height: 3rem;
}
html:not(.touchevents) .m-button-decision:hover {
  color: #ffffff;
  background-color: #3383fe;
}
html:not(.touchevents) .m-button-decision:focus {
  color: #ffffff;
  background-color: #3383fe;
  /* Focus coming from mouse click  */
}
html:not(.touchevents) .m-button-decision:focus:not(:focus-visible) {
  color: #3383fe;
  background-color: #ffffff;
}
.m-button-decision:active {
  color: #ffffff;
  background-color: #3383fe;
}
.m-button.m-button-waiting {
  position: relative;
  text-decoration: none;
}
.m-button.m-button-waiting > *:not(:last-child) {
  opacity: 0;
}
.m-button.m-button-waiting .m-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.6875rem;
}
html:not(.touchevents) .m-button.m-button-waiting:hover {
  text-decoration: none;
}
.m-button-large {
  padding: 0.65625rem 2rem;
  font-size: 1.125rem;
}
.m-button-small {
  padding: 0.4375rem 1rem;
  font-size: 0.75rem;
}
.m-button-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
  padding-left: 0;
  padding-right: 0;
}
.m-button-block .m-button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.m-button-isWide {
  padding: 0 2.25em;
}
.m-button-isDarkBlue {
  background-color: #002866;
  color: #ffffff;
}
.m-button-isAlmostActive::after, .m-button-isAlmostActive::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: translate(-50%, -50%);
  pointer-events: none;
  background-color: currentColor;
  outline: 1px solid currentColor;
}
.m-button-isAlmostActive::after {
  color: rgba(0, 100, 254, 0.25);
  clip-path: circle(0 at 50% 50%);
  transition: 500ms opacity;
}
.m-button-isAlmostActive::before {
  color: rgba(255, 255, 255, 0.5);
  transition: 500ms opacity;
}
.m-button-wasAlmostActive::before {
  opacity: 0;
  transition: 400ms opacity 350ms;
  will-change: opacity;
}
.m-button-wasAlmostActive::after {
  opacity: 0;
  clip-path: circle(100% at 50% 50%);
  transition: 500ms clip-path 250ms, 400ms opacity 350ms;
  will-change: clip-path, opacity;
}

input.m-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none !important;
  color: #ffffff;
}

.m-labelIcon {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.m-labelIcon > .m-label {
  padding-right: 8px;
}
.m-labelIcon > .m-Icon {
  max-height: 1.2rem;
}

@media (max-width: 321px) {
  .m-labelIcon .m-Icon {
    transform: scale(0.7);
  }
}
a.m-button-block {
  justify-content: center;
}

a.m-button {
  display: inline-flex;
  align-items: center;
}
a.m-button:hover {
  text-decoration: none !important;
}
a.m-button:focus {
  text-decoration: none;
}

.m-bottomNavigation {
  height: 4rem;
  height: calc(4rem + constant(safe-area-inset-bottom));
  height: calc(4rem + env(safe-area-inset-bottom));
}
.m-bottomNavigation-content {
  display: flex;
  justify-content: space-around;
  align-content: stretch;
  box-sizing: content-box;
  padding: 0;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  height: 4rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #002d72;
}
.m-bottomNavigation-button {
  flex: 1;
  margin: 0;
  align-self: stretch;
  height: 100%;
  box-shadow: none;
  border: none;
  border-radius: 0;
  background-color: #002d72;
  color: #ffffff;
  font-size: 0.6875rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
html:not(.touchevents) .m-bottomNavigation-button:focus {
  color: #ffffff;
}
.m-bottomNavigation-button:focus {
  outline: none;
}
.m-bottomNavigation-button-isActive {
  color: #ffed00;
}
.m-bottomNavigation-buttonLabel {
  display: block;
  margin-top: 0.125rem;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.625rem;
}

.m-checkbox {
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  font-size: 0.75rem;
  cursor: pointer;
}
.m-checkbox--label-center {
  align-items: center;
}
html:not(.touchevents) .m-checkbox:hover .m-checkbox-input:not(:disabled) + .m-checkbox-inputIcon {
  border-color: #0064fe;
}
.m-checkbox-inputIcon {
  position: relative;
  float: left;
  width: 1rem;
  height: 1rem;
  border-radius: 0.125rem;
  box-shadow: inset 0 0 0 0.125rem #ccd0d6;
  background-color: #ffffff;
  transition: background-color, border-color, box-shadow;
  transition-duration: 0.35s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  will-change: background-color, border-color;
  flex-shrink: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .m-checkbox-inputIcon {
    border-radius: 0;
  }
}
.m-checkbox-inputIcon::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 0;
  height: 0;
  border-radius: 0.125rem;
  background-color: transparent;
  transition: width, height, background-color;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.m-checkbox-inputIcon svg {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  top: 0.125rem;
  left: 0.125rem;
}
.m-checkbox-inputIcon svg path,
.m-checkbox-inputIcon svg polyline {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 1.125rem;
  stroke-dashoffset: 1.125rem;
  will-change: stroke-dashoffset;
  transition: stroke-dashoffset 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0s;
}
.m-checkbox-input {
  position: absolute;
  opacity: 0;
}
.m-checkbox-input:focus + .m-checkbox-inputIcon::before {
  width: 1.875rem;
  height: 1.875rem;
  background-color: #cce0ff;
}
.m-checkbox-isIndeterminate .m-checkbox-input + .m-checkbox-inputIcon, .m-checkbox-input:checked + .m-checkbox-inputIcon {
  border-color: #0064fe;
  box-shadow: 0 0 0 0.0625rem #0064fe;
  background-color: #0064fe;
  transition-delay: 0s;
}
.m-checkbox-isIndeterminate .m-checkbox-input + .m-checkbox-inputIcon path,
.m-checkbox-isIndeterminate .m-checkbox-input + .m-checkbox-inputIcon polyline, .m-checkbox-input:checked + .m-checkbox-inputIcon path,
.m-checkbox-input:checked + .m-checkbox-inputIcon polyline {
  stroke-dashoffset: 0;
  transition-delay: 0.15s;
}
.m-checkbox-input:disabled:not(:checked) + .m-checkbox-inputIcon {
  box-shadow: none;
  padding: 0.0625rem;
}
.m-checkbox-isIndeterminate .m-checkbox-input:disabled + .m-checkbox-inputIcon, .m-checkbox-input:checked:disabled + .m-checkbox-inputIcon {
  border-color: #99a1ad;
  box-shadow: 0 0 0 0.0625rem #99a1ad;
  background-color: #99a1ad;
}
.m-checkbox-input:disabled + .m-checkbox-inputIcon {
  border-color: #e6e8eb;
  background-color: #f2f3f5;
}
.m-checkbox-input:disabled ~ .m-checkbox-label, .m-checkbox-input:disabled ~ .m-checkbox-label strong {
  color: #99a1ad;
}
.m-checkbox-label {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #001432;
  margin-left: 0.5rem;
}
.m-checkbox-label > strong {
  color: #002d72;
}
.m-checkbox-withMargin {
  margin: 1.5rem 0;
}
.m-checkbox-star {
  cursor: pointer;
}

.m-drop-checkboxes {
  border-radius: 0.125rem;
  padding: 0.125rem 0 0.125rem 0.14rem;
  position: relative;
  transition: 150ms background-color, width;
}
.m-drop-checkboxes .m-checkbox-label {
  font-weight: normal;
}
.m-drop-checkboxes.dropdown-visible {
  background-color: #cce0ff;
  width: 2.8rem;
}
.m-drop-checkboxes--label-center {
  align-items: center;
}
.m-drop-checkboxes .m-checkbox-inputIcon {
  box-shadow: inset 0 0 0 0.025rem #cce0ff;
}
.m-drop-checkboxes .m-checkbox-isIndeterminate .m-checkbox-input + .m-checkbox-inputIcon,
.m-drop-checkboxes .m-checkbox-input:checked + .m-checkbox-inputIcon {
  box-shadow: 0 0 0 0.01rem #cce0ff;
}
.m-drop-checkboxes .m-checkbox .m-checkbox-input:not(:disabled) + .m-checkbox-inputIcon::before {
  content: none;
}
.m-drop-checkboxes .m-dropdown-container {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.m-drop-checkboxes .m-dropdown-container.open .toggler {
  background: #99c1ff;
}
.m-drop-checkboxes .m-dropdown-container.open .toggler .m-icon {
  color: white !important;
}
.m-drop-checkboxes .m-dropdown-container .dropdown-menu {
  top: 110%;
  min-width: 8.5625rem;
}
.m-drop-checkboxes .m-dropdown-container .m-checkbox {
  padding: 0.5rem;
}
.m-drop-checkboxes .toggler {
  background: #cce0ff;
  border-bottom-right-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
  height: 1.25rem;
  transition: 150ms background-color;
  width: 1.55rem;
}
.m-drop-checkboxes .toggler .m-icon {
  height: 1em;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1em;
}

.m-choiceList {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  list-style: none;
}
.m-choiceList > li {
  transition: background-color 0.125s ease-in-out 0s;
  position: relative;
  padding: 1rem 1.5rem;
  width: 100%;
  box-shadow: inset 0 -1px 0 0 #f2f3f5;
}
.m-choiceList > li > a:hover,
.m-choiceList > li > a:focus {
  text-decoration: none;
}
.m-choiceList > li:not(.m-choiceList-header) {
  min-height: 4.25rem;
}
.m-choiceList > li.m-choiceList-header {
  padding: 0.375rem 1.5rem 0.375rem 0.75rem;
  color: #6681aa;
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 0 #f2f3f5;
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  text-transform: uppercase;
}
.m-choiceList > .m-choiceList-linkItem {
  padding: 0;
  color: #002d72;
}
.m-choiceList > .m-choiceList-linkItem-isSelected {
  color: #002d72;
  font-weight: bold;
}
.m-choiceList-link {
  display: block;
  padding: 0.8125rem 1.5rem;
  color: inherit;
}
.m-choiceList-link strong {
  color: #0064fe;
}
.m-choiceList-linkItem-isSelected .m-choiceList-link strong {
  color: #002d72;
  font-weight: bold;
}
.m-choiceList-link .m-storeID {
  margin-right: 0.5rem;
}
html:not(.touchevents) .m-choiceList:not(.m-choiceList-noHover) li:not(.m-choiceList-header):hover {
  color: #0064fe;
  background-color: #f2f7ff;
}
html:not(.touchevents) .m-choiceList:not(.m-choiceList-noHover) li:not(.m-choiceList-header):hover .m-storeID:not(.m-storeID-isPrimary) {
  color: #ffffff;
  background-color: #0050cb;
}
.m-choiceList-bordered {
  border: 1px solid #ccd0d6;
  border-radius: 3px;
}
.m-choiceList-bordered > li:not(:last-child) {
  box-shadow: inset 0 -1px 0 0 #ccd0d6;
}
.m-choiceList-compact > li:not(.m-choiceList-header) {
  min-height: 0;
}

mm-radiobutton.ng-dirty.ng-invalid .m-radioButton-inputIcon {
  border-color: #eb8676;
}
mm-radiobutton .m-radioButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
}
html:not(.touchevents) mm-radiobutton .m-radioButton:hover .m-radioButton-input:not(:disabled) + .m-radioButton-inputIcon {
  border-color: #0064fe;
}
mm-radiobutton .m-radioButton-inputIcon {
  position: relative;
  float: left;
  width: 1rem;
  height: 1rem;
  padding: 0.3125rem;
  border: 0.125rem solid #ccd0d6;
  border-radius: 50%;
  background-color: #ffffff;
  transition: background-color, border-color;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease, ease;
  will-change: background-color, border-color;
  flex-shrink: 0;
}
mm-radiobutton .m-radioButton-inputIcon::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: transparent;
  transition: width 0.3s, height 0.3s, background-color 0.3s;
  transition-timing-function: cubic-bezier(0.77, 0, 0.32, 1);
}
mm-radiobutton .m-radioButton-inputIcon::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  border-radius: 50%;
  background-color: #0064fe;
  transition: transform 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}
mm-radiobutton .m-radioButton-input {
  position: absolute;
  opacity: 0;
}
mm-radiobutton .m-radioButton-input:focus + .m-radioButton-inputIcon::before {
  width: 1.875rem;
  height: 1.875rem;
  background-color: #cce0ff;
}
mm-radiobutton .m-radioButton-input:checked + .m-radioButton-inputIcon {
  border-color: #0064fe;
}
mm-radiobutton .m-radioButton-input:checked + .m-radioButton-inputIcon::after {
  transform: scale(0.625); /* 16 * 0.625 = 10 */
}
mm-radiobutton .m-radioButton-input:checked:disabled + .m-radioButton-inputIcon {
  border-color: #99a1ad;
  background-color: #ffffff;
}
mm-radiobutton .m-radioButton-input:checked:disabled + .m-radioButton-inputIcon::after {
  background-color: #99a1ad;
}
mm-radiobutton .m-radioButton-input:disabled + .m-radioButton-inputIcon {
  border-color: #e6e8eb;
  background-color: #f2f3f5;
}
mm-radiobutton .m-radioButton-input:disabled:not(:checked) + .m-radioButton-inputIcon {
  border-width: 1px;
}
mm-radiobutton .m-radioButton-input:disabled ~ .m-radioButton-label, mm-radiobutton .m-radioButton-input:disabled ~ .m-radioButton-label strong {
  color: #99a1ad;
}
mm-radiobutton .m-radioButton-label {
  font-size: 0.75rem;
  line-height: 1rem;
  flex-grow: 1;
  color: #001432;
  margin-left: 0.5rem;
}
mm-radiobutton .m-radioButton-label > strong {
  color: #002d72;
}
mm-radiobutton .m-radioButton-isReversed {
  text-align: left;
  flex-direction: row-reverse;
}
mm-radiobutton .m-radioButton-isReversed .m-radioButton-label {
  margin-left: 0;
  margin-right: 0.75rem;
}

.m-radioButtonGroup-isBordered {
  margin: 1.5rem 0;
  border-radius: 0.125rem;
  box-shadow: inset 0 0 0 1px #ccd0d6;
  overflow: hidden;
}
.m-radioButtonGroup-isBordered .m-radioButton {
  padding: 1rem;
  box-shadow: 0 1px 0 0 #ccd0d6;
}
.m-radioButtonGroup-isBordered .m-radioButton-isChecked {
  box-shadow: inset 0 0 0 2px #66a2fe;
  z-index: 1;
}
.m-radioButtonGroup-isBordered .m-address {
  line-height: 1.3;
}

.m-errorMessage {
  padding: 11px 16px 9px;
  margin: 20px 0;
  font-weight: bold;
  color: #de351b;
  letter-spacing: 0;
  line-height: 20px;
  background: #ffebcc;
  border-radius: 3px;
}

.m-row-fluid, .m-row {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.m-row {
  display: flex;
  flex-flow: row wrap;
  max-width: 980px;
}
@media (min-width: 1024px) {
  .m-row {
    max-width: 1280px;
  }
}
.m-row-centerVertical {
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: wrap;
}
.m-row .m-col-centered:nth-child(1n) {
  margin: 0 auto;
  float: none;
}

.m-col-xs1of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 1/8 - (30px - 30px * 1/8));
  max-width: calc(99.9% * 1/8 - (30px - 30px * 1/8));
  width: calc(99.9% * 1/8 - (30px - 30px * 1/8));
}

.m-col-xs1of8:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs1of8:last-child {
  margin-right: 0;
}

.m-col-xs1of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs2of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 2/8 - (30px - 30px * 2/8));
  max-width: calc(99.9% * 2/8 - (30px - 30px * 2/8));
  width: calc(99.9% * 2/8 - (30px - 30px * 2/8));
}

.m-col-xs2of8:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs2of8:last-child {
  margin-right: 0;
}

.m-col-xs2of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs3of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 3/8 - (30px - 30px * 3/8));
  max-width: calc(99.9% * 3/8 - (30px - 30px * 3/8));
  width: calc(99.9% * 3/8 - (30px - 30px * 3/8));
}

.m-col-xs3of8:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs3of8:last-child {
  margin-right: 0;
}

.m-col-xs3of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs4of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 4/8 - (30px - 30px * 4/8));
  max-width: calc(99.9% * 4/8 - (30px - 30px * 4/8));
  width: calc(99.9% * 4/8 - (30px - 30px * 4/8));
}

.m-col-xs4of8:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs4of8:last-child {
  margin-right: 0;
}

.m-col-xs4of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs5of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 5/8 - (30px - 30px * 5/8));
  max-width: calc(99.9% * 5/8 - (30px - 30px * 5/8));
  width: calc(99.9% * 5/8 - (30px - 30px * 5/8));
}

.m-col-xs5of8:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs5of8:last-child {
  margin-right: 0;
}

.m-col-xs5of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs6of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 6/8 - (30px - 30px * 6/8));
  max-width: calc(99.9% * 6/8 - (30px - 30px * 6/8));
  width: calc(99.9% * 6/8 - (30px - 30px * 6/8));
}

.m-col-xs6of8:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs6of8:last-child {
  margin-right: 0;
}

.m-col-xs6of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs7of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 7/8 - (30px - 30px * 7/8));
  max-width: calc(99.9% * 7/8 - (30px - 30px * 7/8));
  width: calc(99.9% * 7/8 - (30px - 30px * 7/8));
}

.m-col-xs7of8:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs7of8:last-child {
  margin-right: 0;
}

.m-col-xs7of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs8of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 8/8 - (30px - 30px * 8/8));
  max-width: calc(99.9% * 8/8 - (30px - 30px * 8/8));
  width: calc(99.9% * 8/8 - (30px - 30px * 8/8));
}

.m-col-xs8of8:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs8of8:last-child {
  margin-right: 0;
}

.m-col-xs8of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs1of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 1/12 - (30px - 30px * 1/12));
  max-width: calc(99.9% * 1/12 - (30px - 30px * 1/12));
  width: calc(99.9% * 1/12 - (30px - 30px * 1/12));
}

.m-col-xs1of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs1of12:last-child {
  margin-right: 0;
}

.m-col-xs1of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs2of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 2/12 - (30px - 30px * 2/12));
  max-width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
  width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
}

.m-col-xs2of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs2of12:last-child {
  margin-right: 0;
}

.m-col-xs2of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs3of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 3/12 - (30px - 30px * 3/12));
  max-width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
  width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
}

.m-col-xs3of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs3of12:last-child {
  margin-right: 0;
}

.m-col-xs3of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs4of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 4/12 - (30px - 30px * 4/12));
  max-width: calc(99.9% * 4/12 - (30px - 30px * 4/12));
  width: calc(99.9% * 4/12 - (30px - 30px * 4/12));
}

.m-col-xs4of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs4of12:last-child {
  margin-right: 0;
}

.m-col-xs4of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs5of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 5/12 - (30px - 30px * 5/12));
  max-width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
  width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
}

.m-col-xs5of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs5of12:last-child {
  margin-right: 0;
}

.m-col-xs5of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs6of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 6/12 - (30px - 30px * 6/12));
  max-width: calc(99.9% * 6/12 - (30px - 30px * 6/12));
  width: calc(99.9% * 6/12 - (30px - 30px * 6/12));
}

.m-col-xs6of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs6of12:last-child {
  margin-right: 0;
}

.m-col-xs6of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs7of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 7/12 - (30px - 30px * 7/12));
  max-width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
  width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
}

.m-col-xs7of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs7of12:last-child {
  margin-right: 0;
}

.m-col-xs7of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs8of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 8/12 - (30px - 30px * 8/12));
  max-width: calc(99.9% * 8/12 - (30px - 30px * 8/12));
  width: calc(99.9% * 8/12 - (30px - 30px * 8/12));
}

.m-col-xs8of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs8of12:last-child {
  margin-right: 0;
}

.m-col-xs8of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs9of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 9/12 - (30px - 30px * 9/12));
  max-width: calc(99.9% * 9/12 - (30px - 30px * 9/12));
  width: calc(99.9% * 9/12 - (30px - 30px * 9/12));
}

.m-col-xs9of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs9of12:last-child {
  margin-right: 0;
}

.m-col-xs9of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs10of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 10/12 - (30px - 30px * 10/12));
  max-width: calc(99.9% * 10/12 - (30px - 30px * 10/12));
  width: calc(99.9% * 10/12 - (30px - 30px * 10/12));
}

.m-col-xs10of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs10of12:last-child {
  margin-right: 0;
}

.m-col-xs10of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs11of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 11/12 - (30px - 30px * 11/12));
  max-width: calc(99.9% * 11/12 - (30px - 30px * 11/12));
  width: calc(99.9% * 11/12 - (30px - 30px * 11/12));
}

.m-col-xs11of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs11of12:last-child {
  margin-right: 0;
}

.m-col-xs11of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xs12of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 12/12 - (30px - 30px * 12/12));
  max-width: calc(99.9% * 12/12 - (30px - 30px * 12/12));
  width: calc(99.9% * 12/12 - (30px - 30px * 12/12));
}

.m-col-xs12of12:nth-child(1n) {
  margin-right: 30px;
  margin-left: 0;
}

.m-col-xs12of12:last-child {
  margin-right: 0;
}

.m-col-xs12of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin1of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 1/8 - (8px - 8px * 1/8));
  max-width: calc(99.9% * 1/8 - (8px - 8px * 1/8));
  width: calc(99.9% * 1/8 - (8px - 8px * 1/8));
}

.m-col-xsThin1of8:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin1of8:last-child {
  margin-right: 0;
}

.m-col-xsThin1of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin2of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 2/8 - (8px - 8px * 2/8));
  max-width: calc(99.9% * 2/8 - (8px - 8px * 2/8));
  width: calc(99.9% * 2/8 - (8px - 8px * 2/8));
}

.m-col-xsThin2of8:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin2of8:last-child {
  margin-right: 0;
}

.m-col-xsThin2of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin3of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 3/8 - (8px - 8px * 3/8));
  max-width: calc(99.9% * 3/8 - (8px - 8px * 3/8));
  width: calc(99.9% * 3/8 - (8px - 8px * 3/8));
}

.m-col-xsThin3of8:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin3of8:last-child {
  margin-right: 0;
}

.m-col-xsThin3of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin4of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 4/8 - (8px - 8px * 4/8));
  max-width: calc(99.9% * 4/8 - (8px - 8px * 4/8));
  width: calc(99.9% * 4/8 - (8px - 8px * 4/8));
}

.m-col-xsThin4of8:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin4of8:last-child {
  margin-right: 0;
}

.m-col-xsThin4of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin5of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 5/8 - (8px - 8px * 5/8));
  max-width: calc(99.9% * 5/8 - (8px - 8px * 5/8));
  width: calc(99.9% * 5/8 - (8px - 8px * 5/8));
}

.m-col-xsThin5of8:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin5of8:last-child {
  margin-right: 0;
}

.m-col-xsThin5of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin6of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 6/8 - (8px - 8px * 6/8));
  max-width: calc(99.9% * 6/8 - (8px - 8px * 6/8));
  width: calc(99.9% * 6/8 - (8px - 8px * 6/8));
}

.m-col-xsThin6of8:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin6of8:last-child {
  margin-right: 0;
}

.m-col-xsThin6of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin7of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 7/8 - (8px - 8px * 7/8));
  max-width: calc(99.9% * 7/8 - (8px - 8px * 7/8));
  width: calc(99.9% * 7/8 - (8px - 8px * 7/8));
}

.m-col-xsThin7of8:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin7of8:last-child {
  margin-right: 0;
}

.m-col-xsThin7of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin8of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 8/8 - (8px - 8px * 8/8));
  max-width: calc(99.9% * 8/8 - (8px - 8px * 8/8));
  width: calc(99.9% * 8/8 - (8px - 8px * 8/8));
}

.m-col-xsThin8of8:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin8of8:last-child {
  margin-right: 0;
}

.m-col-xsThin8of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin1of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 1/12 - (8px - 8px * 1/12));
  max-width: calc(99.9% * 1/12 - (8px - 8px * 1/12));
  width: calc(99.9% * 1/12 - (8px - 8px * 1/12));
}

.m-col-xsThin1of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin1of12:last-child {
  margin-right: 0;
}

.m-col-xsThin1of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin2of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 2/12 - (8px - 8px * 2/12));
  max-width: calc(99.9% * 2/12 - (8px - 8px * 2/12));
  width: calc(99.9% * 2/12 - (8px - 8px * 2/12));
}

.m-col-xsThin2of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin2of12:last-child {
  margin-right: 0;
}

.m-col-xsThin2of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin3of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 3/12 - (8px - 8px * 3/12));
  max-width: calc(99.9% * 3/12 - (8px - 8px * 3/12));
  width: calc(99.9% * 3/12 - (8px - 8px * 3/12));
}

.m-col-xsThin3of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin3of12:last-child {
  margin-right: 0;
}

.m-col-xsThin3of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin4of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 4/12 - (8px - 8px * 4/12));
  max-width: calc(99.9% * 4/12 - (8px - 8px * 4/12));
  width: calc(99.9% * 4/12 - (8px - 8px * 4/12));
}

.m-col-xsThin4of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin4of12:last-child {
  margin-right: 0;
}

.m-col-xsThin4of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin5of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 5/12 - (8px - 8px * 5/12));
  max-width: calc(99.9% * 5/12 - (8px - 8px * 5/12));
  width: calc(99.9% * 5/12 - (8px - 8px * 5/12));
}

.m-col-xsThin5of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin5of12:last-child {
  margin-right: 0;
}

.m-col-xsThin5of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin6of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 6/12 - (8px - 8px * 6/12));
  max-width: calc(99.9% * 6/12 - (8px - 8px * 6/12));
  width: calc(99.9% * 6/12 - (8px - 8px * 6/12));
}

.m-col-xsThin6of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin6of12:last-child {
  margin-right: 0;
}

.m-col-xsThin6of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin7of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 7/12 - (8px - 8px * 7/12));
  max-width: calc(99.9% * 7/12 - (8px - 8px * 7/12));
  width: calc(99.9% * 7/12 - (8px - 8px * 7/12));
}

.m-col-xsThin7of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin7of12:last-child {
  margin-right: 0;
}

.m-col-xsThin7of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin8of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 8/12 - (8px - 8px * 8/12));
  max-width: calc(99.9% * 8/12 - (8px - 8px * 8/12));
  width: calc(99.9% * 8/12 - (8px - 8px * 8/12));
}

.m-col-xsThin8of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin8of12:last-child {
  margin-right: 0;
}

.m-col-xsThin8of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin9of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 9/12 - (8px - 8px * 9/12));
  max-width: calc(99.9% * 9/12 - (8px - 8px * 9/12));
  width: calc(99.9% * 9/12 - (8px - 8px * 9/12));
}

.m-col-xsThin9of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin9of12:last-child {
  margin-right: 0;
}

.m-col-xsThin9of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin10of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 10/12 - (8px - 8px * 10/12));
  max-width: calc(99.9% * 10/12 - (8px - 8px * 10/12));
  width: calc(99.9% * 10/12 - (8px - 8px * 10/12));
}

.m-col-xsThin10of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin10of12:last-child {
  margin-right: 0;
}

.m-col-xsThin10of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin11of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 11/12 - (8px - 8px * 11/12));
  max-width: calc(99.9% * 11/12 - (8px - 8px * 11/12));
  width: calc(99.9% * 11/12 - (8px - 8px * 11/12));
}

.m-col-xsThin11of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin11of12:last-child {
  margin-right: 0;
}

.m-col-xsThin11of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsThin12of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 12/12 - (8px - 8px * 12/12));
  max-width: calc(99.9% * 12/12 - (8px - 8px * 12/12));
  width: calc(99.9% * 12/12 - (8px - 8px * 12/12));
}

.m-col-xsThin12of12:nth-child(1n) {
  margin-right: 8px;
  margin-left: 0;
}

.m-col-xsThin12of12:last-child {
  margin-right: 0;
}

.m-col-xsThin12of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight1of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 1/8 - (16px - 16px * 1/8));
  max-width: calc(99.9% * 1/8 - (16px - 16px * 1/8));
  width: calc(99.9% * 1/8 - (16px - 16px * 1/8));
}

.m-col-xsTight1of8:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight1of8:last-child {
  margin-right: 0;
}

.m-col-xsTight1of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight2of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 2/8 - (16px - 16px * 2/8));
  max-width: calc(99.9% * 2/8 - (16px - 16px * 2/8));
  width: calc(99.9% * 2/8 - (16px - 16px * 2/8));
}

.m-col-xsTight2of8:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight2of8:last-child {
  margin-right: 0;
}

.m-col-xsTight2of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight3of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 3/8 - (16px - 16px * 3/8));
  max-width: calc(99.9% * 3/8 - (16px - 16px * 3/8));
  width: calc(99.9% * 3/8 - (16px - 16px * 3/8));
}

.m-col-xsTight3of8:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight3of8:last-child {
  margin-right: 0;
}

.m-col-xsTight3of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight4of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 4/8 - (16px - 16px * 4/8));
  max-width: calc(99.9% * 4/8 - (16px - 16px * 4/8));
  width: calc(99.9% * 4/8 - (16px - 16px * 4/8));
}

.m-col-xsTight4of8:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight4of8:last-child {
  margin-right: 0;
}

.m-col-xsTight4of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight5of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 5/8 - (16px - 16px * 5/8));
  max-width: calc(99.9% * 5/8 - (16px - 16px * 5/8));
  width: calc(99.9% * 5/8 - (16px - 16px * 5/8));
}

.m-col-xsTight5of8:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight5of8:last-child {
  margin-right: 0;
}

.m-col-xsTight5of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight6of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 6/8 - (16px - 16px * 6/8));
  max-width: calc(99.9% * 6/8 - (16px - 16px * 6/8));
  width: calc(99.9% * 6/8 - (16px - 16px * 6/8));
}

.m-col-xsTight6of8:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight6of8:last-child {
  margin-right: 0;
}

.m-col-xsTight6of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight7of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 7/8 - (16px - 16px * 7/8));
  max-width: calc(99.9% * 7/8 - (16px - 16px * 7/8));
  width: calc(99.9% * 7/8 - (16px - 16px * 7/8));
}

.m-col-xsTight7of8:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight7of8:last-child {
  margin-right: 0;
}

.m-col-xsTight7of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight8of8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 8/8 - (16px - 16px * 8/8));
  max-width: calc(99.9% * 8/8 - (16px - 16px * 8/8));
  width: calc(99.9% * 8/8 - (16px - 16px * 8/8));
}

.m-col-xsTight8of8:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight8of8:last-child {
  margin-right: 0;
}

.m-col-xsTight8of8:nth-child(8n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight1of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 1/12 - (16px - 16px * 1/12));
  max-width: calc(99.9% * 1/12 - (16px - 16px * 1/12));
  width: calc(99.9% * 1/12 - (16px - 16px * 1/12));
}

.m-col-xsTight1of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight1of12:last-child {
  margin-right: 0;
}

.m-col-xsTight1of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight2of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 2/12 - (16px - 16px * 2/12));
  max-width: calc(99.9% * 2/12 - (16px - 16px * 2/12));
  width: calc(99.9% * 2/12 - (16px - 16px * 2/12));
}

.m-col-xsTight2of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight2of12:last-child {
  margin-right: 0;
}

.m-col-xsTight2of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight3of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 3/12 - (16px - 16px * 3/12));
  max-width: calc(99.9% * 3/12 - (16px - 16px * 3/12));
  width: calc(99.9% * 3/12 - (16px - 16px * 3/12));
}

.m-col-xsTight3of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight3of12:last-child {
  margin-right: 0;
}

.m-col-xsTight3of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight4of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 4/12 - (16px - 16px * 4/12));
  max-width: calc(99.9% * 4/12 - (16px - 16px * 4/12));
  width: calc(99.9% * 4/12 - (16px - 16px * 4/12));
}

.m-col-xsTight4of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight4of12:last-child {
  margin-right: 0;
}

.m-col-xsTight4of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight5of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 5/12 - (16px - 16px * 5/12));
  max-width: calc(99.9% * 5/12 - (16px - 16px * 5/12));
  width: calc(99.9% * 5/12 - (16px - 16px * 5/12));
}

.m-col-xsTight5of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight5of12:last-child {
  margin-right: 0;
}

.m-col-xsTight5of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight6of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 6/12 - (16px - 16px * 6/12));
  max-width: calc(99.9% * 6/12 - (16px - 16px * 6/12));
  width: calc(99.9% * 6/12 - (16px - 16px * 6/12));
}

.m-col-xsTight6of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight6of12:last-child {
  margin-right: 0;
}

.m-col-xsTight6of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight7of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 7/12 - (16px - 16px * 7/12));
  max-width: calc(99.9% * 7/12 - (16px - 16px * 7/12));
  width: calc(99.9% * 7/12 - (16px - 16px * 7/12));
}

.m-col-xsTight7of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight7of12:last-child {
  margin-right: 0;
}

.m-col-xsTight7of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight8of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 8/12 - (16px - 16px * 8/12));
  max-width: calc(99.9% * 8/12 - (16px - 16px * 8/12));
  width: calc(99.9% * 8/12 - (16px - 16px * 8/12));
}

.m-col-xsTight8of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight8of12:last-child {
  margin-right: 0;
}

.m-col-xsTight8of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight9of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 9/12 - (16px - 16px * 9/12));
  max-width: calc(99.9% * 9/12 - (16px - 16px * 9/12));
  width: calc(99.9% * 9/12 - (16px - 16px * 9/12));
}

.m-col-xsTight9of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight9of12:last-child {
  margin-right: 0;
}

.m-col-xsTight9of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight10of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 10/12 - (16px - 16px * 10/12));
  max-width: calc(99.9% * 10/12 - (16px - 16px * 10/12));
  width: calc(99.9% * 10/12 - (16px - 16px * 10/12));
}

.m-col-xsTight10of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight10of12:last-child {
  margin-right: 0;
}

.m-col-xsTight10of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight11of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 11/12 - (16px - 16px * 11/12));
  max-width: calc(99.9% * 11/12 - (16px - 16px * 11/12));
  width: calc(99.9% * 11/12 - (16px - 16px * 11/12));
}

.m-col-xsTight11of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight11of12:last-child {
  margin-right: 0;
}

.m-col-xsTight11of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

.m-col-xsTight12of12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(99.9% * 12/12 - (16px - 16px * 12/12));
  max-width: calc(99.9% * 12/12 - (16px - 16px * 12/12));
  width: calc(99.9% * 12/12 - (16px - 16px * 12/12));
}

.m-col-xsTight12of12:nth-child(1n) {
  margin-right: 16px;
  margin-left: 0;
}

.m-col-xsTight12of12:last-child {
  margin-right: 0;
}

.m-col-xsTight12of12:nth-child(12n) {
  margin-right: 0;
  margin-left: auto;
}

@media (min-width: 420px) {
  .m-col-sm1of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/8 - (30px - 30px * 1/8));
    max-width: calc(99.9% * 1/8 - (30px - 30px * 1/8));
    width: calc(99.9% * 1/8 - (30px - 30px * 1/8));
  }
  .m-col-sm1of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm1of8:last-child {
    margin-right: 0;
  }
  .m-col-sm1of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm2of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/8 - (30px - 30px * 2/8));
    max-width: calc(99.9% * 2/8 - (30px - 30px * 2/8));
    width: calc(99.9% * 2/8 - (30px - 30px * 2/8));
  }
  .m-col-sm2of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm2of8:last-child {
    margin-right: 0;
  }
  .m-col-sm2of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm3of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/8 - (30px - 30px * 3/8));
    max-width: calc(99.9% * 3/8 - (30px - 30px * 3/8));
    width: calc(99.9% * 3/8 - (30px - 30px * 3/8));
  }
  .m-col-sm3of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm3of8:last-child {
    margin-right: 0;
  }
  .m-col-sm3of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm4of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/8 - (30px - 30px * 4/8));
    max-width: calc(99.9% * 4/8 - (30px - 30px * 4/8));
    width: calc(99.9% * 4/8 - (30px - 30px * 4/8));
  }
  .m-col-sm4of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm4of8:last-child {
    margin-right: 0;
  }
  .m-col-sm4of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm5of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/8 - (30px - 30px * 5/8));
    max-width: calc(99.9% * 5/8 - (30px - 30px * 5/8));
    width: calc(99.9% * 5/8 - (30px - 30px * 5/8));
  }
  .m-col-sm5of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm5of8:last-child {
    margin-right: 0;
  }
  .m-col-sm5of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm6of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/8 - (30px - 30px * 6/8));
    max-width: calc(99.9% * 6/8 - (30px - 30px * 6/8));
    width: calc(99.9% * 6/8 - (30px - 30px * 6/8));
  }
  .m-col-sm6of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm6of8:last-child {
    margin-right: 0;
  }
  .m-col-sm6of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm7of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/8 - (30px - 30px * 7/8));
    max-width: calc(99.9% * 7/8 - (30px - 30px * 7/8));
    width: calc(99.9% * 7/8 - (30px - 30px * 7/8));
  }
  .m-col-sm7of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm7of8:last-child {
    margin-right: 0;
  }
  .m-col-sm7of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm8of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/8 - (30px - 30px * 8/8));
    max-width: calc(99.9% * 8/8 - (30px - 30px * 8/8));
    width: calc(99.9% * 8/8 - (30px - 30px * 8/8));
  }
  .m-col-sm8of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm8of8:last-child {
    margin-right: 0;
  }
  .m-col-sm8of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm1of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/12 - (30px - 30px * 1/12));
    max-width: calc(99.9% * 1/12 - (30px - 30px * 1/12));
    width: calc(99.9% * 1/12 - (30px - 30px * 1/12));
  }
  .m-col-sm1of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm1of12:last-child {
    margin-right: 0;
  }
  .m-col-sm1of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm2of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (30px - 30px * 2/12));
    max-width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
    width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
  }
  .m-col-sm2of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm2of12:last-child {
    margin-right: 0;
  }
  .m-col-sm2of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm3of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    max-width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
  }
  .m-col-sm3of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm3of12:last-child {
    margin-right: 0;
  }
  .m-col-sm3of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm4of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/12 - (30px - 30px * 4/12));
    max-width: calc(99.9% * 4/12 - (30px - 30px * 4/12));
    width: calc(99.9% * 4/12 - (30px - 30px * 4/12));
  }
  .m-col-sm4of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm4of12:last-child {
    margin-right: 0;
  }
  .m-col-sm4of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm5of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/12 - (30px - 30px * 5/12));
    max-width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
    width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
  }
  .m-col-sm5of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm5of12:last-child {
    margin-right: 0;
  }
  .m-col-sm5of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm6of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/12 - (30px - 30px * 6/12));
    max-width: calc(99.9% * 6/12 - (30px - 30px * 6/12));
    width: calc(99.9% * 6/12 - (30px - 30px * 6/12));
  }
  .m-col-sm6of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm6of12:last-child {
    margin-right: 0;
  }
  .m-col-sm6of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm7of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (30px - 30px * 7/12));
    max-width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
    width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
  }
  .m-col-sm7of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm7of12:last-child {
    margin-right: 0;
  }
  .m-col-sm7of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm8of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (30px - 30px * 8/12));
    max-width: calc(99.9% * 8/12 - (30px - 30px * 8/12));
    width: calc(99.9% * 8/12 - (30px - 30px * 8/12));
  }
  .m-col-sm8of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm8of12:last-child {
    margin-right: 0;
  }
  .m-col-sm8of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm9of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (30px - 30px * 9/12));
    max-width: calc(99.9% * 9/12 - (30px - 30px * 9/12));
    width: calc(99.9% * 9/12 - (30px - 30px * 9/12));
  }
  .m-col-sm9of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm9of12:last-child {
    margin-right: 0;
  }
  .m-col-sm9of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm10of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 10/12 - (30px - 30px * 10/12));
    max-width: calc(99.9% * 10/12 - (30px - 30px * 10/12));
    width: calc(99.9% * 10/12 - (30px - 30px * 10/12));
  }
  .m-col-sm10of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm10of12:last-child {
    margin-right: 0;
  }
  .m-col-sm10of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm11of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 11/12 - (30px - 30px * 11/12));
    max-width: calc(99.9% * 11/12 - (30px - 30px * 11/12));
    width: calc(99.9% * 11/12 - (30px - 30px * 11/12));
  }
  .m-col-sm11of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm11of12:last-child {
    margin-right: 0;
  }
  .m-col-sm11of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-sm12of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 12/12 - (30px - 30px * 12/12));
    max-width: calc(99.9% * 12/12 - (30px - 30px * 12/12));
    width: calc(99.9% * 12/12 - (30px - 30px * 12/12));
  }
  .m-col-sm12of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-sm12of12:last-child {
    margin-right: 0;
  }
  .m-col-sm12of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin1of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/8 - (8px - 8px * 1/8));
    max-width: calc(99.9% * 1/8 - (8px - 8px * 1/8));
    width: calc(99.9% * 1/8 - (8px - 8px * 1/8));
  }
  .m-col-smThin1of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin1of8:last-child {
    margin-right: 0;
  }
  .m-col-smThin1of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin2of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/8 - (8px - 8px * 2/8));
    max-width: calc(99.9% * 2/8 - (8px - 8px * 2/8));
    width: calc(99.9% * 2/8 - (8px - 8px * 2/8));
  }
  .m-col-smThin2of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin2of8:last-child {
    margin-right: 0;
  }
  .m-col-smThin2of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin3of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/8 - (8px - 8px * 3/8));
    max-width: calc(99.9% * 3/8 - (8px - 8px * 3/8));
    width: calc(99.9% * 3/8 - (8px - 8px * 3/8));
  }
  .m-col-smThin3of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin3of8:last-child {
    margin-right: 0;
  }
  .m-col-smThin3of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin4of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/8 - (8px - 8px * 4/8));
    max-width: calc(99.9% * 4/8 - (8px - 8px * 4/8));
    width: calc(99.9% * 4/8 - (8px - 8px * 4/8));
  }
  .m-col-smThin4of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin4of8:last-child {
    margin-right: 0;
  }
  .m-col-smThin4of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin5of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/8 - (8px - 8px * 5/8));
    max-width: calc(99.9% * 5/8 - (8px - 8px * 5/8));
    width: calc(99.9% * 5/8 - (8px - 8px * 5/8));
  }
  .m-col-smThin5of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin5of8:last-child {
    margin-right: 0;
  }
  .m-col-smThin5of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin6of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/8 - (8px - 8px * 6/8));
    max-width: calc(99.9% * 6/8 - (8px - 8px * 6/8));
    width: calc(99.9% * 6/8 - (8px - 8px * 6/8));
  }
  .m-col-smThin6of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin6of8:last-child {
    margin-right: 0;
  }
  .m-col-smThin6of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin7of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/8 - (8px - 8px * 7/8));
    max-width: calc(99.9% * 7/8 - (8px - 8px * 7/8));
    width: calc(99.9% * 7/8 - (8px - 8px * 7/8));
  }
  .m-col-smThin7of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin7of8:last-child {
    margin-right: 0;
  }
  .m-col-smThin7of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin8of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/8 - (8px - 8px * 8/8));
    max-width: calc(99.9% * 8/8 - (8px - 8px * 8/8));
    width: calc(99.9% * 8/8 - (8px - 8px * 8/8));
  }
  .m-col-smThin8of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin8of8:last-child {
    margin-right: 0;
  }
  .m-col-smThin8of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin1of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/12 - (8px - 8px * 1/12));
    max-width: calc(99.9% * 1/12 - (8px - 8px * 1/12));
    width: calc(99.9% * 1/12 - (8px - 8px * 1/12));
  }
  .m-col-smThin1of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin1of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin1of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin2of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (8px - 8px * 2/12));
    max-width: calc(99.9% * 2/12 - (8px - 8px * 2/12));
    width: calc(99.9% * 2/12 - (8px - 8px * 2/12));
  }
  .m-col-smThin2of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin2of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin2of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin3of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (8px - 8px * 3/12));
    max-width: calc(99.9% * 3/12 - (8px - 8px * 3/12));
    width: calc(99.9% * 3/12 - (8px - 8px * 3/12));
  }
  .m-col-smThin3of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin3of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin3of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin4of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/12 - (8px - 8px * 4/12));
    max-width: calc(99.9% * 4/12 - (8px - 8px * 4/12));
    width: calc(99.9% * 4/12 - (8px - 8px * 4/12));
  }
  .m-col-smThin4of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin4of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin4of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin5of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/12 - (8px - 8px * 5/12));
    max-width: calc(99.9% * 5/12 - (8px - 8px * 5/12));
    width: calc(99.9% * 5/12 - (8px - 8px * 5/12));
  }
  .m-col-smThin5of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin5of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin5of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin6of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/12 - (8px - 8px * 6/12));
    max-width: calc(99.9% * 6/12 - (8px - 8px * 6/12));
    width: calc(99.9% * 6/12 - (8px - 8px * 6/12));
  }
  .m-col-smThin6of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin6of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin6of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin7of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (8px - 8px * 7/12));
    max-width: calc(99.9% * 7/12 - (8px - 8px * 7/12));
    width: calc(99.9% * 7/12 - (8px - 8px * 7/12));
  }
  .m-col-smThin7of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin7of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin7of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin8of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (8px - 8px * 8/12));
    max-width: calc(99.9% * 8/12 - (8px - 8px * 8/12));
    width: calc(99.9% * 8/12 - (8px - 8px * 8/12));
  }
  .m-col-smThin8of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin8of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin8of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin9of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (8px - 8px * 9/12));
    max-width: calc(99.9% * 9/12 - (8px - 8px * 9/12));
    width: calc(99.9% * 9/12 - (8px - 8px * 9/12));
  }
  .m-col-smThin9of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin9of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin9of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin10of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 10/12 - (8px - 8px * 10/12));
    max-width: calc(99.9% * 10/12 - (8px - 8px * 10/12));
    width: calc(99.9% * 10/12 - (8px - 8px * 10/12));
  }
  .m-col-smThin10of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin10of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin10of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin11of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 11/12 - (8px - 8px * 11/12));
    max-width: calc(99.9% * 11/12 - (8px - 8px * 11/12));
    width: calc(99.9% * 11/12 - (8px - 8px * 11/12));
  }
  .m-col-smThin11of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin11of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin11of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smThin12of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 12/12 - (8px - 8px * 12/12));
    max-width: calc(99.9% * 12/12 - (8px - 8px * 12/12));
    width: calc(99.9% * 12/12 - (8px - 8px * 12/12));
  }
  .m-col-smThin12of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-smThin12of12:last-child {
    margin-right: 0;
  }
  .m-col-smThin12of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight1of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/8 - (16px - 16px * 1/8));
    max-width: calc(99.9% * 1/8 - (16px - 16px * 1/8));
    width: calc(99.9% * 1/8 - (16px - 16px * 1/8));
  }
  .m-col-smTight1of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight1of8:last-child {
    margin-right: 0;
  }
  .m-col-smTight1of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight2of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/8 - (16px - 16px * 2/8));
    max-width: calc(99.9% * 2/8 - (16px - 16px * 2/8));
    width: calc(99.9% * 2/8 - (16px - 16px * 2/8));
  }
  .m-col-smTight2of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight2of8:last-child {
    margin-right: 0;
  }
  .m-col-smTight2of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight3of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/8 - (16px - 16px * 3/8));
    max-width: calc(99.9% * 3/8 - (16px - 16px * 3/8));
    width: calc(99.9% * 3/8 - (16px - 16px * 3/8));
  }
  .m-col-smTight3of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight3of8:last-child {
    margin-right: 0;
  }
  .m-col-smTight3of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight4of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/8 - (16px - 16px * 4/8));
    max-width: calc(99.9% * 4/8 - (16px - 16px * 4/8));
    width: calc(99.9% * 4/8 - (16px - 16px * 4/8));
  }
  .m-col-smTight4of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight4of8:last-child {
    margin-right: 0;
  }
  .m-col-smTight4of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight5of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/8 - (16px - 16px * 5/8));
    max-width: calc(99.9% * 5/8 - (16px - 16px * 5/8));
    width: calc(99.9% * 5/8 - (16px - 16px * 5/8));
  }
  .m-col-smTight5of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight5of8:last-child {
    margin-right: 0;
  }
  .m-col-smTight5of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight6of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/8 - (16px - 16px * 6/8));
    max-width: calc(99.9% * 6/8 - (16px - 16px * 6/8));
    width: calc(99.9% * 6/8 - (16px - 16px * 6/8));
  }
  .m-col-smTight6of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight6of8:last-child {
    margin-right: 0;
  }
  .m-col-smTight6of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight7of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/8 - (16px - 16px * 7/8));
    max-width: calc(99.9% * 7/8 - (16px - 16px * 7/8));
    width: calc(99.9% * 7/8 - (16px - 16px * 7/8));
  }
  .m-col-smTight7of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight7of8:last-child {
    margin-right: 0;
  }
  .m-col-smTight7of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight8of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/8 - (16px - 16px * 8/8));
    max-width: calc(99.9% * 8/8 - (16px - 16px * 8/8));
    width: calc(99.9% * 8/8 - (16px - 16px * 8/8));
  }
  .m-col-smTight8of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight8of8:last-child {
    margin-right: 0;
  }
  .m-col-smTight8of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight1of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/12 - (16px - 16px * 1/12));
    max-width: calc(99.9% * 1/12 - (16px - 16px * 1/12));
    width: calc(99.9% * 1/12 - (16px - 16px * 1/12));
  }
  .m-col-smTight1of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight1of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight1of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight2of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (16px - 16px * 2/12));
    max-width: calc(99.9% * 2/12 - (16px - 16px * 2/12));
    width: calc(99.9% * 2/12 - (16px - 16px * 2/12));
  }
  .m-col-smTight2of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight2of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight2of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight3of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (16px - 16px * 3/12));
    max-width: calc(99.9% * 3/12 - (16px - 16px * 3/12));
    width: calc(99.9% * 3/12 - (16px - 16px * 3/12));
  }
  .m-col-smTight3of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight3of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight3of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight4of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/12 - (16px - 16px * 4/12));
    max-width: calc(99.9% * 4/12 - (16px - 16px * 4/12));
    width: calc(99.9% * 4/12 - (16px - 16px * 4/12));
  }
  .m-col-smTight4of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight4of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight4of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight5of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/12 - (16px - 16px * 5/12));
    max-width: calc(99.9% * 5/12 - (16px - 16px * 5/12));
    width: calc(99.9% * 5/12 - (16px - 16px * 5/12));
  }
  .m-col-smTight5of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight5of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight5of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight6of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/12 - (16px - 16px * 6/12));
    max-width: calc(99.9% * 6/12 - (16px - 16px * 6/12));
    width: calc(99.9% * 6/12 - (16px - 16px * 6/12));
  }
  .m-col-smTight6of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight6of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight6of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight7of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (16px - 16px * 7/12));
    max-width: calc(99.9% * 7/12 - (16px - 16px * 7/12));
    width: calc(99.9% * 7/12 - (16px - 16px * 7/12));
  }
  .m-col-smTight7of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight7of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight7of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight8of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (16px - 16px * 8/12));
    max-width: calc(99.9% * 8/12 - (16px - 16px * 8/12));
    width: calc(99.9% * 8/12 - (16px - 16px * 8/12));
  }
  .m-col-smTight8of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight8of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight8of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight9of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (16px - 16px * 9/12));
    max-width: calc(99.9% * 9/12 - (16px - 16px * 9/12));
    width: calc(99.9% * 9/12 - (16px - 16px * 9/12));
  }
  .m-col-smTight9of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight9of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight9of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight10of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 10/12 - (16px - 16px * 10/12));
    max-width: calc(99.9% * 10/12 - (16px - 16px * 10/12));
    width: calc(99.9% * 10/12 - (16px - 16px * 10/12));
  }
  .m-col-smTight10of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight10of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight10of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight11of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 11/12 - (16px - 16px * 11/12));
    max-width: calc(99.9% * 11/12 - (16px - 16px * 11/12));
    width: calc(99.9% * 11/12 - (16px - 16px * 11/12));
  }
  .m-col-smTight11of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight11of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight11of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-smTight12of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 12/12 - (16px - 16px * 12/12));
    max-width: calc(99.9% * 12/12 - (16px - 16px * 12/12));
    width: calc(99.9% * 12/12 - (16px - 16px * 12/12));
  }
  .m-col-smTight12of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-smTight12of12:last-child {
    margin-right: 0;
  }
  .m-col-smTight12of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media (min-width: 1024px) {
  .m-col-md1of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/8 - (30px - 30px * 1/8));
    max-width: calc(99.9% * 1/8 - (30px - 30px * 1/8));
    width: calc(99.9% * 1/8 - (30px - 30px * 1/8));
  }
  .m-col-md1of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md1of8:last-child {
    margin-right: 0;
  }
  .m-col-md1of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md2of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/8 - (30px - 30px * 2/8));
    max-width: calc(99.9% * 2/8 - (30px - 30px * 2/8));
    width: calc(99.9% * 2/8 - (30px - 30px * 2/8));
  }
  .m-col-md2of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md2of8:last-child {
    margin-right: 0;
  }
  .m-col-md2of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md3of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/8 - (30px - 30px * 3/8));
    max-width: calc(99.9% * 3/8 - (30px - 30px * 3/8));
    width: calc(99.9% * 3/8 - (30px - 30px * 3/8));
  }
  .m-col-md3of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md3of8:last-child {
    margin-right: 0;
  }
  .m-col-md3of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md4of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/8 - (30px - 30px * 4/8));
    max-width: calc(99.9% * 4/8 - (30px - 30px * 4/8));
    width: calc(99.9% * 4/8 - (30px - 30px * 4/8));
  }
  .m-col-md4of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md4of8:last-child {
    margin-right: 0;
  }
  .m-col-md4of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md5of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/8 - (30px - 30px * 5/8));
    max-width: calc(99.9% * 5/8 - (30px - 30px * 5/8));
    width: calc(99.9% * 5/8 - (30px - 30px * 5/8));
  }
  .m-col-md5of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md5of8:last-child {
    margin-right: 0;
  }
  .m-col-md5of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md6of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/8 - (30px - 30px * 6/8));
    max-width: calc(99.9% * 6/8 - (30px - 30px * 6/8));
    width: calc(99.9% * 6/8 - (30px - 30px * 6/8));
  }
  .m-col-md6of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md6of8:last-child {
    margin-right: 0;
  }
  .m-col-md6of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md7of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/8 - (30px - 30px * 7/8));
    max-width: calc(99.9% * 7/8 - (30px - 30px * 7/8));
    width: calc(99.9% * 7/8 - (30px - 30px * 7/8));
  }
  .m-col-md7of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md7of8:last-child {
    margin-right: 0;
  }
  .m-col-md7of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md8of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/8 - (30px - 30px * 8/8));
    max-width: calc(99.9% * 8/8 - (30px - 30px * 8/8));
    width: calc(99.9% * 8/8 - (30px - 30px * 8/8));
  }
  .m-col-md8of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md8of8:last-child {
    margin-right: 0;
  }
  .m-col-md8of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md1of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/12 - (30px - 30px * 1/12));
    max-width: calc(99.9% * 1/12 - (30px - 30px * 1/12));
    width: calc(99.9% * 1/12 - (30px - 30px * 1/12));
  }
  .m-col-md1of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md1of12:last-child {
    margin-right: 0;
  }
  .m-col-md1of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md2of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (30px - 30px * 2/12));
    max-width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
    width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
  }
  .m-col-md2of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md2of12:last-child {
    margin-right: 0;
  }
  .m-col-md2of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md3of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    max-width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
  }
  .m-col-md3of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md3of12:last-child {
    margin-right: 0;
  }
  .m-col-md3of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md4of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/12 - (30px - 30px * 4/12));
    max-width: calc(99.9% * 4/12 - (30px - 30px * 4/12));
    width: calc(99.9% * 4/12 - (30px - 30px * 4/12));
  }
  .m-col-md4of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md4of12:last-child {
    margin-right: 0;
  }
  .m-col-md4of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md5of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/12 - (30px - 30px * 5/12));
    max-width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
    width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
  }
  .m-col-md5of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md5of12:last-child {
    margin-right: 0;
  }
  .m-col-md5of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md6of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/12 - (30px - 30px * 6/12));
    max-width: calc(99.9% * 6/12 - (30px - 30px * 6/12));
    width: calc(99.9% * 6/12 - (30px - 30px * 6/12));
  }
  .m-col-md6of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md6of12:last-child {
    margin-right: 0;
  }
  .m-col-md6of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md7of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (30px - 30px * 7/12));
    max-width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
    width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
  }
  .m-col-md7of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md7of12:last-child {
    margin-right: 0;
  }
  .m-col-md7of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md8of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (30px - 30px * 8/12));
    max-width: calc(99.9% * 8/12 - (30px - 30px * 8/12));
    width: calc(99.9% * 8/12 - (30px - 30px * 8/12));
  }
  .m-col-md8of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md8of12:last-child {
    margin-right: 0;
  }
  .m-col-md8of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md9of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (30px - 30px * 9/12));
    max-width: calc(99.9% * 9/12 - (30px - 30px * 9/12));
    width: calc(99.9% * 9/12 - (30px - 30px * 9/12));
  }
  .m-col-md9of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md9of12:last-child {
    margin-right: 0;
  }
  .m-col-md9of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md10of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 10/12 - (30px - 30px * 10/12));
    max-width: calc(99.9% * 10/12 - (30px - 30px * 10/12));
    width: calc(99.9% * 10/12 - (30px - 30px * 10/12));
  }
  .m-col-md10of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md10of12:last-child {
    margin-right: 0;
  }
  .m-col-md10of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md11of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 11/12 - (30px - 30px * 11/12));
    max-width: calc(99.9% * 11/12 - (30px - 30px * 11/12));
    width: calc(99.9% * 11/12 - (30px - 30px * 11/12));
  }
  .m-col-md11of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md11of12:last-child {
    margin-right: 0;
  }
  .m-col-md11of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-md12of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 12/12 - (30px - 30px * 12/12));
    max-width: calc(99.9% * 12/12 - (30px - 30px * 12/12));
    width: calc(99.9% * 12/12 - (30px - 30px * 12/12));
  }
  .m-col-md12of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-md12of12:last-child {
    margin-right: 0;
  }
  .m-col-md12of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin1of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/8 - (8px - 8px * 1/8));
    max-width: calc(99.9% * 1/8 - (8px - 8px * 1/8));
    width: calc(99.9% * 1/8 - (8px - 8px * 1/8));
  }
  .m-col-mdThin1of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin1of8:last-child {
    margin-right: 0;
  }
  .m-col-mdThin1of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin2of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/8 - (8px - 8px * 2/8));
    max-width: calc(99.9% * 2/8 - (8px - 8px * 2/8));
    width: calc(99.9% * 2/8 - (8px - 8px * 2/8));
  }
  .m-col-mdThin2of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin2of8:last-child {
    margin-right: 0;
  }
  .m-col-mdThin2of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin3of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/8 - (8px - 8px * 3/8));
    max-width: calc(99.9% * 3/8 - (8px - 8px * 3/8));
    width: calc(99.9% * 3/8 - (8px - 8px * 3/8));
  }
  .m-col-mdThin3of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin3of8:last-child {
    margin-right: 0;
  }
  .m-col-mdThin3of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin4of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/8 - (8px - 8px * 4/8));
    max-width: calc(99.9% * 4/8 - (8px - 8px * 4/8));
    width: calc(99.9% * 4/8 - (8px - 8px * 4/8));
  }
  .m-col-mdThin4of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin4of8:last-child {
    margin-right: 0;
  }
  .m-col-mdThin4of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin5of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/8 - (8px - 8px * 5/8));
    max-width: calc(99.9% * 5/8 - (8px - 8px * 5/8));
    width: calc(99.9% * 5/8 - (8px - 8px * 5/8));
  }
  .m-col-mdThin5of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin5of8:last-child {
    margin-right: 0;
  }
  .m-col-mdThin5of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin6of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/8 - (8px - 8px * 6/8));
    max-width: calc(99.9% * 6/8 - (8px - 8px * 6/8));
    width: calc(99.9% * 6/8 - (8px - 8px * 6/8));
  }
  .m-col-mdThin6of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin6of8:last-child {
    margin-right: 0;
  }
  .m-col-mdThin6of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin7of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/8 - (8px - 8px * 7/8));
    max-width: calc(99.9% * 7/8 - (8px - 8px * 7/8));
    width: calc(99.9% * 7/8 - (8px - 8px * 7/8));
  }
  .m-col-mdThin7of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin7of8:last-child {
    margin-right: 0;
  }
  .m-col-mdThin7of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin8of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/8 - (8px - 8px * 8/8));
    max-width: calc(99.9% * 8/8 - (8px - 8px * 8/8));
    width: calc(99.9% * 8/8 - (8px - 8px * 8/8));
  }
  .m-col-mdThin8of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin8of8:last-child {
    margin-right: 0;
  }
  .m-col-mdThin8of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin1of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/12 - (8px - 8px * 1/12));
    max-width: calc(99.9% * 1/12 - (8px - 8px * 1/12));
    width: calc(99.9% * 1/12 - (8px - 8px * 1/12));
  }
  .m-col-mdThin1of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin1of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin1of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin2of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (8px - 8px * 2/12));
    max-width: calc(99.9% * 2/12 - (8px - 8px * 2/12));
    width: calc(99.9% * 2/12 - (8px - 8px * 2/12));
  }
  .m-col-mdThin2of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin2of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin2of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin3of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (8px - 8px * 3/12));
    max-width: calc(99.9% * 3/12 - (8px - 8px * 3/12));
    width: calc(99.9% * 3/12 - (8px - 8px * 3/12));
  }
  .m-col-mdThin3of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin3of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin3of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin4of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/12 - (8px - 8px * 4/12));
    max-width: calc(99.9% * 4/12 - (8px - 8px * 4/12));
    width: calc(99.9% * 4/12 - (8px - 8px * 4/12));
  }
  .m-col-mdThin4of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin4of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin4of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin5of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/12 - (8px - 8px * 5/12));
    max-width: calc(99.9% * 5/12 - (8px - 8px * 5/12));
    width: calc(99.9% * 5/12 - (8px - 8px * 5/12));
  }
  .m-col-mdThin5of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin5of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin5of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin6of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/12 - (8px - 8px * 6/12));
    max-width: calc(99.9% * 6/12 - (8px - 8px * 6/12));
    width: calc(99.9% * 6/12 - (8px - 8px * 6/12));
  }
  .m-col-mdThin6of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin6of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin6of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin7of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (8px - 8px * 7/12));
    max-width: calc(99.9% * 7/12 - (8px - 8px * 7/12));
    width: calc(99.9% * 7/12 - (8px - 8px * 7/12));
  }
  .m-col-mdThin7of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin7of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin7of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin8of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (8px - 8px * 8/12));
    max-width: calc(99.9% * 8/12 - (8px - 8px * 8/12));
    width: calc(99.9% * 8/12 - (8px - 8px * 8/12));
  }
  .m-col-mdThin8of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin8of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin8of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin9of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (8px - 8px * 9/12));
    max-width: calc(99.9% * 9/12 - (8px - 8px * 9/12));
    width: calc(99.9% * 9/12 - (8px - 8px * 9/12));
  }
  .m-col-mdThin9of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin9of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin9of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin10of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 10/12 - (8px - 8px * 10/12));
    max-width: calc(99.9% * 10/12 - (8px - 8px * 10/12));
    width: calc(99.9% * 10/12 - (8px - 8px * 10/12));
  }
  .m-col-mdThin10of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin10of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin10of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin11of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 11/12 - (8px - 8px * 11/12));
    max-width: calc(99.9% * 11/12 - (8px - 8px * 11/12));
    width: calc(99.9% * 11/12 - (8px - 8px * 11/12));
  }
  .m-col-mdThin11of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin11of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin11of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdThin12of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 12/12 - (8px - 8px * 12/12));
    max-width: calc(99.9% * 12/12 - (8px - 8px * 12/12));
    width: calc(99.9% * 12/12 - (8px - 8px * 12/12));
  }
  .m-col-mdThin12of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-mdThin12of12:last-child {
    margin-right: 0;
  }
  .m-col-mdThin12of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight1of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/8 - (16px - 16px * 1/8));
    max-width: calc(99.9% * 1/8 - (16px - 16px * 1/8));
    width: calc(99.9% * 1/8 - (16px - 16px * 1/8));
  }
  .m-col-mdTight1of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight1of8:last-child {
    margin-right: 0;
  }
  .m-col-mdTight1of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight2of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/8 - (16px - 16px * 2/8));
    max-width: calc(99.9% * 2/8 - (16px - 16px * 2/8));
    width: calc(99.9% * 2/8 - (16px - 16px * 2/8));
  }
  .m-col-mdTight2of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight2of8:last-child {
    margin-right: 0;
  }
  .m-col-mdTight2of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight3of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/8 - (16px - 16px * 3/8));
    max-width: calc(99.9% * 3/8 - (16px - 16px * 3/8));
    width: calc(99.9% * 3/8 - (16px - 16px * 3/8));
  }
  .m-col-mdTight3of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight3of8:last-child {
    margin-right: 0;
  }
  .m-col-mdTight3of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight4of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/8 - (16px - 16px * 4/8));
    max-width: calc(99.9% * 4/8 - (16px - 16px * 4/8));
    width: calc(99.9% * 4/8 - (16px - 16px * 4/8));
  }
  .m-col-mdTight4of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight4of8:last-child {
    margin-right: 0;
  }
  .m-col-mdTight4of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight5of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/8 - (16px - 16px * 5/8));
    max-width: calc(99.9% * 5/8 - (16px - 16px * 5/8));
    width: calc(99.9% * 5/8 - (16px - 16px * 5/8));
  }
  .m-col-mdTight5of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight5of8:last-child {
    margin-right: 0;
  }
  .m-col-mdTight5of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight6of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/8 - (16px - 16px * 6/8));
    max-width: calc(99.9% * 6/8 - (16px - 16px * 6/8));
    width: calc(99.9% * 6/8 - (16px - 16px * 6/8));
  }
  .m-col-mdTight6of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight6of8:last-child {
    margin-right: 0;
  }
  .m-col-mdTight6of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight7of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/8 - (16px - 16px * 7/8));
    max-width: calc(99.9% * 7/8 - (16px - 16px * 7/8));
    width: calc(99.9% * 7/8 - (16px - 16px * 7/8));
  }
  .m-col-mdTight7of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight7of8:last-child {
    margin-right: 0;
  }
  .m-col-mdTight7of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight8of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/8 - (16px - 16px * 8/8));
    max-width: calc(99.9% * 8/8 - (16px - 16px * 8/8));
    width: calc(99.9% * 8/8 - (16px - 16px * 8/8));
  }
  .m-col-mdTight8of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight8of8:last-child {
    margin-right: 0;
  }
  .m-col-mdTight8of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight1of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/12 - (16px - 16px * 1/12));
    max-width: calc(99.9% * 1/12 - (16px - 16px * 1/12));
    width: calc(99.9% * 1/12 - (16px - 16px * 1/12));
  }
  .m-col-mdTight1of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight1of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight1of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight2of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (16px - 16px * 2/12));
    max-width: calc(99.9% * 2/12 - (16px - 16px * 2/12));
    width: calc(99.9% * 2/12 - (16px - 16px * 2/12));
  }
  .m-col-mdTight2of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight2of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight2of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight3of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (16px - 16px * 3/12));
    max-width: calc(99.9% * 3/12 - (16px - 16px * 3/12));
    width: calc(99.9% * 3/12 - (16px - 16px * 3/12));
  }
  .m-col-mdTight3of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight3of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight3of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight4of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/12 - (16px - 16px * 4/12));
    max-width: calc(99.9% * 4/12 - (16px - 16px * 4/12));
    width: calc(99.9% * 4/12 - (16px - 16px * 4/12));
  }
  .m-col-mdTight4of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight4of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight4of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight5of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/12 - (16px - 16px * 5/12));
    max-width: calc(99.9% * 5/12 - (16px - 16px * 5/12));
    width: calc(99.9% * 5/12 - (16px - 16px * 5/12));
  }
  .m-col-mdTight5of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight5of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight5of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight6of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/12 - (16px - 16px * 6/12));
    max-width: calc(99.9% * 6/12 - (16px - 16px * 6/12));
    width: calc(99.9% * 6/12 - (16px - 16px * 6/12));
  }
  .m-col-mdTight6of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight6of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight6of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight7of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (16px - 16px * 7/12));
    max-width: calc(99.9% * 7/12 - (16px - 16px * 7/12));
    width: calc(99.9% * 7/12 - (16px - 16px * 7/12));
  }
  .m-col-mdTight7of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight7of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight7of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight8of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (16px - 16px * 8/12));
    max-width: calc(99.9% * 8/12 - (16px - 16px * 8/12));
    width: calc(99.9% * 8/12 - (16px - 16px * 8/12));
  }
  .m-col-mdTight8of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight8of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight8of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight9of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (16px - 16px * 9/12));
    max-width: calc(99.9% * 9/12 - (16px - 16px * 9/12));
    width: calc(99.9% * 9/12 - (16px - 16px * 9/12));
  }
  .m-col-mdTight9of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight9of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight9of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight10of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 10/12 - (16px - 16px * 10/12));
    max-width: calc(99.9% * 10/12 - (16px - 16px * 10/12));
    width: calc(99.9% * 10/12 - (16px - 16px * 10/12));
  }
  .m-col-mdTight10of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight10of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight10of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight11of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 11/12 - (16px - 16px * 11/12));
    max-width: calc(99.9% * 11/12 - (16px - 16px * 11/12));
    width: calc(99.9% * 11/12 - (16px - 16px * 11/12));
  }
  .m-col-mdTight11of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight11of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight11of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-mdTight12of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 12/12 - (16px - 16px * 12/12));
    max-width: calc(99.9% * 12/12 - (16px - 16px * 12/12));
    width: calc(99.9% * 12/12 - (16px - 16px * 12/12));
  }
  .m-col-mdTight12of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-mdTight12of12:last-child {
    margin-right: 0;
  }
  .m-col-mdTight12of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
@media (min-width: 1280px) {
  .m-col-lg1of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/8 - (30px - 30px * 1/8));
    max-width: calc(99.9% * 1/8 - (30px - 30px * 1/8));
    width: calc(99.9% * 1/8 - (30px - 30px * 1/8));
  }
  .m-col-lg1of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg1of8:last-child {
    margin-right: 0;
  }
  .m-col-lg1of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg2of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/8 - (30px - 30px * 2/8));
    max-width: calc(99.9% * 2/8 - (30px - 30px * 2/8));
    width: calc(99.9% * 2/8 - (30px - 30px * 2/8));
  }
  .m-col-lg2of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg2of8:last-child {
    margin-right: 0;
  }
  .m-col-lg2of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg3of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/8 - (30px - 30px * 3/8));
    max-width: calc(99.9% * 3/8 - (30px - 30px * 3/8));
    width: calc(99.9% * 3/8 - (30px - 30px * 3/8));
  }
  .m-col-lg3of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg3of8:last-child {
    margin-right: 0;
  }
  .m-col-lg3of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg4of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/8 - (30px - 30px * 4/8));
    max-width: calc(99.9% * 4/8 - (30px - 30px * 4/8));
    width: calc(99.9% * 4/8 - (30px - 30px * 4/8));
  }
  .m-col-lg4of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg4of8:last-child {
    margin-right: 0;
  }
  .m-col-lg4of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg5of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/8 - (30px - 30px * 5/8));
    max-width: calc(99.9% * 5/8 - (30px - 30px * 5/8));
    width: calc(99.9% * 5/8 - (30px - 30px * 5/8));
  }
  .m-col-lg5of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg5of8:last-child {
    margin-right: 0;
  }
  .m-col-lg5of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg6of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/8 - (30px - 30px * 6/8));
    max-width: calc(99.9% * 6/8 - (30px - 30px * 6/8));
    width: calc(99.9% * 6/8 - (30px - 30px * 6/8));
  }
  .m-col-lg6of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg6of8:last-child {
    margin-right: 0;
  }
  .m-col-lg6of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg7of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/8 - (30px - 30px * 7/8));
    max-width: calc(99.9% * 7/8 - (30px - 30px * 7/8));
    width: calc(99.9% * 7/8 - (30px - 30px * 7/8));
  }
  .m-col-lg7of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg7of8:last-child {
    margin-right: 0;
  }
  .m-col-lg7of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg8of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/8 - (30px - 30px * 8/8));
    max-width: calc(99.9% * 8/8 - (30px - 30px * 8/8));
    width: calc(99.9% * 8/8 - (30px - 30px * 8/8));
  }
  .m-col-lg8of8:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg8of8:last-child {
    margin-right: 0;
  }
  .m-col-lg8of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg1of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/12 - (30px - 30px * 1/12));
    max-width: calc(99.9% * 1/12 - (30px - 30px * 1/12));
    width: calc(99.9% * 1/12 - (30px - 30px * 1/12));
  }
  .m-col-lg1of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg1of12:last-child {
    margin-right: 0;
  }
  .m-col-lg1of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg2of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (30px - 30px * 2/12));
    max-width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
    width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
  }
  .m-col-lg2of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg2of12:last-child {
    margin-right: 0;
  }
  .m-col-lg2of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg3of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    max-width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
    width: calc(99.9% * 3/12 - (30px - 30px * 3/12));
  }
  .m-col-lg3of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg3of12:last-child {
    margin-right: 0;
  }
  .m-col-lg3of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg4of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/12 - (30px - 30px * 4/12));
    max-width: calc(99.9% * 4/12 - (30px - 30px * 4/12));
    width: calc(99.9% * 4/12 - (30px - 30px * 4/12));
  }
  .m-col-lg4of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg4of12:last-child {
    margin-right: 0;
  }
  .m-col-lg4of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg5of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/12 - (30px - 30px * 5/12));
    max-width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
    width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
  }
  .m-col-lg5of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg5of12:last-child {
    margin-right: 0;
  }
  .m-col-lg5of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg6of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/12 - (30px - 30px * 6/12));
    max-width: calc(99.9% * 6/12 - (30px - 30px * 6/12));
    width: calc(99.9% * 6/12 - (30px - 30px * 6/12));
  }
  .m-col-lg6of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg6of12:last-child {
    margin-right: 0;
  }
  .m-col-lg6of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg7of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (30px - 30px * 7/12));
    max-width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
    width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
  }
  .m-col-lg7of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg7of12:last-child {
    margin-right: 0;
  }
  .m-col-lg7of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg8of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (30px - 30px * 8/12));
    max-width: calc(99.9% * 8/12 - (30px - 30px * 8/12));
    width: calc(99.9% * 8/12 - (30px - 30px * 8/12));
  }
  .m-col-lg8of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg8of12:last-child {
    margin-right: 0;
  }
  .m-col-lg8of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg9of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (30px - 30px * 9/12));
    max-width: calc(99.9% * 9/12 - (30px - 30px * 9/12));
    width: calc(99.9% * 9/12 - (30px - 30px * 9/12));
  }
  .m-col-lg9of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg9of12:last-child {
    margin-right: 0;
  }
  .m-col-lg9of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg10of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 10/12 - (30px - 30px * 10/12));
    max-width: calc(99.9% * 10/12 - (30px - 30px * 10/12));
    width: calc(99.9% * 10/12 - (30px - 30px * 10/12));
  }
  .m-col-lg10of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg10of12:last-child {
    margin-right: 0;
  }
  .m-col-lg10of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg11of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 11/12 - (30px - 30px * 11/12));
    max-width: calc(99.9% * 11/12 - (30px - 30px * 11/12));
    width: calc(99.9% * 11/12 - (30px - 30px * 11/12));
  }
  .m-col-lg11of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg11of12:last-child {
    margin-right: 0;
  }
  .m-col-lg11of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lg12of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 12/12 - (30px - 30px * 12/12));
    max-width: calc(99.9% * 12/12 - (30px - 30px * 12/12));
    width: calc(99.9% * 12/12 - (30px - 30px * 12/12));
  }
  .m-col-lg12of12:nth-child(1n) {
    margin-right: 30px;
    margin-left: 0;
  }
  .m-col-lg12of12:last-child {
    margin-right: 0;
  }
  .m-col-lg12of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin1of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/8 - (8px - 8px * 1/8));
    max-width: calc(99.9% * 1/8 - (8px - 8px * 1/8));
    width: calc(99.9% * 1/8 - (8px - 8px * 1/8));
  }
  .m-col-lgThin1of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin1of8:last-child {
    margin-right: 0;
  }
  .m-col-lgThin1of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin2of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/8 - (8px - 8px * 2/8));
    max-width: calc(99.9% * 2/8 - (8px - 8px * 2/8));
    width: calc(99.9% * 2/8 - (8px - 8px * 2/8));
  }
  .m-col-lgThin2of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin2of8:last-child {
    margin-right: 0;
  }
  .m-col-lgThin2of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin3of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/8 - (8px - 8px * 3/8));
    max-width: calc(99.9% * 3/8 - (8px - 8px * 3/8));
    width: calc(99.9% * 3/8 - (8px - 8px * 3/8));
  }
  .m-col-lgThin3of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin3of8:last-child {
    margin-right: 0;
  }
  .m-col-lgThin3of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin4of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/8 - (8px - 8px * 4/8));
    max-width: calc(99.9% * 4/8 - (8px - 8px * 4/8));
    width: calc(99.9% * 4/8 - (8px - 8px * 4/8));
  }
  .m-col-lgThin4of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin4of8:last-child {
    margin-right: 0;
  }
  .m-col-lgThin4of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin5of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/8 - (8px - 8px * 5/8));
    max-width: calc(99.9% * 5/8 - (8px - 8px * 5/8));
    width: calc(99.9% * 5/8 - (8px - 8px * 5/8));
  }
  .m-col-lgThin5of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin5of8:last-child {
    margin-right: 0;
  }
  .m-col-lgThin5of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin6of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/8 - (8px - 8px * 6/8));
    max-width: calc(99.9% * 6/8 - (8px - 8px * 6/8));
    width: calc(99.9% * 6/8 - (8px - 8px * 6/8));
  }
  .m-col-lgThin6of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin6of8:last-child {
    margin-right: 0;
  }
  .m-col-lgThin6of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin7of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/8 - (8px - 8px * 7/8));
    max-width: calc(99.9% * 7/8 - (8px - 8px * 7/8));
    width: calc(99.9% * 7/8 - (8px - 8px * 7/8));
  }
  .m-col-lgThin7of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin7of8:last-child {
    margin-right: 0;
  }
  .m-col-lgThin7of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin8of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/8 - (8px - 8px * 8/8));
    max-width: calc(99.9% * 8/8 - (8px - 8px * 8/8));
    width: calc(99.9% * 8/8 - (8px - 8px * 8/8));
  }
  .m-col-lgThin8of8:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin8of8:last-child {
    margin-right: 0;
  }
  .m-col-lgThin8of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin1of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/12 - (8px - 8px * 1/12));
    max-width: calc(99.9% * 1/12 - (8px - 8px * 1/12));
    width: calc(99.9% * 1/12 - (8px - 8px * 1/12));
  }
  .m-col-lgThin1of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin1of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin1of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin2of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (8px - 8px * 2/12));
    max-width: calc(99.9% * 2/12 - (8px - 8px * 2/12));
    width: calc(99.9% * 2/12 - (8px - 8px * 2/12));
  }
  .m-col-lgThin2of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin2of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin2of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin3of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (8px - 8px * 3/12));
    max-width: calc(99.9% * 3/12 - (8px - 8px * 3/12));
    width: calc(99.9% * 3/12 - (8px - 8px * 3/12));
  }
  .m-col-lgThin3of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin3of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin3of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin4of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/12 - (8px - 8px * 4/12));
    max-width: calc(99.9% * 4/12 - (8px - 8px * 4/12));
    width: calc(99.9% * 4/12 - (8px - 8px * 4/12));
  }
  .m-col-lgThin4of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin4of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin4of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin5of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/12 - (8px - 8px * 5/12));
    max-width: calc(99.9% * 5/12 - (8px - 8px * 5/12));
    width: calc(99.9% * 5/12 - (8px - 8px * 5/12));
  }
  .m-col-lgThin5of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin5of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin5of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin6of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/12 - (8px - 8px * 6/12));
    max-width: calc(99.9% * 6/12 - (8px - 8px * 6/12));
    width: calc(99.9% * 6/12 - (8px - 8px * 6/12));
  }
  .m-col-lgThin6of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin6of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin6of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin7of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (8px - 8px * 7/12));
    max-width: calc(99.9% * 7/12 - (8px - 8px * 7/12));
    width: calc(99.9% * 7/12 - (8px - 8px * 7/12));
  }
  .m-col-lgThin7of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin7of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin7of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin8of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (8px - 8px * 8/12));
    max-width: calc(99.9% * 8/12 - (8px - 8px * 8/12));
    width: calc(99.9% * 8/12 - (8px - 8px * 8/12));
  }
  .m-col-lgThin8of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin8of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin8of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin9of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (8px - 8px * 9/12));
    max-width: calc(99.9% * 9/12 - (8px - 8px * 9/12));
    width: calc(99.9% * 9/12 - (8px - 8px * 9/12));
  }
  .m-col-lgThin9of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin9of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin9of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin10of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 10/12 - (8px - 8px * 10/12));
    max-width: calc(99.9% * 10/12 - (8px - 8px * 10/12));
    width: calc(99.9% * 10/12 - (8px - 8px * 10/12));
  }
  .m-col-lgThin10of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin10of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin10of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin11of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 11/12 - (8px - 8px * 11/12));
    max-width: calc(99.9% * 11/12 - (8px - 8px * 11/12));
    width: calc(99.9% * 11/12 - (8px - 8px * 11/12));
  }
  .m-col-lgThin11of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin11of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin11of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgThin12of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 12/12 - (8px - 8px * 12/12));
    max-width: calc(99.9% * 12/12 - (8px - 8px * 12/12));
    width: calc(99.9% * 12/12 - (8px - 8px * 12/12));
  }
  .m-col-lgThin12of12:nth-child(1n) {
    margin-right: 8px;
    margin-left: 0;
  }
  .m-col-lgThin12of12:last-child {
    margin-right: 0;
  }
  .m-col-lgThin12of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight1of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/8 - (16px - 16px * 1/8));
    max-width: calc(99.9% * 1/8 - (16px - 16px * 1/8));
    width: calc(99.9% * 1/8 - (16px - 16px * 1/8));
  }
  .m-col-lgTight1of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight1of8:last-child {
    margin-right: 0;
  }
  .m-col-lgTight1of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight2of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/8 - (16px - 16px * 2/8));
    max-width: calc(99.9% * 2/8 - (16px - 16px * 2/8));
    width: calc(99.9% * 2/8 - (16px - 16px * 2/8));
  }
  .m-col-lgTight2of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight2of8:last-child {
    margin-right: 0;
  }
  .m-col-lgTight2of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight3of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/8 - (16px - 16px * 3/8));
    max-width: calc(99.9% * 3/8 - (16px - 16px * 3/8));
    width: calc(99.9% * 3/8 - (16px - 16px * 3/8));
  }
  .m-col-lgTight3of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight3of8:last-child {
    margin-right: 0;
  }
  .m-col-lgTight3of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight4of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/8 - (16px - 16px * 4/8));
    max-width: calc(99.9% * 4/8 - (16px - 16px * 4/8));
    width: calc(99.9% * 4/8 - (16px - 16px * 4/8));
  }
  .m-col-lgTight4of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight4of8:last-child {
    margin-right: 0;
  }
  .m-col-lgTight4of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight5of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/8 - (16px - 16px * 5/8));
    max-width: calc(99.9% * 5/8 - (16px - 16px * 5/8));
    width: calc(99.9% * 5/8 - (16px - 16px * 5/8));
  }
  .m-col-lgTight5of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight5of8:last-child {
    margin-right: 0;
  }
  .m-col-lgTight5of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight6of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/8 - (16px - 16px * 6/8));
    max-width: calc(99.9% * 6/8 - (16px - 16px * 6/8));
    width: calc(99.9% * 6/8 - (16px - 16px * 6/8));
  }
  .m-col-lgTight6of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight6of8:last-child {
    margin-right: 0;
  }
  .m-col-lgTight6of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight7of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/8 - (16px - 16px * 7/8));
    max-width: calc(99.9% * 7/8 - (16px - 16px * 7/8));
    width: calc(99.9% * 7/8 - (16px - 16px * 7/8));
  }
  .m-col-lgTight7of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight7of8:last-child {
    margin-right: 0;
  }
  .m-col-lgTight7of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight8of8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/8 - (16px - 16px * 8/8));
    max-width: calc(99.9% * 8/8 - (16px - 16px * 8/8));
    width: calc(99.9% * 8/8 - (16px - 16px * 8/8));
  }
  .m-col-lgTight8of8:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight8of8:last-child {
    margin-right: 0;
  }
  .m-col-lgTight8of8:nth-child(8n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight1of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/12 - (16px - 16px * 1/12));
    max-width: calc(99.9% * 1/12 - (16px - 16px * 1/12));
    width: calc(99.9% * 1/12 - (16px - 16px * 1/12));
  }
  .m-col-lgTight1of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight1of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight1of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight2of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/12 - (16px - 16px * 2/12));
    max-width: calc(99.9% * 2/12 - (16px - 16px * 2/12));
    width: calc(99.9% * 2/12 - (16px - 16px * 2/12));
  }
  .m-col-lgTight2of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight2of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight2of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight3of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 3/12 - (16px - 16px * 3/12));
    max-width: calc(99.9% * 3/12 - (16px - 16px * 3/12));
    width: calc(99.9% * 3/12 - (16px - 16px * 3/12));
  }
  .m-col-lgTight3of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight3of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight3of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight4of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 4/12 - (16px - 16px * 4/12));
    max-width: calc(99.9% * 4/12 - (16px - 16px * 4/12));
    width: calc(99.9% * 4/12 - (16px - 16px * 4/12));
  }
  .m-col-lgTight4of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight4of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight4of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight5of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 5/12 - (16px - 16px * 5/12));
    max-width: calc(99.9% * 5/12 - (16px - 16px * 5/12));
    width: calc(99.9% * 5/12 - (16px - 16px * 5/12));
  }
  .m-col-lgTight5of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight5of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight5of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight6of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 6/12 - (16px - 16px * 6/12));
    max-width: calc(99.9% * 6/12 - (16px - 16px * 6/12));
    width: calc(99.9% * 6/12 - (16px - 16px * 6/12));
  }
  .m-col-lgTight6of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight6of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight6of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight7of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 7/12 - (16px - 16px * 7/12));
    max-width: calc(99.9% * 7/12 - (16px - 16px * 7/12));
    width: calc(99.9% * 7/12 - (16px - 16px * 7/12));
  }
  .m-col-lgTight7of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight7of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight7of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight8of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 8/12 - (16px - 16px * 8/12));
    max-width: calc(99.9% * 8/12 - (16px - 16px * 8/12));
    width: calc(99.9% * 8/12 - (16px - 16px * 8/12));
  }
  .m-col-lgTight8of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight8of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight8of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight9of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 9/12 - (16px - 16px * 9/12));
    max-width: calc(99.9% * 9/12 - (16px - 16px * 9/12));
    width: calc(99.9% * 9/12 - (16px - 16px * 9/12));
  }
  .m-col-lgTight9of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight9of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight9of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight10of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 10/12 - (16px - 16px * 10/12));
    max-width: calc(99.9% * 10/12 - (16px - 16px * 10/12));
    width: calc(99.9% * 10/12 - (16px - 16px * 10/12));
  }
  .m-col-lgTight10of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight10of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight10of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight11of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 11/12 - (16px - 16px * 11/12));
    max-width: calc(99.9% * 11/12 - (16px - 16px * 11/12));
    width: calc(99.9% * 11/12 - (16px - 16px * 11/12));
  }
  .m-col-lgTight11of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight11of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight11of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
  .m-col-lgTight12of12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 12/12 - (16px - 16px * 12/12));
    max-width: calc(99.9% * 12/12 - (16px - 16px * 12/12));
    width: calc(99.9% * 12/12 - (16px - 16px * 12/12));
  }
  .m-col-lgTight12of12:nth-child(1n) {
    margin-right: 16px;
    margin-left: 0;
  }
  .m-col-lgTight12of12:last-child {
    margin-right: 0;
  }
  .m-col-lgTight12of12:nth-child(12n) {
    margin-right: 0;
    margin-left: auto;
  }
}
.m-toggleButton {
  position: relative;
  min-height: 2rem;
  margin: 1rem 0;
  font-size: 1rem;
}
.m-toggleButton .m-input-errorMessage {
  margin-top: 0;
  margin-right: 5.3125rem;
  font-size: 1rem;
}

.m-toggleButton-label {
  display: block;
  margin-right: 5.3125rem;
  padding: 0.5rem 1.25rem 0.5rem 0;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -webkit-hyphens: auto;
  hyphens: auto;
  color: #001432;
  line-height: 1.5;
}

.m-toggleButton-input {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  padding: 3px;
  width: 4.5rem;
  height: auto;
  border: none;
  border-radius: 1rem;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  background-color: #e6e8eb;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
  transition-property: background-color;
  transition-duration: 0.18s;
  transition-timing-function: ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  /* Don't use focus() mixin, to also disable outline for touch devices */
}
.m-toggleButton-input:focus {
  outline: none;
}
html:not(.touchevents) .m-toggleButton-input:focus {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25), 0 0 0 2px rgba(86, 119, 252, 0.5);
}

.m-toggleButton-input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}

.m-toggleButton-isActive .m-toggleButton-input {
  background-color: #34c082;
}

.m-toggleButton-switch {
  position: relative;
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 0.8125rem;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  transition-property: transform, width;
  transition-duration: inherit;
  transition-timing-function: inherit;
  pointer-events: none;
}

.m-toggleButton-isReadOnly {
  pointer-events: none;
}

.m-toggleButton:not(.m-toggleButton-isBlocked) .m-toggleButton-input:active .m-toggleButton-switch {
  width: 1.9375rem;
}

.m-toggleButton-isActive .m-toggleButton-switch {
  transform: translateX(2.5rem);
}

.m-toggleButton-isActive:not(.m-toggleButton-isBlocked) .m-toggleButton-input:active .m-toggleButton-switch {
  transform: translateX(2.1875rem);
}

.m-toggleButton-isBlocked .m-toggleButton-switch {
  color: #99a1ad;
  background-color: #ffffff;
}

.m-toggleButton-isBlocked .m-toggleButton-switch .icon {
  font-size: inherit;
  line-height: inherit;
}

.m-toggleButton-isShaking .m-toggleButton-switch {
  animation: 0.4s toggleSwitch-shake;
}

@keyframes toggleSwitch-shake {
  0% {
    left: 3px;
  }
  20% {
    left: -2.5px;
  }
  40% {
    left: 1.5px;
  }
  60% {
    left: -1px;
  }
  80% {
    left: 0.5px;
  }
  100% {
    left: 0;
  }
}
.m-toggleButton-option {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0 0.625rem;
  line-height: 2rem;
  text-align: center;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  color: #667284;
  transition-property: opacity;
  transition-duration: inherit;
  transition-timing-function: inherit;
  overflow: hidden;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.m-toggleButton-option-isTrue {
  padding: 0 2rem 0 0.5rem;
  opacity: 0;
}
.m-toggleButton-option-isFalse {
  padding: 0 0.5rem 0 2rem;
}

.m-toggleButton-isActive .m-toggleButton-option-isTrue {
  color: #ffffff;
  opacity: 1;
}

.m-toggleButton-isActive .m-toggleButton-option-isFalse {
  opacity: 0;
}

.m-toggleButton-isDecision.m-toggleButton-isActive .m-toggleButton-input {
  background-color: #0064fe;
}

.m-toggleButton-hasWarning .m-toggleButton-input {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25), 0 0 0 2px #eb8676;
}

.m-toggleButton-isCompact .m-toggleButton-input {
  width: 3rem;
  height: 1.625rem;
}
.m-toggleButton-isCompact .m-toggleButton-switch {
  width: 1.25rem;
  height: 1.25rem;
}
.m-toggleButton-isCompact.m-toggleButton-isActive .m-toggleButton-switch {
  transform: translateX(1.375rem);
}
.m-toggleButton-isCompact.m-toggleButton-isActive:not(.m-toggleButton-isBlocked) .m-toggleButton-input:active .m-toggleButton-switch {
  transform: translateX(0.6875rem);
}
.m-toggleButton-isCompact .m-toggleButton-option {
  display: none;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 17.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top .arrow, .bs-popover-auto[x-placement^=top] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^=top] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::after {
  bottom: 1px;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right .arrow, .bs-popover-auto[x-placement^=right] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^=right] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::after {
  left: 1px;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^=bottom] .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::after {
  top: 1px;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left .arrow, .bs-popover-auto[x-placement^=left] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^=left] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::after {
  right: 1px;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.m-popover.popover {
  position: absolute;
  z-index: 1060;
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  word-wrap: break-word;
  box-shadow: 0 2px 8px 0 #e6e8eb;
  border-radius: 4px;
  border: none;
  padding: 1rem;
  background-color: #ffffff;
  overflow: hidden;
  width: 26rem;
  max-width: 26rem;
}
.m-popover.popover .popover-body {
  color: #001432;
  overflow-y: auto;
  max-height: 9rem;
  background-color: #ffffff;
  padding: 0;
}
.m-popover.popover .arrow {
  display: none;
}

.m-tabPanel {
  border-top: 1px solid #f2f3f5;
}
.m-tabPanel-isPadded {
  padding: 32px;
}
@media (max-width: 321px) {
  .m-tabPanel-isPadded {
    padding: 16px;
  }
}
.m-tabPanel-inactive {
  display: none;
}

.m-tabPanel-list {
  padding: 0;
  display: flex;
  border-bottom: 0.0625rem solid #e6e8eb;
}
.m-tabPanel-list-isSticky {
  position: sticky;
  top: 3.5rem;
  background: white;
  z-index: 1;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}
@media (min-width: 768px) {
  .m-tabPanel-list-isSticky {
    top: 5rem;
  }
}

.m-tabPanel-tabs {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
}

.m-tabPanel-tab {
  border-radius: 0;
  background-color: #ffffff;
  height: 3rem;
  align-self: stretch;
  flex-grow: 1;
  flex-basis: 0;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 transparent;
  transition: 0.3s box-shadow;
  /* Don"t use focus() mixin, to also disable outline for touch devices */
}
.m-tabPanel-tab:focus {
  outline: 0 none;
}
.m-tabPanel-tab:disabled {
  color: #99a1ad;
  text-decoration: none !important;
  pointer-events: none;
}
html:not(.touchevents) .m-tabPanel-tab:hover .m-tabPanel-tab-label {
  text-decoration: underline;
}
.m-tabPanel-tab-selected {
  color: #002d72;
  pointer-events: none;
  box-shadow: inset 0 -4px 0 0 #002d72;
}
.m-tabPanel-tab-selected#loginbutton {
  box-shadow: inset 0 -6px 0 0 #34c082;
}
.m-tabPanel-tab-selected#signupbutton {
  box-shadow: inset 0 -6px 0 0 #0064fe;
}
.m-tabPanel-tab .m-tabPanel-tab-label {
  font-family: inherit;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: #0064fe;
}
.m-tabPanel-tab .m-tabPanel-tab-mark {
  opacity: 1;
  color: rgb(255, 159, 0);
  font-size: 0.625rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  vertical-align: super;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #ccd0d6;
  margin: 2rem 0;
}

.card-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  color: #002d72;
  background-color: #ffffff;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom: 1px solid #f2f3f5;
  min-height: 110px;
  padding: 16px;
}

.card-header__headline {
  font-weight: normal;
  font-size: 1.25rem;
}

.card-content {
  padding: 0 0 1rem 1rem;
}

.card-footer {
  height: 4rem;
  margin-top: 2rem;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f2f3f5;
}

/* Todo: Refactor css classnames to MCSS */
.m-notificationDeprecated {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  pointer-events: none;
}
.m-notificationDeprecated a {
  color: #ffffff !important;
}
.m-notificationDeprecated a.m-close-notification {
  width: 2rem;
  height: 2rem;
  line-height: 25px;
  font-size: 2.5rem;
  font-weight: 300;
  cursor: pointer;
  text-align: center;
  color: #667284;
  margin-left: 1rem;
  align-self: flex-start;
}
.m-notificationDeprecated a.m-close-notification:hover {
  text-decoration: none;
}
.m-notificationDeprecated--active {
  pointer-events: auto;
}
.m-notificationDeprecated--blocking::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: -1;
  visibility: hidden;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color, visibility, z-index;
  transition-duration: 400ms, 0s, 0s;
  transition-delay: 200ms, 600ms, 600ms;
}
.m-notificationDeprecated--blocking.m-notificationDeprecated--active::before {
  z-index: 1;
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
  background-color: rgba(255, 255, 255, 0.4);
}
.m-notificationDeprecated__action {
  white-space: nowrap;
}
.m-notificationDeprecated__message {
  flex-grow: 1;
  line-height: 1.5;
}
.m-notificationDeprecated__message a {
  text-decoration: underline;
}
.m-notificationDeprecated__content {
  position: relative;
  min-height: 4rem;
  color: #ffffff;
  padding: 1rem 1.5rem;
  z-index: 2;
  opacity: 0.5;
  transform: translateY(100%);
  transition: 200ms opacity, 200ms transform;
}
.m-notificationDeprecated--active .m-notificationDeprecated__content {
  transform: translateY(0);
  opacity: 1;
}
.m-notificationDeprecated--message {
  background-color: #6681aa;
}
.m-notificationDeprecated--error {
  background-color: #de351b;
}

.m-footer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem;
  width: 100%;
  height: 3rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: #667284;
  background-color: transparent;
}
.m-footer-isMobile {
  height: auto;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  padding: 0.625rem 0rem;
  border-top: 1px solid #e6e8eb;
}
.m-footer-isMobile-inModal {
  border-top: none;
  padding: 0;
}
.m-footer-select {
  position: relative;
  margin-bottom: 0.625rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
.m-footer-selectInput {
  color: inherit;
  margin: 0;
  height: 1.5rem;
  padding: 0 0.75rem 0 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
}
.m-footer-select .m-icon {
  position: absolute;
  right: 0;
  top: 0.1875rem;
  line-height: 1.25rem;
  font-size: 0.75rem;
}
.m-footer-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  line-height: 1.5rem;
  color: inherit;
  text-decoration: underline;
  display: inline-block;
}
.m-footer-link-isActive {
  color: #001432;
  font-weight: bold;
  text-decoration: none;
}
@media (min-width: 768px) {
  .m-footer {
    padding: 0.875rem 1.5rem;
  }
}
.m-footer-copyright {
  flex-basis: 100%;
  text-align: center;
  line-height: 1.5rem;
}

.m-barcodeCard {
  display: flex;
  position: absolute;
  top: 2rem;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0;
  max-height: 100vh;
  margin-bottom: 0;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 8px 32px 0 rgba(30, 48, 86, 0.1);
  border-radius: 1rem;
  overflow: hidden;
}
.m-barcodeCard-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.3125rem;
}
@media (min-height: 38.75rem) {
  .m-barcodeCard-pagination {
    height: 5.3125rem;
  }
}
.m-barcodeCard-paginationButton {
  width: 1.0625rem;
  height: 1.0625rem;
  padding: 0 0.3125rem;
  background: transparent;
  border: 0 none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.m-barcodeCard-paginationButton:focus {
  outline: 0 none;
}
.m-barcodeCard-paginationIndicator {
  display: block;
  width: 0.4375rem;
  height: 0.4375rem;
  background: #99c1ff;
  border: 0.125rem solid transparent;
  border-radius: 50%;
  transition: 250ms border-color, 250ms background-color;
}
.m-barcodeCard-paginationButton-isActive .m-barcodeCard-paginationIndicator {
  border-color: #ffffff;
  background: transparent;
}
.m-barcodeCard-section {
  padding: 0 1rem;
  flex-grow: 0;
}
.m-barcodeCard-section + .m-barcodeCard-section {
  padding-top: 0.0625rem;
  box-shadow: inset 0 0.0625rem 0 0 #f2f3f5;
}
.m-barcodeCard-section > p {
  padding-bottom: 0;
  color: #33435b;
}
@media (min-height: 38.75rem) {
  .m-barcodeCard-section > p {
    padding-bottom: 0.5rem;
  }
}
.m-barcodeCard-section > small {
  color: #33435b;
}
.m-barcodeCard-section > a {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.m-barcodeCard-hasImage.m-barcodeCard-section {
  display: flex;
}
.m-barcodeCard-hasImage.m-barcodeCard-section > p {
  margin-right: auto;
  min-width: 0;
}
.m-barcodeCard-code {
  position: relative;
  margin: 1.875rem 1rem 0.875rem;
  max-height: 11.25rem;
  min-height: 1.25rem;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
}
.m-barcodeCard-code svg,
.m-barcodeCard-code img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.m-barcodeCard-code svg {
  height: 100%;
}
.m-barcodeCard-secure {
  position: relative;
  height: 3rem;
  overflow: hidden;
  border-radius: 0 0 1rem 1rem;
  flex-grow: 0;
  transform: translateZ(0);
}
@media (min-height: 38.75rem) {
  .m-barcodeCard-secure {
    height: 4.0625rem;
  }
}
.m-barcodeCard-secure strong {
  position: relative;
  display: block;
  text-align: center;
  padding: 1rem 0;
  color: white;
  font-weight: bold;
  z-index: 1;
}
@media (min-height: 38.75rem) {
  .m-barcodeCard-secure strong {
    padding: 1.4375rem 0;
  }
}
.m-barcodeCard-secure span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20%;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.m-barcodeCard-secure span:nth-child(1) {
  left: -14.2857142857%;
  width: 16.2857142857%;
  /* filter: hue-rotate(random(10) * -1deg) saturate(115 - random(30) * 1%); */
  animation-name: barcode-secure-1;
  animation-duration: 3.6s;
}
@keyframes barcode-secure-1 {
  0%, 100% {
    transform: translateX(158.2857142857%) scaleX(1.1);
  }
  50% {
    transform: translateX(-103.7142857143%) scaleX(1);
  }
}
.m-barcodeCard-secure span:nth-child(2) {
  left: 0%;
  width: 17.2857142857%;
  /* filter: hue-rotate(random(10) * -1deg) saturate(115 - random(30) * 1%); */
  animation-name: barcode-secure-2;
  animation-duration: 5.3s;
}
@keyframes barcode-secure-2 {
  0%, 100% {
    transform: translateX(113.2857142857%) scaleX(1.44);
  }
  50% {
    transform: translateX(-9.7142857143%) scaleX(1);
  }
}
.m-barcodeCard-secure span:nth-child(3) {
  left: 14.2857142857%;
  width: 32.2857142857%;
  /* filter: hue-rotate(random(10) * -1deg) saturate(115 - random(30) * 1%); */
  animation-name: barcode-secure-3;
  animation-duration: 6s;
}
@keyframes barcode-secure-3 {
  0%, 100% {
    transform: translateX(136.2857142857%) scaleX(1.22);
  }
  50% {
    transform: translateX(-39.7142857143%) scaleX(1);
  }
}
.m-barcodeCard-secure span:nth-child(4) {
  left: 28.5714285714%;
  width: 18.2857142857%;
  /* filter: hue-rotate(random(10) * -1deg) saturate(115 - random(30) * 1%); */
  animation-name: barcode-secure-4;
  animation-duration: 6.6s;
}
@keyframes barcode-secure-4 {
  0%, 100% {
    transform: translateX(25.2857142857%) scaleX(1.37);
  }
  50% {
    transform: translateX(-4.7142857143%) scaleX(1);
  }
}
.m-barcodeCard-secure span:nth-child(5) {
  left: 42.8571428571%;
  width: 30.2857142857%;
  /* filter: hue-rotate(random(10) * -1deg) saturate(115 - random(30) * 1%); */
  animation-name: barcode-secure-5;
  animation-duration: 7s;
}
@keyframes barcode-secure-5 {
  0%, 100% {
    transform: translateX(44.2857142857%) scaleX(0.91);
  }
  50% {
    transform: translateX(-52.7142857143%) scaleX(1);
  }
}
.m-barcodeCard-secure span:nth-child(6) {
  left: 57.1428571429%;
  width: 29.2857142857%;
  /* filter: hue-rotate(random(10) * -1deg) saturate(115 - random(30) * 1%); */
  animation-name: barcode-secure-6;
  animation-duration: 6.4s;
}
@keyframes barcode-secure-6 {
  0%, 100% {
    transform: translateX(63.2857142857%) scaleX(0.57);
  }
  50% {
    transform: translateX(21.2857142857%) scaleX(1);
  }
}
.m-barcodeCard-secure span:nth-child(7) {
  left: 71.4285714286%;
  width: 17.2857142857%;
  /* filter: hue-rotate(random(10) * -1deg) saturate(115 - random(30) * 1%); */
  animation-name: barcode-secure-7;
  animation-duration: 7.5s;
}
@keyframes barcode-secure-7 {
  0%, 100% {
    transform: translateX(87.2857142857%) scaleX(1.28);
  }
  50% {
    transform: translateX(-104.7142857143%) scaleX(1);
  }
}
.m-barcodeCard-blue {
  /* TODO OHOH */
  background-color: #48b0ff;
  content: "#48b0ff";
  content: "#3399ff";
  content: "#247bff";
  content: "#113dff";
  content: "#4ba5ff";
}
.m-barcodeCard-blue span {
  background-color: rgba(72, 176, 255, 0.8);
}
.m-barcodeCard-blue span:nth-child(5n+1) {
  background-color: rgba(72, 176, 255, 0.5);
}
.m-barcodeCard-blue span:nth-child(5n+2) {
  background-color: rgba(51, 153, 255, 0.5);
}
.m-barcodeCard-blue span:nth-child(5n+3) {
  background-color: rgba(36, 123, 255, 0.5);
}
.m-barcodeCard-blue span:nth-child(5n+4) {
  background-color: rgba(17, 61, 255, 0.5);
}
.m-barcodeCard-blue span:nth-child(5n+5) {
  background-color: rgba(75, 165, 255, 0.5);
}
.m-barcodeCard-gold {
  /* TODO OHOH */
  background-color: #f5cd47;
  content: "#f5cd47";
  content: "#c58700";
  content: "#d19f07";
  content: "#deb630";
  content: "#ba7900";
}
.m-barcodeCard-gold span {
  background-color: rgba(245, 205, 71, 0.8);
}
.m-barcodeCard-gold span:nth-child(5n+1) {
  background-color: rgba(245, 205, 71, 0.5);
}
.m-barcodeCard-gold span:nth-child(5n+2) {
  background-color: rgba(197, 135, 0, 0.5);
}
.m-barcodeCard-gold span:nth-child(5n+3) {
  background-color: rgba(209, 159, 7, 0.5);
}
.m-barcodeCard-gold span:nth-child(5n+4) {
  background-color: rgba(222, 182, 48, 0.5);
}
.m-barcodeCard-gold span:nth-child(5n+5) {
  background-color: rgba(186, 121, 0, 0.5);
}
.m-barcodeCard-red {
  /* TODO OHOH */
  background-color: #c80000;
  content: "#c80000";
  content: "#ab0000";
  content: "#b90000";
  content: "#920000";
  content: "#e60000";
}
.m-barcodeCard-red span {
  background-color: rgba(200, 0, 0, 0.8);
}
.m-barcodeCard-red span:nth-child(5n+1) {
  background-color: rgba(200, 0, 0, 0.5);
}
.m-barcodeCard-red span:nth-child(5n+2) {
  background-color: rgba(171, 0, 0, 0.5);
}
.m-barcodeCard-red span:nth-child(5n+3) {
  background-color: rgba(185, 0, 0, 0.5);
}
.m-barcodeCard-red span:nth-child(5n+4) {
  background-color: rgba(146, 0, 0, 0.5);
}
.m-barcodeCard-red span:nth-child(5n+5) {
  background-color: rgba(230, 0, 0, 0.5);
}
.m-barcodeCard-green {
  /* TODO OHOH */
  background-color: #95ce00;
  content: "#95ce00";
  content: "#c9e600";
  content: "#b3dc00";
  content: "#d8ed00";
  content: "#e3f200";
}
.m-barcodeCard-green span {
  background-color: rgba(149, 206, 0, 0.8);
}
.m-barcodeCard-green span:nth-child(5n+1) {
  background-color: rgba(149, 206, 0, 0.5);
}
.m-barcodeCard-green span:nth-child(5n+2) {
  background-color: rgba(201, 230, 0, 0.5);
}
.m-barcodeCard-green span:nth-child(5n+3) {
  background-color: rgba(179, 220, 0, 0.5);
}
.m-barcodeCard-green span:nth-child(5n+4) {
  background-color: rgba(216, 237, 0, 0.5);
}
.m-barcodeCard-green span:nth-child(5n+5) {
  background-color: rgba(227, 242, 0, 0.5);
}
.m-barcodeCard-tr-bl {
  /* TODO OHOH */
  background-color: #0044d4;
  content: "#0044d4";
  content: "#2773f3";
  content: "#4094f5";
  content: "#001e9b";
  content: "#57aef6";
}
.m-barcodeCard-tr-bl span {
  background-color: rgba(0, 68, 212, 0.8);
}
.m-barcodeCard-tr-bl span:nth-child(5n+1) {
  background-color: rgba(0, 68, 212, 0.5);
}
.m-barcodeCard-tr-bl span:nth-child(5n+2) {
  background-color: rgba(39, 115, 243, 0.5);
}
.m-barcodeCard-tr-bl span:nth-child(5n+3) {
  background-color: rgba(64, 148, 245, 0.5);
}
.m-barcodeCard-tr-bl span:nth-child(5n+4) {
  background-color: rgba(0, 30, 155, 0.5);
}
.m-barcodeCard-tr-bl span:nth-child(5n+5) {
  background-color: rgba(87, 174, 246, 0.5);
}
.m-barcodeCard-tr-pc {
  /* TODO OHOH */
  background-color: #fbcc2e;
  content: "#fbcc2e";
  content: "#fcd956";
  content: "#fce171";
  content: "#fbc828";
  content: "#fbbd16";
}
.m-barcodeCard-tr-pc span {
  background-color: rgba(251, 204, 46, 0.8);
}
.m-barcodeCard-tr-pc span:nth-child(5n+1) {
  background-color: rgba(251, 204, 46, 0.5);
}
.m-barcodeCard-tr-pc span:nth-child(5n+2) {
  background-color: rgba(252, 217, 86, 0.5);
}
.m-barcodeCard-tr-pc span:nth-child(5n+3) {
  background-color: rgba(252, 225, 113, 0.5);
}
.m-barcodeCard-tr-pc span:nth-child(5n+4) {
  background-color: rgba(251, 200, 40, 0.5);
}
.m-barcodeCard-tr-pc span:nth-child(5n+5) {
  background-color: rgba(251, 189, 22, 0.5);
}
.m-barcodeCard-nl-bl {
  /* TODO OHOH */
  background-color: #0097ff;
  content: "#0097ff";
  content: "#2cb0ff";
  content: "#52c0ff";
  content: "#63c8ff";
  content: "#008eff";
}
.m-barcodeCard-nl-bl span {
  background-color: rgba(0, 151, 255, 0.8);
}
.m-barcodeCard-nl-bl span:nth-child(5n+1) {
  background-color: rgba(0, 151, 255, 0.5);
}
.m-barcodeCard-nl-bl span:nth-child(5n+2) {
  background-color: rgba(44, 176, 255, 0.5);
}
.m-barcodeCard-nl-bl span:nth-child(5n+3) {
  background-color: rgba(82, 192, 255, 0.5);
}
.m-barcodeCard-nl-bl span:nth-child(5n+4) {
  background-color: rgba(99, 200, 255, 0.5);
}
.m-barcodeCard-nl-bl span:nth-child(5n+5) {
  background-color: rgba(0, 142, 255, 0.5);
}
.m-barcodeCard-nl-si {
  /* TODO OHOH */
  background-color: #d1d7e4;
  content: "#d1d7e4";
  content: "#dadfea";
  content: "#eef1f6";
  content: "#c4cbd9";
  content: "#dadfea";
}
.m-barcodeCard-nl-si span {
  background-color: rgba(209, 215, 228, 0.8);
}
.m-barcodeCard-nl-si span:nth-child(5n+1) {
  background-color: rgba(209, 215, 228, 0.5);
}
.m-barcodeCard-nl-si span:nth-child(5n+2) {
  background-color: rgba(218, 223, 234, 0.5);
}
.m-barcodeCard-nl-si span:nth-child(5n+3) {
  background-color: rgba(238, 241, 246, 0.5);
}
.m-barcodeCard-nl-si span:nth-child(5n+4) {
  background-color: rgba(196, 203, 217, 0.5);
}
.m-barcodeCard-nl-si span:nth-child(5n+2) {
  background-color: rgba(218, 223, 234, 0.5);
}
.m-barcodeCard-nl-vp {
  /* TODO OHOH */
  background-color: #152a40;
  content: "#152a40";
  content: "#203850";
  content: "#2d4965";
  content: "#0f2032";
  content: "#203850";
}
.m-barcodeCard-nl-vp span {
  background-color: rgba(21, 42, 64, 0.8);
}
.m-barcodeCard-nl-vp span:nth-child(5n+1) {
  background-color: rgba(21, 42, 64, 0.5);
}
.m-barcodeCard-nl-vp span:nth-child(5n+2) {
  background-color: rgba(32, 56, 80, 0.5);
}
.m-barcodeCard-nl-vp span:nth-child(5n+3) {
  background-color: rgba(45, 73, 101, 0.5);
}
.m-barcodeCard-nl-vp span:nth-child(5n+4) {
  background-color: rgba(15, 32, 50, 0.5);
}
.m-barcodeCard-nl-vp span:nth-child(5n+2) {
  background-color: rgba(32, 56, 80, 0.5);
}
.m-barcodeCard-nl-go {
  /* TODO OHOH */
  background-color: #fce600;
  content: "#fce600";
  content: "#fcd300";
  content: "#fac700";
  content: "#fcec21";
  content: "#fbb800";
}
.m-barcodeCard-nl-go span {
  background-color: rgba(252, 230, 0, 0.8);
}
.m-barcodeCard-nl-go span:nth-child(5n+1) {
  background-color: rgba(252, 230, 0, 0.5);
}
.m-barcodeCard-nl-go span:nth-child(5n+2) {
  background-color: rgba(252, 211, 0, 0.5);
}
.m-barcodeCard-nl-go span:nth-child(5n+3) {
  background-color: rgba(250, 199, 0, 0.5);
}
.m-barcodeCard-nl-go span:nth-child(5n+4) {
  background-color: rgba(252, 236, 33, 0.5);
}
.m-barcodeCard-nl-go span:nth-child(5n+5) {
  background-color: rgba(251, 184, 0, 0.5);
}
.m-barcodeCard-nl-pc {
  /* TODO OHOH */
  background-color: #73bc00;
  content: "#73bc00";
  content: "#93cd23";
  content: "#a8d84e";
  content: "#bae06e";
  content: "#6bae00";
}
.m-barcodeCard-nl-pc span {
  background-color: rgba(115, 188, 0, 0.8);
}
.m-barcodeCard-nl-pc span:nth-child(5n+1) {
  background-color: rgba(115, 188, 0, 0.5);
}
.m-barcodeCard-nl-pc span:nth-child(5n+2) {
  background-color: rgba(147, 205, 35, 0.5);
}
.m-barcodeCard-nl-pc span:nth-child(5n+3) {
  background-color: rgba(168, 216, 78, 0.5);
}
.m-barcodeCard-nl-pc span:nth-child(5n+4) {
  background-color: rgba(186, 224, 110, 0.5);
}
.m-barcodeCard-nl-pc span:nth-child(5n+5) {
  background-color: rgba(107, 174, 0, 0.5);
}
.m-barcodeCard-ro-bl {
  /* TODO OHOH */
  background-color: #6681aa;
  content: "#6681aa";
  content: "#33578e";
  content: "#1a3c7b";
  content: "#00245b";
  content: "#99abc7";
}
.m-barcodeCard-ro-bl span {
  background-color: rgba(102, 129, 170, 0.8);
}
.m-barcodeCard-ro-bl span:nth-child(5n+1) {
  background-color: rgba(102, 129, 170, 0.5);
}
.m-barcodeCard-ro-bl span:nth-child(5n+2) {
  background-color: rgba(51, 87, 142, 0.5);
}
.m-barcodeCard-ro-bl span:nth-child(5n+3) {
  background-color: rgba(26, 60, 123, 0.5);
}
.m-barcodeCard-ro-bl span:nth-child(5n+4) {
  background-color: rgba(0, 36, 91, 0.5);
}
.m-barcodeCard-ro-bl span:nth-child(5n+5) {
  background-color: rgba(153, 171, 199, 0.5);
}
.m-barcodeCard-ro-si {
  /* TODO OHOH */
  background-color: #cdd5e1;
  content: "#cdd5e1";
  content: "#cdd5e1";
  content: "#adb8c7";
  content: "#9facbc";
  content: "#d9e0ea";
}
.m-barcodeCard-ro-si span {
  background-color: rgba(205, 213, 225, 0.8);
}
.m-barcodeCard-ro-si span:nth-child(5n+1) {
  background-color: rgba(205, 213, 225, 0.5);
}
.m-barcodeCard-ro-si span:nth-child(5n+1) {
  background-color: rgba(205, 213, 225, 0.5);
}
.m-barcodeCard-ro-si span:nth-child(5n+3) {
  background-color: rgba(173, 184, 199, 0.5);
}
.m-barcodeCard-ro-si span:nth-child(5n+4) {
  background-color: rgba(159, 172, 188, 0.5);
}
.m-barcodeCard-ro-si span:nth-child(5n+5) {
  background-color: rgba(217, 224, 234, 0.5);
}
.m-barcodeCard-ro-go {
  /* TODO OHOH */
  background-color: #ffe500;
  content: "#ffe500";
  content: "#ffd900";
  content: "#ffc200";
  content: "#ffcd00";
  content: "#f8a900";
}
.m-barcodeCard-ro-go span {
  background-color: rgba(255, 229, 0, 0.8);
}
.m-barcodeCard-ro-go span:nth-child(5n+1) {
  background-color: rgba(255, 229, 0, 0.5);
}
.m-barcodeCard-ro-go span:nth-child(5n+2) {
  background-color: rgba(255, 217, 0, 0.5);
}
.m-barcodeCard-ro-go span:nth-child(5n+3) {
  background-color: rgba(255, 194, 0, 0.5);
}
.m-barcodeCard-ro-go span:nth-child(5n+4) {
  background-color: rgba(255, 205, 0, 0.5);
}
.m-barcodeCard-ro-go span:nth-child(5n+5) {
  background-color: rgba(248, 169, 0, 0.5);
}
.m-barcodeCard-ro-vp {
  /* TODO OHOH */
  background-color: #cdd5e1;
  content: "#cdd5e1";
  content: "#cdd5e1";
  content: "#adb8c7";
  content: "#9facbc";
  content: "#d9e0ea";
}
.m-barcodeCard-ro-vp span {
  background-color: rgba(205, 213, 225, 0.8);
}
.m-barcodeCard-ro-vp span:nth-child(5n+1) {
  background-color: rgba(205, 213, 225, 0.5);
}
.m-barcodeCard-ro-vp span:nth-child(5n+1) {
  background-color: rgba(205, 213, 225, 0.5);
}
.m-barcodeCard-ro-vp span:nth-child(5n+3) {
  background-color: rgba(173, 184, 199, 0.5);
}
.m-barcodeCard-ro-vp span:nth-child(5n+4) {
  background-color: rgba(159, 172, 188, 0.5);
}
.m-barcodeCard-ro-vp span:nth-child(5n+5) {
  background-color: rgba(217, 224, 234, 0.5);
}
.m-barcodeCard-ro-pc {
  /* TODO OHOH */
  background-color: #6681aa;
  content: "#6681aa";
  content: "#33578e";
  content: "#1a3c7b";
  content: "#00245b";
  content: "#99abc7";
}
.m-barcodeCard-ro-pc span {
  background-color: rgba(102, 129, 170, 0.8);
}
.m-barcodeCard-ro-pc span:nth-child(5n+1) {
  background-color: rgba(102, 129, 170, 0.5);
}
.m-barcodeCard-ro-pc span:nth-child(5n+2) {
  background-color: rgba(51, 87, 142, 0.5);
}
.m-barcodeCard-ro-pc span:nth-child(5n+3) {
  background-color: rgba(26, 60, 123, 0.5);
}
.m-barcodeCard-ro-pc span:nth-child(5n+4) {
  background-color: rgba(0, 36, 91, 0.5);
}
.m-barcodeCard-ro-pc span:nth-child(5n+5) {
  background-color: rgba(153, 171, 199, 0.5);
}
.m-barcodeCard-de-bl {
  /* TODO OHOH */
  background-color: #0097ff;
  content: "#0097ff";
  content: "#2cb0ff";
  content: "#52c0ff";
  content: "#63c8ff";
  content: "#008eff";
}
.m-barcodeCard-de-bl span {
  background-color: rgba(0, 151, 255, 0.8);
}
.m-barcodeCard-de-bl span:nth-child(5n+1) {
  background-color: rgba(0, 151, 255, 0.5);
}
.m-barcodeCard-de-bl span:nth-child(5n+2) {
  background-color: rgba(44, 176, 255, 0.5);
}
.m-barcodeCard-de-bl span:nth-child(5n+3) {
  background-color: rgba(82, 192, 255, 0.5);
}
.m-barcodeCard-de-bl span:nth-child(5n+4) {
  background-color: rgba(99, 200, 255, 0.5);
}
.m-barcodeCard-de-bl span:nth-child(5n+5) {
  background-color: rgba(0, 142, 255, 0.5);
}
.m-barcodeCard-de-si {
  /* TODO OHOH */
  background-color: #d1d7e4;
  content: "#d1d7e4";
  content: "#dadfea";
  content: "#eef1f6";
  content: "#c4cbd9";
  content: "#dadfea";
}
.m-barcodeCard-de-si span {
  background-color: rgba(209, 215, 228, 0.8);
}
.m-barcodeCard-de-si span:nth-child(5n+1) {
  background-color: rgba(209, 215, 228, 0.5);
}
.m-barcodeCard-de-si span:nth-child(5n+2) {
  background-color: rgba(218, 223, 234, 0.5);
}
.m-barcodeCard-de-si span:nth-child(5n+3) {
  background-color: rgba(238, 241, 246, 0.5);
}
.m-barcodeCard-de-si span:nth-child(5n+4) {
  background-color: rgba(196, 203, 217, 0.5);
}
.m-barcodeCard-de-si span:nth-child(5n+2) {
  background-color: rgba(218, 223, 234, 0.5);
}
.m-barcodeCard-de-go {
  /* TODO OHOH */
  background-color: #fce600;
  content: "#fce600";
  content: "#fcd300";
  content: "#fac700";
  content: "#fcec21";
  content: "#fbb800";
}
.m-barcodeCard-de-go span {
  background-color: rgba(252, 230, 0, 0.8);
}
.m-barcodeCard-de-go span:nth-child(5n+1) {
  background-color: rgba(252, 230, 0, 0.5);
}
.m-barcodeCard-de-go span:nth-child(5n+2) {
  background-color: rgba(252, 211, 0, 0.5);
}
.m-barcodeCard-de-go span:nth-child(5n+3) {
  background-color: rgba(250, 199, 0, 0.5);
}
.m-barcodeCard-de-go span:nth-child(5n+4) {
  background-color: rgba(252, 236, 33, 0.5);
}
.m-barcodeCard-de-go span:nth-child(5n+5) {
  background-color: rgba(251, 184, 0, 0.5);
}
.m-barcodeCard-de-pc {
  /* TODO OHOH */
  background-color: #0097ff;
  content: "#0097ff";
  content: "#2cb0ff";
  content: "#52c0ff";
  content: "#63c8ff";
  content: "#008eff";
}
.m-barcodeCard-de-pc span {
  background-color: rgba(0, 151, 255, 0.8);
}
.m-barcodeCard-de-pc span:nth-child(5n+1) {
  background-color: rgba(0, 151, 255, 0.5);
}
.m-barcodeCard-de-pc span:nth-child(5n+2) {
  background-color: rgba(44, 176, 255, 0.5);
}
.m-barcodeCard-de-pc span:nth-child(5n+3) {
  background-color: rgba(82, 192, 255, 0.5);
}
.m-barcodeCard-de-pc span:nth-child(5n+4) {
  background-color: rgba(99, 200, 255, 0.5);
}
.m-barcodeCard-de-pc span:nth-child(5n+5) {
  background-color: rgba(0, 142, 255, 0.5);
}
.m-barcodeCard-de-eu {
  /* TODO OHOH */
  background-color: #fbcc2e;
  content: "#fbcc2e";
  content: "#fcd956";
  content: "#fce171";
  content: "#fbc828";
  content: "#fbbd16";
}
.m-barcodeCard-de-eu span {
  background-color: rgba(251, 204, 46, 0.8);
}
.m-barcodeCard-de-eu span:nth-child(5n+1) {
  background-color: rgba(251, 204, 46, 0.5);
}
.m-barcodeCard-de-eu span:nth-child(5n+2) {
  background-color: rgba(252, 217, 86, 0.5);
}
.m-barcodeCard-de-eu span:nth-child(5n+3) {
  background-color: rgba(252, 225, 113, 0.5);
}
.m-barcodeCard-de-eu span:nth-child(5n+4) {
  background-color: rgba(251, 200, 40, 0.5);
}
.m-barcodeCard-de-eu span:nth-child(5n+5) {
  background-color: rgba(251, 189, 22, 0.5);
}

.m-barcodeCardPending {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 2rem;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0;
  max-height: 100vh;
  margin-bottom: 0;
  padding-top: 2.1875rem;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 8px 32px 0 rgba(30, 48, 86, 0.1);
}
.m-barcodeCardPending-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 1.5rem 2rem;
  overflow: hidden;
}
.m-barcodeCardPending-title {
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.125rem;
  color: #667284;
  text-align: center;
  line-height: 1.5rem;
}
.m-barcodeCardPending-description {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  color: #667284;
  letter-spacing: 0;
}
.m-barcodeCardPending-footer {
  padding: 1.375rem 1rem;
  width: 100%;
  height: 4rem;
  color: #667284;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.1875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  box-shadow: inset 0 0.0625rem 0 0 #e6e8eb;
}
.m-barcodeCardPending-zzz {
  margin-bottom: 2.375rem;
}

.m-page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.m-page-greyBackground {
  background-color: #f2f3f5;
}
.m-page-lightBlueBackground {
  background-color: #f2f7ff;
}
.m-page-lightMetroBlueBackground {
  background-color: #f2f5f8;
}
.m-page-coverHeight {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.m-page-sideBar {
  padding-left: 3rem;
}
.m-page-horizontalHeader {
  margin-top: 2rem;
  min-height: calc(100vh - 2rem);
}
.m-page-horizontalHeader .m-sideGrid {
  height: calc(100vh - 2rem);
}

.m-page-container {
  position: relative;
  flex: 1 1 auto;
}
.m-page-container-isCentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.m-page-container-noPadding {
  margin: 0;
  padding: 0;
}

/* Todo: these are app specific styles and should not be part of the library */
.authentication-container {
  display: flex;
  align-items: stretch;
  align-content: space-between;
  margin: 0 1rem 1rem;
}
.authentication-container :first-child {
  margin-right: 0.5rem;
}
.authentication-container :last-child {
  margin-left: 0.5rem;
}

@media (max-width: 321px) {
  .authentication-container {
    margin: 1rem;
  }
  .authentication-container button {
    font-size: 90%;
  }
}
/* TODO: Remove intro-header, intro-title, info-icon after extra/DCx components are used within digital card app */
.intro-header {
  margin-top: 3.75rem;
}

.intro-title {
  font-size: 1.625rem;
  font-weight: 600;
  color: #ffffff;
  margin-top: 1rem;
}

.info-icon {
  position: absolute;
  top: 3rem;
  right: 2rem;
}

/* Todo: refactor css classnames for MCSS style */
.m-panel {
  display: block;
  /* Only for IE11, which actually supports display: flex, but does not support @supports .. */
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(128, 128, 128, 0.2);
  border-radius: 3px;
}
@supports (display: flex) {
  .m-panel {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}
.m-panel + .m-panel {
  margin-top: 3rem;
}
.m-panel-header {
  display: flex;
  align-items: center;
  color: #002d72;
  background-color: #ffffff;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom: 1px solid #e6e8eb;
  height: 3.25rem;
  padding: 0 1rem 0 1rem;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.625rem;
}
@media (min-width: 768px) {
  .m-panel-header {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
.m-panel-headerCenteredContent {
  justify-content: center;
  padding: 0 1.5rem;
}
.m-panel-content {
  position: relative;
  font-size: 1rem;
  flex-grow: 1;
}
.m-panel-content::before, .m-panel-content::after {
  content: ".";
  clear: both;
  display: block;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.m-panel-contentPadding {
  margin: 1.5rem;
}
@media (min-width: 1024px) {
  .m-panel-contentPadding {
    margin: 3rem 6rem;
  }
}
.m-panel-footer {
  min-height: 64px;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e6e8eb;
}
.m-panel-footer .m-button {
  margin: 0 0 0 0.5rem;
}
.m-panel-footer-isStack {
  height: auto;
  flex-direction: column;
}
.m-panel-footer-isStack .m-button-block:not(:last-child) {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .m-panel .m-panel {
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .m-panel .m-panel-content {
    padding: 0;
  }
  .m-panel .m-panel-contentPadding {
    margin: 1rem 1.5rem;
  }
  .m-panel-footer {
    padding: 0 1rem;
    justify-content: space-around;
  }
}
.m-panel--tiny {
  box-shadow: none;
  border: 1px solid #ccd0d6;
  border-radius: 0;
}
.m-panel--tiny .m-panel-header {
  border-bottom: 1px solid #ccd0d6;
  padding: 0 0.5rem;
  height: 2rem;
  background-color: #f2f7ff;
}
.m-panel--tiny .m-panel-header__info {
  font-size: 0.6875rem;
  line-height: 1.2;
  padding: 0 0.25rem;
  color: #667284;
}
.m-panel--tiny .m-bar-item {
  font-size: 0.8125rem;
  line-height: 1.23rem;
}
.m-panel--tiny .m-panel-content {
  padding: 0.5rem;
}

/* .panel-header *:last-child {
  margin-left: auto;
  margin-right: 0.5rem;
} */
.m-icon {
  width: 1.5em;
  height: 1.5em;
}
.m-icon-isRotate {
  animation: 1s icon-rotate ease-in-out infinite;
}
@keyframes icon-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
.m-icon.m-icon-color-inherited * {
  fill: #667284;
  fill: currentColor !important;
  stroke-width: 1.5px !important;
}

.m-input {
  margin-bottom: 1rem;
  /* Hide clear and reveal icons in internet explorer */
}
.m-input-isStandalone {
  margin-bottom: 0;
}
.m-input ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #99a1ad;
  font-style: italic;
}
.m-input ::-moz-placeholder {
  /* Firefox 19+ */
  color: #99a1ad;
  font-style: italic;
}
.m-input :-ms-input-placeholder {
  /* IE 10+ */
  color: #99a1ad;
  font-style: italic;
}
.m-input :-moz-placeholder {
  /* Firefox 18- */
  color: #99a1ad;
  font-style: italic;
}
.m-input-elementWrapper {
  padding-left: 1px;
  padding-right: 1px;
  position: relative;
}
.m-input-elementWrapper > div {
  margin-bottom: 0.5rem;
}
.m-input-element {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  border: none;
  box-shadow: 0 0 0 1px #ccd0d6;
  font-size: 0.75rem;
  font-family: inherit;
  padding: 0.0625rem 0.5rem 0;
  height: 2rem;
  color: #001432;
  border-radius: 0.125rem;
  transition: 0.125s ease-in-out 0s;
  transition-property: border-color;
}
.m-input-element:focus {
  color: #33435b;
  outline: none;
  border: none;
  box-shadow: 0 0 0 2px #66a2fe;
}
.m-input-element:disabled {
  pointer-events: none;
  color: #99a1ad;
  background-color: #f2f3f5;
  border: 1px solid transparent;
  opacity: 1;
  box-shadow: 0 0 0 1px #ccd0d6;
}
.m-input-element::before {
  content: "";
}
.m-input-element[aria-readonly=true] {
  padding-left: 2rem;
  background-color: #f2f3f5;
  border: 1px solid transparent;
  box-shadow: 0 0 0 1px #ccd0d6;
  pointer-events: none;
}
.m-input-element[aria-readonly=true]:focus {
  outline: 0 none;
  box-shadow: 0 0 0 1px #ccd0d6;
}
.m-input-element[aria-readonly=true] .m-input-leftIcon {
  color: #99a1ad;
  width: 1rem;
}
.m-input-element.m-input-hasIconLeft {
  padding-left: 2rem;
}
.m-input-element.m-input-isValid {
  border: 1px solid #85d9b4;
  box-shadow: 0 0 0 1px #85d9b4;
}
.m-input-element.m-input--isHighlighted--green {
  background-color: #ebf9f3;
}
.m-input-element.m-input-hasInfo {
  border: 1px solid #66a2fe;
  box-shadow: none;
}
.m-input-element.m-input-hasWarning {
  border: 1px solid #eb8676;
  box-shadow: 0 0 0 1px #eb8676;
}
.m-input-element.m-input-hasError, .m-input-element.ng-invalid.ng-dirty {
  box-shadow: 0 0 0 2px #eb8676;
}
.m-input-element.m-input-hasIcon {
  padding-right: 2.75rem;
}
.m-input input[type=search] {
  -webkit-appearance: textfield;
}
.m-input input[type=search]::-webkit-search-decoration,
.m-input input[type=search]::-webkit-search-cancel-button,
.m-input input[type=search]::-webkit-search-results-button,
.m-input input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.m-input input::-ms-clear,
.m-input input::-ms-reveal {
  display: none;
}
.m-input textarea {
  overflow: auto;
  resize: vertical;
  width: 100%;
  min-height: 10rem;
  padding-top: 1rem;
}
.m-input-errorMessage, .m-input-infoMessage {
  display: block;
  visibility: visible;
  font-size: 0.6875rem;
  line-height: 0.75rem;
  margin-top: 0.3125rem;
}
.m-input-errorMessage {
  color: #de351b;
}
.m-input-errorMessage-isInfo {
  position: relative;
  padding: 0.4375rem 0.75rem 0.4375rem 0.625rem;
  border-radius: 0.125rem;
  color: #003c98;
  background-color: #cce0ff;
  font-size: 0.8125rem;
}
.m-input-errorMessage-isInfo::after {
  content: "";
  position: absolute;
  top: -0.75rem;
  right: 0.75rem;
  border: 8px solid transparent;
  border-bottom-color: #cce0ff;
}
.m-input-errorMessage-isInfo .m-icon {
  font-size: 1rem;
  width: 1em;
  height: 1em;
  vertical-align: text-top;
  margin-right: 0.375rem;
}
.m-input-infoMessage {
  color: #667284;
}
.m-input .m-input-leftIcon {
  position: absolute;
  top: 0;
  left: 0.5rem;
  height: 2rem;
  width: 1rem;
  color: #667284;
}
.m-input-uploader {
  cursor: pointer;
}
.m-input-iconLink {
  position: absolute;
  right: 0.0625rem;
  top: 0;
  padding: 0.475rem 0.5rem;
  color: #33435b;
  font-size: 0.75rem;
  border: none;
  border-radius: 0 0.125rem 0.125rem 0;
}
.m-input-iconLink .m-icon {
  width: 1rem;
  height: 1rem;
}
.m-input-iconLink:focus {
  outline: none;
}
.m-input-iconLink:focus svg {
  color: #ffffff !important;
}
.m-input-iconLink--unclickable {
  align-items: center;
  background: #e6f0ff;
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
  display: flex;
  font-size: 0.75rem;
  justify-content: center;
  height: 2rem;
  padding: 0;
  pointer-events: none;
  right: 0.0625rem;
  width: 2rem;
}
.m-input-iconLink--loader {
  stroke-width: 0.18rem;
  right: 0.625rem;
  width: 0.0625rem;
  color: #0064fe;
  height: 2rem;
}
html:not(.touchevents) .m-input-iconLink:focus {
  outline: none;
  background-color: #0064fe;
  color: #ffffff;
  border-radius: 0 0.125rem 0.125rem 0;
}
.m-input-valueGroup-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.m-input-valueGroup-group {
  position: relative;
  flex: 0 0 auto;
}
.m-input-valueGroup-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: inherit;
  font-family: inherit;
  padding: 0;
  border: none;
  background: transparent;
}
.m-input-valueGroup-input:focus {
  outline: none;
}
.m-input-valueGroup-mask {
  color: #667284;
  font-style: italic;
  pointer-events: none;
}
.m-input-valueGroup-maskHidden {
  visibility: hidden;
  opacity: 0;
}
.m-input-valueGroup-delimiter {
  display: inline-block;
  padding: 0 0.125rem;
  font-style: italic;
  text-align: center;
  color: #667284;
}

.m-label {
  display: flex;
  justify-content: space-between;
}
.m-label label {
  font-size: 0.6875rem;
  line-height: 1.385;
  font-weight: normal;
  color: #001432;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor: default;
  display: flex;
  flex-direction: row;
  padding-bottom: 0.3125rem;
}
.m-label label span.m-label-required {
  display: inline-block;
  vertical-align: top;
  margin-top: -0.125rem;
}
.m-label-link {
  font-size: 0.6875rem;
  line-height: 1.385;
  font-weight: normal;
  color: #0064fe;
}

.mm-labels__dropdown-menu {
  border: none !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25) !important; /* stylelint-disable-line color-function-notation */
  height: 30rem;
  max-height: 30rem !important;
  margin-top: 1rem;
  width: 18.5rem;
}
.mm-labels__dropdown-menu--top-position, .mm-labels__dropdown-menu--bottom-position {
  left: 1.5rem !important;
}
.mm-labels__dropdown-menu--top-position {
  top: -450px;
}
.mm-labels__dropdown-menu--bottom-position {
  top: unset;
}
.mm-labels__dropdown-menu .m-checkbox {
  padding: 0.5rem 1rem;
}
.mm-labels__list-wrapper {
  border-top: solid 1px #e6e8eb;
  padding: 0.5rem 0;
  position: relative;
  overflow-y: auto;
}
.mm-labels__list-wrapper::-webkit-scrollbar {
  width: 0.2rem;
}
.mm-labels__list-wrapper::-webkit-scrollbar-thumb {
  background-clip: border-box;
}
.mm-labels-assign-view .mm-labels__list-wrapper {
  border-bottom: solid 1px #e6e8eb;
  height: 17.225rem;
}
.mm-labels-manage-view .mm-labels__list-wrapper {
  border-bottom: solid 1px #e6e8eb;
  height: 20.825rem;
}
.mm-labels__list-wrapper--no-results {
  bottom: 0;
  font-size: 0.8rem;
  font-style: italic;
  height: 0.8rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
}
.mm-labels__list-actions {
  display: none;
  position: absolute;
  right: 0.7rem;
  top: 0.2rem;
}
.mm-labels__list-actions--submit {
  top: 0.3rem;
}
.mm-labels__list-actions--manage {
  cursor: pointer;
  border: none;
  background: none;
}
.mm-labels__list-actions--manage[disabled] .m-icon {
  color: #ccd0d6 !important;
  cursor: unset;
}
.mm-labels__list-actions--manage .m-icon {
  transition: all ease-in 0.2ms;
}
.mm-labels__list-item {
  position: relative;
  transition: all 0.1s ease-in-out;
}
.mm-labels__list-item:hover {
  background-color: #e6f0ff;
}
.mm-labels__list-item:hover .mm-labels__list-actions {
  display: block;
}
.mm-labels__list-item:hover .mm-labels__list-actions .mm-labels__list-actions--manage:hover .m-icon {
  color: #002d72 !important;
}
.mm-labels__list-item--success-action {
  background-color: #e6f0ff;
  color: #5dcd9b;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 2rem;
  padding-right: 0.5rem;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}
.mm-labels__list-item--success-action-icon {
  margin-right: 0.1rem;
}
.mm-labels__list-item--success-action-icon svg {
  vertical-align: sub !important;
}
.mm-labels__list-item--deleted-warning {
  background-color: #e6f0ff;
  color: #de351b;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 2rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
  position: absolute;
  right: 0;
  text-align: left;
  top: 0;
  width: 100%;
}
.mm-labels__list-item--deleted-warning-icon {
  margin-right: 0.1rem;
}
.mm-labels__list-item--deleted-warning-icon svg {
  vertical-align: sub !important;
}
.mm-labels__list-item--highlighted {
  background-color: #e6f0ff;
}
.mm-labels__warning {
  color: #de351b;
  display: inline-block;
  font-size: 0.75rem;
  height: 1rem;
  line-height: 1rem;
}
.mm-labels__actions {
  bottom: 0;
  position: absolute;
  width: 100%;
}
.mm-labels__actions--add {
  border-bottom: solid 1px #e6e8eb;
}
.mm-labels__actions--add-button {
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
.mm-labels__actions--close {
  cursor: pointer;
  position: absolute;
  right: 0.5rem;
  top: 0.4rem;
}
.mm-labels__actions--apply-button, .mm-labels__actions--assign-button, .mm-labels__actions--unassign-button {
  cursor: pointer;
  padding: 0.4375rem 0.6rem;
}
.mm-labels__actions--assign-button {
  margin-right: 0.6rem;
}
.mm-labels__actions--unassign-button {
  margin-right: 1.2rem;
}
.mm-labels__actions--manage-button {
  float: right;
}
.mm-labels__actions--check-button {
  padding-right: 0;
}
.mm-labels__header {
  border-bottom: solid 1px #e6e8eb;
}
.mm-labels__header-title {
  color: #002d72;
  margin: 0;
  padding: 0.6rem 1rem;
}
.mm-labels__header-back-btn {
  cursor: pointer;
  position: absolute;
  top: 0.4rem;
}
.mm-labels__header-back-btn + span {
  padding-left: 2rem;
}
.mm-labels__input-container {
  padding: 0.5rem 1rem;
  position: relative;
}
.mm-labels__input-container input {
  border: solid 1px #e6e8eb;
  border-radius: 0.125rem;
  height: 2.5rem;
  padding: 0.6rem;
  width: 100%;
}
.mm-labels__input-container input::-moz-placeholder {
  color: #99a1ad;
  font-style: italic;
}
.mm-labels__input-container input::placeholder {
  color: #99a1ad;
  font-style: italic;
}
.mm-labels__input-action-icon {
  color: #ccd0d6;
  position: absolute;
  right: 0.9rem;
  top: 0.6rem;
}

.mm-labels-manage-view .mm-labels__list-item--label,
.m-dropdown-container .dropdown-menu .mm-labels-manage-view .mm-labels__list-item--label {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #002d72;
}
.mm-labels-manage-view li,
.m-dropdown-container .dropdown-menu .mm-labels-manage-view li {
  padding: 0.3rem 1rem 0.5rem;
}
.mm-labels-manage-view li input:focus-visible,
.mm-labels-manage-view li input:focus,
.mm-labels-manage-view li input,
.m-dropdown-container .dropdown-menu .mm-labels-manage-view li input:focus-visible,
.m-dropdown-container .dropdown-menu .mm-labels-manage-view li input:focus,
.m-dropdown-container .dropdown-menu .mm-labels-manage-view li input {
  border-radius: 0;
  border: 1px solid #0064fe;
  color: #002d72;
  font-size: 0.75rem;
  height: 1.1rem;
  line-height: 1rem;
  margin-left: -0.1rem;
  padding-left: 0.1rem;
  width: 12.5rem;
}

.m-logo {
  height: 2.25rem;
  width: 9.1906rem;
}
.m-logo-isSmall {
  height: 1.6875rem;
  width: 5.9063rem;
}
.m-logo-isSmall.m-logo-isMetroTr {
  height: 2.25rem;
  width: 7.9225rem;
}
.m-logo-isSmall.m-logo-isMetroUnited, .m-logo-isSmall.m-logo-isMetroUnitedInverted, .m-logo-isSmall.m-logo-isMetro {
  height: 2.25rem;
  width: 9.1906rem;
}
.m-logo-isSmall.m-logo-isMakro {
  height: 3.5rem;
  width: 5.9063rem;
}
.m-logo-isSmall.m-logo-isMakroNl {
  height: 3rem;
  width: 6.7175rem;
}
.m-logo-isLarge {
  height: 3.5rem;
  max-height: none;
}
.m-logo-isLarge.m-logo-isMetroTr {
  height: 3.3rem;
  width: 11.75rem;
}
.m-logo-isLarge.m-logo-isMetroUnited, .m-logo-isLarge.m-logo-isMetroUnitedInverted, .m-logo-isLarge.m-logo-isMetro {
  height: 2.25rem;
  width: 12.2538rem;
}
.m-logo-isLarge.m-logo-isMakro {
  height: 3.5rem;
  width: 12.25rem;
}
.m-logo-isLarge.m-logo-isMakroNl {
  height: 3rem;
  width: 11.9431rem;
}

.m-mainNavigation {
  padding: 0 1.5rem;
  background-color: #ffffff;
}
.m-mainNavigation [role=menubar] {
  display: flex;
  align-items: stretch;
}
.m-mainNavigation li {
  display: inline;
  padding-right: 1rem;
}
.m-mainNavigation li a {
  color: #002d72;
  line-height: 4.65rem;
  display: inline-block;
  text-decoration: none;
  margin-top: 0.1875rem;
}
.m-mainNavigation li a:hover {
  text-decoration: none;
  border-bottom: 3px solid #0064fe;
}
.m-mainNavigation li a.m-mainNavigation-isActive {
  text-decoration: none;
  border-bottom: 3px solid #0064fe;
}

/**
 * .page-top
 * The main sky blue bar on top of each additional view container or page
 *
 * Todo: Refactor css classnames
 */
.m-page-top-inner, .m-page-top {
  height: 4rem;
}

.m-page-top {
  position: relative;
  z-index: 30;
  /* Todo: ALSO make it possible to configure the zIndex
    e.g. like this: <NavigationHeader behindHeader />
  */
}
.m-header + .m-page-top {
  z-index: 10;
}
.m-page-top-inner {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0064fe;
  color: #ffffff;
  text-align: left;
  z-index: 2;
}
.m-page-top-isRelative .m-page-top-inner {
  position: relative;
}
.m-page-top-isAbsolute .m-page-top-inner {
  position: absolute;
}
.m-page-top-isLight .m-page-top-inner {
  background-color: #ffffff;
  color: #002d72;
}
.m-page-top-isDark .m-page-top-inner {
  background-color: #002d72;
  color: #ffed00;
}
.m-page-top-isDark .m-page-top-inner .m-page-top-iconButton {
  color: #ffffff;
}
.m-page-top-content {
  padding: 0 1.5rem;
  line-height: 4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.25rem;
}
@media (min-width: 768px) {
  .m-page-top-content {
    font-size: 1.125rem;
  }
}
.m-page-top-iconButton {
  width: 4rem;
  height: 4rem;
  font-size: 1.5rem;
  color: inherit;
}
@media (max-width: 321px) {
  .m-page-top {
    min-height: 3rem;
  }
}

/**
 * Nicely formatted html tags
 *
 * @example
 * <div class="m-niceHtml">
 *   <h1>Hello world</h1>
 *   <p>This is a fantastic paragraph</pa>
 * </div>
 *
 * Or with a light grey background
 *
 * @example
 * <div class="m-niceHtml m-niceHtml-isGrey">
 *   <h1>Hello world</h1>
 *   <p>This is a fantastic paragraph</pa>
 * </div>
 */
.m-niceHtml {
  box-shadow: 0 1px 2px 0 rgba(0, 45, 114, 0.2);
  display: flex;
  flex-direction: column;
  padding: 1rem 2.25rem;
  margin-bottom: 2.2rem;
  border-radius: 3px;
  text-align: center;
  background-color: #ffffff;
}
.m-niceHtml-isGrey {
  box-shadow: none;
  background-color: #f2f3f5;
}
.m-niceHtml-isLightBlue {
  box-shadow: none;
  background-color: #f2f7ff;
}
.m-niceHtml dl {
  display: flex;
  flex-flow: row wrap;
  line-height: 150%;
  color: #667284;
  font-style: italic;
  text-align: left;
}
.m-niceHtml dl dt {
  width: 60%;
}
.m-niceHtml dl dd {
  width: 40%;
  margin-left: 0;
}
.m-niceHtml h1 {
  /* flex, to align possible icons */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 1rem;
  font-size: 7vw;
  font-weight: bold;
  line-height: 1.3;
  color: #0064fe;
  /* Custom breakpoint, because it looks weird when using normal layout breakpoint */
}
@media (min-width: 321px) {
  .m-niceHtml h1 {
    font-size: 5vw;
  }
}
@media (min-width: 550px) {
  .m-niceHtml h1 {
    font-size: 1.875rem;
  }
}
.m-niceHtml h1 .m-icon {
  font-size: 0.8em;
}
.m-niceHtml h2 {
  margin: 0 0 0.5rem;
  font-size: 1.25rem;
  line-height: 1.4;
  color: #002d72;
}
.m-niceHtml small {
  font-size: 0.875rem;
  font-weight: 300;
  color: #666666;
}
.m-niceHtml p {
  font-size: 1rem;
  color: #666666;
  margin: 1.2rem 0;
}

.m-progressNavigation-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #0064fe;
  border-radius: 3px;
  line-height: 1.375;
  padding: 0.375rem 1rem 0.25rem;
  transition: 200ms color;
}
html:not(.touchevents) .m-progressNavigation-link:hover {
  text-decoration: underline;
}
html:not(.touchevents) .m-progressNavigation-link:focus {
  text-decoration: none;
}
.m-progressNavigation-isActive {
  color: #002d72;
  background-color: #ccd5e3;
  pointer-events: none;
}
html:not(.touchevents) .m-progressNavigation-isActive:hover {
  text-decoration: none;
}
.m-progressNavigation-isComplete {
  background: transparent;
  color: #34c082;
}
html:not(.touchevents) .m-progressNavigation-isComplete:hover {
  color: #34c082;
  text-decoration: underline;
}
.m-progressNavigation-isIncomplete {
  background: transparent;
  color: #de351b;
}
html:not(.touchevents) .m-progressNavigation-isIncomplete:hover {
  color: #de351b;
  text-decoration: underline;
}
.m-progressNavigation-isIncomplete .m-progressNavigation-checkIcon {
  color: #de351b;
}
.m-progressNavigation-checkIcon {
  position: relative;
  display: inline-block;
  margin-left: 0.375rem;
  font-size: 0.6667rem;
  opacity: 0;
  transform: scale(0);
  transition: 200ms opacity, 200ms transform;
}
.m-progressNavigation-isComplete .m-progressNavigation-checkIcon, .m-progressNavigation-isIncomplete .m-progressNavigation-checkIcon {
  opacity: 1;
  transform: scale(1);
}

.profileDropdown {
  position: absolute;
  right: 0;
  top: 2.6rem;
  min-width: 12rem;
  z-index: 2;
  box-shadow: 0 8px 32px 0 rgba(0, 45, 114, 0.2);
  border-radius: 3px;
  /* Todo: Not in module style. Do we need those? */
}
.profileDropdown-trigger {
  background: transparent;
  border: 0 none;
  padding: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.profileDropdown-listItem {
  padding: 0.85rem 1rem;
  border-bottom: 1px solid #f2f3f5;
}
.profileDropdown-listItem:first-child {
  border-top: none;
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
}
.profileDropdown-listItem:nth-last-child(-n+1) {
  border-bottom: none;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}
.profileDropdown .arrow_box_nw,
.profileDropdown .arrow_box_ne {
  position: relative;
  background-color: #ffffff;
  border-radius: 0.1875rem;
}
.profileDropdown .arrow_box::after,
.profileDropdown .arrow_box::before,
.profileDropdown .arrow_box_nw::after,
.profileDropdown .arrow_box_nw::before,
.profileDropdown .arrow_box_ne::after,
.profileDropdown .arrow_box_ne::before {
  bottom: 100%;
  border: solid transparent;
  position: absolute;
  pointer-events: none;
  content: " ";
  height: 0;
  width: 0;
}
.profileDropdown .arrow_box::after,
.profileDropdown .arrow_box::before {
  left: 1rem;
  content: " ";
}
.profileDropdown .arrow_box_nw::after,
.profileDropdown .arrow_box_nw::before {
  left: 8rem;
  content: " ";
}
.profileDropdown .arrow_box_ne::after,
.profileDropdown .arrow_box_ne::before {
  right: 0.5rem;
}
.profileDropdown .arrow_box_nw::after,
.profileDropdown .arrow_box_ne::after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #ffffff;
  border-width: 0.5rem;
  margin-left: 0;
}

.m-spinner {
  position: relative;
  margin: 0 auto;
  width: 2em;
  stroke-width: 0.18rem;
  color: currentColor;
}
.m-spinner::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.m-spinner-svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  margin: auto;
  animation: spinner-rotate 2s linear infinite;
  transform-origin: center center;
}
.m-spinner-path {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  color: inherit;
  stroke: currentColor;
  stroke-width: inherit;
  animation: spinner-rotate-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}
.m-spinner .au-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
  font-size: inherit;
}
@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner-rotate-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.m-loader-backdrop {
  background-color: rgba(51, 67, 91, 0.1);
  z-index: 9999;
  justify-content: center;
  align-items: center;
}
.m-loader-container {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}
.m-loader-inner-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/**
 * Hide the overflow in IE.
 *
 * normalize 5.0.0
 */
svg:not(:root) {
  overflow: hidden;
  vertical-align: middle;
}

.m-thumbnail {
  position: relative;
  display: block;
  margin: 0.8rem 0 0.4rem;
  text-align: center;
  color: #002d72;
}
.m-thumbnail-spinner {
  position: relative;
  padding-top: 50%;
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
}
.m-thumbnail-spinner .m-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -1em;
  margin-top: -1em;
}
.m-thumbnail-image {
  display: block;
  width: 100%;
  border: none;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 1px 4px 0 rgba(33, 33, 33, 0.2);
}
.m-thumbnail-label {
  display: block;
  color: #33435b;
  margin-top: 0.4rem;
}
.m-thumbnail-label > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.m-thumbnail-title {
  font-weight: bold;
  line-height: 1.4;
  color: #002d72;
}
.m-thumbnail-subTitle {
  font-size: 0.95rem;
}

.m-ruleChecklist {
  position: relative;
  margin: 0;
  max-height: 0;
  padding: 0 1rem;
  border-radius: 0.25rem;
  background: #f2f3f5;
  color: #33435b;
  font-size: 0.875rem;
  line-height: 1.5rem;
  overflow: hidden;
  transition: 0.3s max-height, 0.3s padding, 0s overflow;
}
.m-ruleChecklist::before {
  content: "";
  display: block;
  position: absolute;
  right: 50%;
  top: -1rem;
  transform: translateX(0.5rem);
  border: 0.5rem solid transparent;
  border-bottom-color: #f2f3f5;
}
@media (min-width: 768px) {
  .m-ruleChecklist::before {
    right: 1.5rem;
    top: -1rem;
    transform: none;
  }
}
.m-ruleChecklist-visible {
  padding: 0.5625rem 1rem;
  max-height: 18.75rem;
  margin-bottom: 1rem;
  overflow: visible;
  transition-delay: 0s, 0s, 0.3s;
}
.m-ruleChecklist-label {
  font-weight: bold;
}
.m-ruleChecklist-content {
  opacity: 0;
  transition: 0.3s opacity;
}
.m-ruleChecklist-visible .m-ruleChecklist-content {
  opacity: 1;
}
.m-ruleChecklist-list {
  margin: 0.3rem 0 0.5rem;
  padding: 0;
  list-style: none;
  line-height: 1.375rem;
}
.m-ruleChecklist-item {
  position: relative;
  padding-left: 1.5rem;
  color: #33435b;
  transition: 0.3s color;
}
.m-ruleChecklist-item::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0.1875rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #ccd0d6;
  transform: scale(0.4);
  transition: 0.3s transform, 0.3s color;
  transition-timing-function: cubic-bezier(0, 0.72, 0.32, 1), linear;
  transition-timing-function: cubic-bezier(0, 0.72, 0.72, 1.42), linear;
}
.m-ruleChecklist-showErrors .m-ruleChecklist-item:not(.m-ruleChecklist-itemValid) {
  color: #de351b;
}
.m-ruleChecklist-showErrors .m-ruleChecklist-item:not(.m-ruleChecklist-itemValid)::before {
  background-color: #de351b;
  transform: scale(1);
}
.m-ruleChecklist-showErrors .m-ruleChecklist-item:not(.m-ruleChecklist-itemValid) .m-ruleChecklist-icon {
  opacity: 1;
  transform: scale(1);
}
.m-ruleChecklist-itemValid::before {
  background-color: #34c082;
  transform: scale(1);
}
.m-ruleChecklist-itemValid .m-ruleChecklist-icon {
  opacity: 1;
  transform: scale(1);
}
.m-ruleChecklist-icon {
  position: absolute;
  left: 0;
  top: 0.1875rem;
  width: 1rem;
  height: 1rem;
  font-size: 0.4rem;
  color: white;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.4);
  transition: 0.3s transform, 0.3s opacity;
}
.m-ruleChecklist-iconCheck {
  background: url("data:image/svg+xml,%3Csvg width='8' height='6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2.646l2.21 2.21L7 1.066' stroke-width='1.5' stroke='%23FFF' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat 50% 50%;
}
.m-ruleChecklist-iconCross {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M22.6 21.3c.4.4.5 1 .1 1.4-.4.4-1 .5-1.4.1l-.1-.1-5.3-5.3-5.3 5.3c-.4.4-1.1.3-1.4-.1-.3-.4-.3-.9 0-1.3l5.3-5.3-5.3-5.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l5.3 5.3 5.3-5.3c.4-.4 1.1-.3 1.4.1.3.4.3.9 0 1.3L17.3 16l5.3 5.3z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%;
}

.m-header-inner, .m-header-position, .m-header {
  height: 3.5rem;
}
@media (min-width: 768px) {
  .m-header-inner, .m-header-position, .m-header {
    height: 4rem;
  }
}
@media (min-width: 1024px) {
  .m-header-inner, .m-header-position, .m-header {
    height: 5rem;
  }
}

.m-header {
  position: relative;
  z-index: 20;
  /* Prevents collapsing margin (which gets added when the header contains an Alert) */
  display: inline-block;
  width: 100%;
}
.m-header-position {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.m-header-isRelative .m-header-position {
  position: relative;
}
.m-header-isAbsolute .m-header-position {
  position: absolute;
}
.m-header-inner {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  background-color: #002d72;
}
.m-header-content {
  position: relative;
  margin-right: 1rem;
  margin-left: auto;
}
@media (min-width: 768px) {
  .m-header-content {
    margin-right: 1.5rem;
  }
}
@media (min-width: 1024px) {
  .m-header-content {
    margin-right: 1.8125rem;
  }
}
.m-header-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  .m-header-buttons {
    margin-right: 1rem;
  }
}
.m-header-buttons > *:not(.m-iconButton) {
  min-width: 5.3125rem;
}
.m-header-buttons .m-iconButton {
  color: #ffffff;
  padding: 0;
  font-size: 1.09375rem;
}

.m-header-logo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media (min-width: 768px) {
  .m-header-logo {
    left: 1.6875rem;
    right: auto;
  }
}
.m-header-logo .m-logo.m-logo-isMetro {
  width: 6.75rem;
  height: 1.25rem;
}
@media (min-width: 768px) {
  .m-header-logo .m-logo.m-logo-isMetro {
    width: 6.75rem;
    height: 1.25rem;
  }
}
@media (min-width: 1024px) {
  .m-header-logo .m-logo.m-logo-isMetro {
    width: 8.125rem;
    height: 1.5rem;
  }
}
.m-header-logo .m-logo.m-logo-isMakro {
  width: 5.25rem;
  height: 1.5rem;
}
@media (min-width: 768px) {
  .m-header-logo .m-logo.m-logo-isMakro {
    width: 6rem;
    height: 1.6875rem;
  }
}
@media (min-width: 1024px) {
  .m-header-logo .m-logo.m-logo-isMakro {
    width: 7rem;
    height: 2rem;
  }
}
.m-header-logo .m-logo.m-logo-isMakroNl {
  width: 5.5rem;
  height: 1.375rem;
}
@media (min-width: 768px) {
  .m-header-logo .m-logo.m-logo-isMakroNl {
    width: 6.25rem;
    height: 1.5625rem;
  }
}
@media (min-width: 1024px) {
  .m-header-logo .m-logo.m-logo-isMakroNl {
    width: 8rem;
    height: 2rem;
  }
}
.m-header-logo .m-logo.m-logo-isMetroTr {
  width: 5.625rem;
  height: 1.625rem;
}
@media (min-width: 768px) {
  .m-header-logo .m-logo.m-logo-isMetroTr {
    width: 6.75rem;
    height: 1.9375rem;
  }
}
@media (min-width: 1024px) {
  .m-header-logo .m-logo.m-logo-isMetroTr {
    width: 8.125rem;
    height: 2.3125rem;
  }
}
.m-header-logo .m-logo.m-logo-isMetroUnitedInverted {
  width: 7.5rem;
  height: 1.25rem;
}
@media (min-width: 1024px) {
  .m-header-logo .m-logo.m-logo-isMetroUnitedInverted {
    width: 11.9375rem;
    height: 2rem;
  }
}

.verticalHeader-nav-extra-link-tooltip {
  left: 0.875rem !important;
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  30% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
.m-verticalHeader__navigation-window.mm-contextual-menu.mm-action-table-menu {
  min-height: 12rem;
  width: 15rem;
}
.m-verticalHeader__navigation-window.mm-contextual-menu.mm-action-table-menu::before {
  content: "";
  position: absolute;
  left: -20px;
  border: solid 10px transparent;
  border-right-color: #ffffff;
  z-index: 1;
}
.m-verticalHeader__navigation-window.mm-contextual-menu.mm-action-table-menu.mm-menu__placement-top::before, .m-verticalHeader__navigation-window.mm-contextual-menu.mm-action-table-menu.mm-menu__placement-topLeft::before {
  bottom: 15px;
}
.m-verticalHeader__navigation-window.mm-contextual-menu.mm-action-table-menu.mm-menu__placement-bottom::before, .m-verticalHeader__navigation-window.mm-contextual-menu.mm-action-table-menu.mm-menu__placement-bottomLeft::before {
  top: 15px;
}
.m-verticalHeader__navigation-window.mm-contextual-menu.mm-action-table-menu.mm-menu__placement-top {
  top: 3rem;
  left: 3rem;
}
.m-verticalHeader__navigation-window.mm-contextual-menu.mm-action-table-menu.mm-menu__placement-topLeft {
  top: 3rem;
  left: 3.5rem;
}
.m-verticalHeader__navigation-window.mm-contextual-menu.mm-action-table-menu.mm-menu__placement-bottom {
  top: -3rem;
  left: 1.75rem;
}
.m-verticalHeader__navigation-window.mm-contextual-menu.mm-action-table-menu.mm-menu__placement-bottomLeft {
  left: 3.5rem;
  top: -3rem;
}

.m-host-verticalHeader + .content--vh {
  margin-left: 3rem;
}
.m-host-verticalHeader--expanded .m-verticalHeader {
  width: 3rem;
}
.m-host-verticalHeader--expanded .m-verticalHeader .hider {
  width: 1.6rem;
}
.m-host-verticalHeader--expanded .m-verticalHeader__toggle-button {
  left: calc(3rem - 0.6rem);
  transform: rotate(0);
}
.m-host-verticalHeader--expanded .m-verticalHeader-nav-link {
  width: 3rem;
}
.m-host-verticalHeader--expanded .m-verticalHeader--hover {
  width: 13rem;
}
.m-host-verticalHeader--expanded .m-verticalHeader--hover .hider {
  width: 13rem;
}
.m-host-verticalHeader--expanded .m-verticalHeader--hover-nav-link {
  width: 13rem;
}
@media (max-width: 1279px) {
  .m-host-verticalHeader--expanded .m-verticalHeader__toggle-button {
    display: none;
  }
}
@media (min-width: 1280px) {
  .m-host-verticalHeader--expanded .m-verticalHeader {
    width: 15.25rem;
  }
  .m-host-verticalHeader--expanded .m-verticalHeader .hider {
    width: 15.25rem;
  }
  .m-host-verticalHeader--expanded .m-verticalHeader-nav-link {
    width: 15.25rem;
  }
  .m-host-verticalHeader--expanded .m-verticalHeader-nav-icon-extra-link {
    display: none;
  }
  .m-host-verticalHeader--expanded .m-verticalHeader-nav-item-name {
    width: 100%;
    transition: none;
    opacity: 1;
    visibility: visible;
  }
  .m-host-verticalHeader--expanded .m-verticalHeader-nav-item-notify-icon {
    margin-right: 0;
    margin-left: auto;
  }
  .m-host-verticalHeader--expanded .m-verticalHeader__toggle-button {
    left: calc(15.25rem - 0.6rem);
  }
  .m-host-verticalHeader--expanded .m-verticalHeader__toggle-button mm-icon svg.m-icon {
    background: white !important;
    border-radius: 50% !important;
  }
}
.m-host-verticalHeader--expanded + .content--vh {
  margin-left: 3rem;
}
@media (min-width: 1280px) {
  .m-host-verticalHeader--expanded + .content--vh {
    max-width: 100%;
    margin-left: 15.25rem;
  }
}

.m-verticalHeader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #002d72;
  width: 3rem;
  transition: width 0.05s linear;
  transform: translateZ(0) scale(1, 1);
  z-index: 40;
}
.m-verticalHeader .hider {
  display: block;
  width: 1.6rem;
  height: 1.5rem;
  overflow: hidden;
}
.m-verticalHeader--hover {
  width: 13rem;
  transition: width 0.05s linear 0.5s;
}
.m-verticalHeader--hover .hider {
  width: 13rem;
  transition: width 0s linear 0.5s;
}
.m-verticalHeader--hover .m-verticalHeader__toggle-button {
  left: calc(13rem - 0.6rem);
  transform: rotate(180deg);
  transition: width 0.05s linear 0.5s;
}
.m-verticalHeader--hover .m-verticalHeader-nav-link {
  width: 13rem;
  transition: width 0.05s linear 0.5s;
  overflow: hidden;
  cursor: pointer;
}
.m-verticalHeader--hover .m-verticalHeader-nav-item-name {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0.5s, opacity 0s linear 0.5s;
}
.m-verticalHeader--hover .m-verticalHeader-nav-icon-extra-link {
  display: none;
}
@media (min-width: 1280px) {
  .m-verticalHeader--hover {
    width: 15.25rem;
  }
  .m-verticalHeader--hover .hider {
    width: 15.25rem;
  }
  .m-verticalHeader--hover .m-verticalHeader-nav-link {
    width: 15.25rem;
  }
  .m-verticalHeader--hover .m-verticalHeader__toggle-button {
    left: calc(15.25rem - 0.6rem);
    transform: rotate(180deg);
  }
}
.m-verticalHeader__toggle-button {
  position: absolute;
  cursor: pointer;
  transition: width 0.05s linear 0.5s;
  left: calc(3rem - 0.6rem);
  top: 1rem;
  transform: rotate(180deg);
  z-index: 2;
}
.m-verticalHeader__toggle-button mm-icon svg.m-icon {
  background: white !important;
  border-radius: 50% !important;
}
@media (max-width: 1279px) {
  .m-verticalHeader .m-verticalHeader__toggle-button {
    display: none;
  }
}
.m-verticalHeader-head {
  width: 3rem;
  height: 3.875rem;
  padding: 1.25rem 0.75rem;
  background-color: #002d72;
}
.m-verticalHeader-logo {
  height: 1.5rem;
  width: 8.875rem;
}
.m-verticalHeader-nav {
  background-color: #002d72;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}
.m-verticalHeader-nav-item {
  align-items: center;
  position: relative;
}
.m-verticalHeader-nav-item-name {
  min-width: 10rem;
  color: #ffffff;
  font-size: 0.8125rem;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: space-between;
  padding-right: 0.75rem;
}
@media (min-width: 1280px) {
  .m-verticalHeader-nav-item-name {
    min-width: 12.25rem;
  }
}
.m-verticalHeader-nav-item-icon-container {
  position: relative;
}
.m-verticalHeader-nav-item-icon {
  width: 3rem;
}
html:not(.touchevents) .m-verticalHeader-nav-item:focus {
  color: #ffffff;
  outline: none;
}
.m-verticalHeader-nav-item-extra-link {
  border-radius: 2px;
  border: solid 1px #ffffff;
  background-color: transparent;
  padding: 0.0625rem 0.4375rem;
  font-size: 0.6875rem;
  margin-left: 8px;
}
.m-verticalHeader-nav-icon-extra-link {
  border-radius: 2px;
  border: solid 1px #ffffff;
  position: absolute;
  background-color: #002d72;
  font-size: 0.5625rem;
  padding: 0 0.1875rem;
  bottom: 0.125rem;
  left: 1.125rem;
}
.m-verticalHeader-nav-link {
  align-items: center;
  display: flex;
  text-decoration: none;
  height: 3rem;
  width: 3rem;
  color: #e6f0ff;
  overflow: hidden;
}
.m-verticalHeader-nav-link-isActive {
  color: #ffffff;
  background-color: #001b44;
}
html:not(.touchevents) .m-verticalHeader-nav-link:focus {
  text-decoration: none;
}
html:not(.touchevents) .m-verticalHeader-nav-link:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #001b44;
}
.m-verticalHeader-button {
  position: relative;
}
.m-verticalHeader-button-hasLargeIcon > button {
  height: 3rem;
}
.m-verticalHeader-button-hasLargeIcon .m-icon {
  font-size: 1.375rem;
}
.m-verticalHeader-buttonSpinner {
  height: 3rem;
  font-size: 1.125rem;
  color: #0064fe;
}
.m-verticalHeader-tooltip {
  box-shadow: 0 8px 32px 0 rgba(0, 45, 114, 0.2);
  text-align: initial;
  position: absolute;
  left: 5.25rem;
  bottom: 0;
  width: 24rem;
  border-radius: 0.125rem;
  background: #ffffff;
}
.m-verticalHeader-tooltipContent {
  max-height: calc(100vh - 3rem);
  overflow: hidden;
  overflow-y: auto;
  background-color: rgba(242, 243, 245, 0.5); /* stylelint-disable-line color-function-notation */
}
.m-verticalHeader-tooltip-enter {
  will-change: opacity, transform;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: opacity, transform;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0s, 0s;
}
.m-verticalHeader-tooltip-enter-active {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}
.m-verticalHeader-tooltip-exit {
  will-change: opacity, transform;
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
  transition: opacity, transform;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0s, 0s;
}
.m-verticalHeader-tooltip-exit-active {
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
}
.m-verticalHeader-tooltip::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 1.1875rem;
  left: -1rem;
  border: 0.5rem solid transparent;
  border-right-color: #ffffff;
}
.m-verticalHeader-drawer {
  box-shadow: 0 8px 32px 0 rgba(0, 45, 114, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 26.75rem;
  padding-left: 3rem;
  background: white;
  z-index: -1;
}
.m-verticalHeader-drawer-enter {
  transform: translateX(-100%);
  transition: 0.3s transform;
  transition-timing-function: cubic-bezier(0, 0, 0, 1);
  will-change: transform;
}
.m-verticalHeader-drawer-enter-active {
  transform: translateX(0%);
}
.m-verticalHeader-drawer-exit {
  transition: 0.2s transform;
  transition-timing-function: cubic-bezier(1, 0, 1, 0);
  will-change: transform;
  transform: translateX(0%);
}
.m-verticalHeader-drawer-exit-active {
  transform: translateX(-100%);
}
.m-verticalHeader-drawer .m-panel {
  height: 100%;
}
.m-verticalHeader-drawer .m-panel-header {
  min-height: 4rem;
  height: auto;
  flex-wrap: wrap;
  flex-shrink: 0;
}
.m-verticalHeader-drawer .m-panel-header .m-input {
  margin: 0.75rem 0.75rem 0.75rem 0;
  flex-grow: 1;
}
.m-verticalHeader-drawer .m-iconButton {
  color: #002d72;
}
.m-verticalHeader-drawer .m-panel-content {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.m-togglePane {
  overflow: hidden;
  display: block;
}
.m-togglePane-content {
  padding: 0.5rem 1.125rem 0.75rem 1.75rem;
  margin: 0 0 0 0.6875rem;
  border-bottom: 1.6875rem solid transparent;
  box-shadow: inset 2px 0 0 0 #66a2fe;
}

.m-multiSelect {
  position: relative;
  margin-bottom: 1.5rem;
}
.m-multiSelect .ng-value {
  background-color: #e6f0ff !important;
}
.m-multiSelect .ng-option.ng-option-selected.ng-option-marked {
  background-color: #e6f0ff !important;
}
.m-multiSelect .ng-option.ng-option-marked {
  background-color: #f2f7ff !important;
}
.m-multiSelect-value {
  position: relative;
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0 0 0 1px #d0d4d8;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 0.5625rem 2rem 0.5625rem 0.75rem;
  text-align: left;
  color: #001432;
  font-size: 1rem;
  font-family: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.m-multiSelect-value:focus {
  color: #33435b;
  outline: none;
  border: 1px solid #66a2fe;
  box-shadow: 0 0 0 1px #66a2fe;
}
.m-multiSelect-hasWarning .m-multiSelect-value {
  border-color: #eb8676;
  box-shadow: 0 0 0 1px #eb8676;
}
.m-multiSelect-isValid .m-multiSelect-value {
  border: 1px solid #85d9b4;
  box-shadow: 0 0 0 1px #85d9b4;
}
.m-multiSelect-hasInfo .m-multiSelect-value {
  border: 1px solid #66a2fe;
  box-shadow: none;
}
.m-multiSelect-placeholder {
  color: #99a1ad;
}
.m-multiSelect-icon {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.m-multiSelect-disabled .m-multiSelect-value {
  pointer-events: none;
  color: #99a1ad;
  background-color: #f2f3f5;
  border: 0 solid #ccd0d6;
  box-shadow: 0 0 0 1px #ccd0d6;
}
.m-multiSelect-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  margin-top: 1px;
  max-height: 16.5rem;
  padding: 0.5rem 0;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(21, 42, 64, 0.2);
  overflow-y: auto;
}
.m-multiSelect-option {
  padding: 0.4375rem 0.625rem;
}
html:not(.touchevents) .m-multiSelect-option:hover {
  background-color: #e6f0ff;
}
.m-multiSelect-enter {
  display: block;
  transform: translateY(-10px);
  opacity: 0;
}
.m-multiSelect-enter-active {
  transition: 150ms transform, 150ms opacity;
  transform: translateY(0);
  opacity: 1;
}
.m-multiSelect-exit {
  transform: translateY(0);
  opacity: 1;
}
.m-multiSelect-exit-active {
  transition: 150ms transform, 150ms opacity;
  transform: translateY(-10px);
  opacity: 0;
}

.custom-multi-select-checkbox .ng-dropdown-header {
  border: none;
  display: flex;
  padding: 0;
}
.custom-multi-select-checkbox .ng-dropdown-header > div {
  align-items: center;
  background: #e6f0ff;
  display: flex;
  width: 100%;
}
.custom-multi-select-checkbox .ng-dropdown-header > div > mm-icon {
  display: flex;
  padding-left: 0.75rem;
}
.custom-multi-select-checkbox .ng-dropdown-header > div > input {
  background: #e6f0ff;
  border: none;
  color: #002d72;
  font-size: 0.75rem;
  padding: 0.6875rem;
  width: 100%;
}
.custom-multi-select-checkbox .ng-dropdown-header > div > input::-moz-placeholder {
  color: #002d72;
  font-size: 0.75rem;
  opacity: 0.4;
}
.custom-multi-select-checkbox .ng-dropdown-header > div > input::placeholder {
  color: #002d72;
  font-size: 0.75rem;
  opacity: 0.4;
}
.custom-multi-select-checkbox .ng-dropdown-header > div > input:focus {
  outline: none;
}
.custom-multi-select-checkbox .ng-dropdown-panel-items {
  max-height: 11.25rem;
}
.custom-multi-select-checkbox .ng-option {
  align-items: center;
  color: #001432;
  display: flex !important;
  font-size: 0.75rem;
  line-height: 0.75;
}
.custom-multi-select-checkbox .ng-option > span::first-letter {
  text-transform: capitalize;
}
.custom-multi-select-checkbox .ng-option > input {
  height: 1.25rem;
  margin: 0 0.5rem 0 0;
  width: 1.25rem;
}
.custom-multi-select-checkbox .ng-value-container {
  padding: 0 0.75rem !important;
}
.custom-multi-select-checkbox .ng-value-container .ng-placeholder {
  color: #001432 !important;
  font-size: 0.6875rem;
  line-height: 0.7;
  padding: 0 !important;
  top: initial !important;
}
.custom-multi-select-checkbox--disabled {
  pointer-events: none;
}

.selected-attributes > h4 {
  border-bottom: 1px solid #002d72;
  color: #002d72;
  font-size: 0.75rem;
  line-height: 1.3;
  margin: 0;
  padding-bottom: 0.5625rem;
}
.selected-attributes li {
  display: flex;
  color: #001432;
  font-size: 0.75rem;
  justify-content: space-between;
  line-height: 1.3;
  padding: 0.5rem 0;
}
.selected-attributes li > span::first-letter {
  text-transform: capitalize;
}
.selected-attributes li > button {
  background: none;
  border: none;
  cursor: pointer;
}
.selected-attributes li .button-disabled {
  pointer-events: none;
  display: none;
}

.m-countrySelectionList {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
  width: 100%;
  background: #ffffff;
  transition: background-color 0.125s ease-in-out 0s;
}
.m-countrySelectionList-isInline {
  margin: 1.5rem 0;
  box-shadow: 0 1px 2px 0 rgba(50, 72, 87, 0.2);
  border-radius: 3px;
}
.m-countrySelectionList a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.5rem;
  padding: 0 1.5rem;
  text-align: left;
}
html:not(.touchevents) .m-countrySelectionList a:hover {
  text-decoration: none;
  background-color: #f2f7ff;
}
html:not(.touchevents) .m-countrySelectionList a:focus {
  text-decoration: none;
}
.m-countrySelectionList-footer {
  margin: auto 1.5rem 1.25rem;
  color: #667284;
  font-style: italic;
}
.m-countrySelectionList-country {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 10rem;
}
.m-countrySelectionList-countryMarket {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #002d72;
  font-weight: bold;
}
.m-countrySelectionList-isInline .m-countrySelectionList-countryMarket {
  font-size: 1.25rem;
}
.m-countrySelectionList-countryURL {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #6681aa;
}
.m-countrySelectionList li + li {
  border-top: 1px solid #f2f3f5;
}
.m-countrySelectionList .m-countryFlag {
  margin-right: 0.75rem;
  height: 2rem;
  width: 2rem;
}
.m-countrySelectionList + .m-page-container {
  margin-top: 1rem;
}

/* Use of `em` is intentionally here, to make enable resizing over local font-size */
.m-badge {
  position: relative;
  display: inline-block;
  padding: 0 0.4em;
  line-height: 1.5;
  min-width: 1.5em;
  height: 1.5em;
  border-radius: 1.5em;
  text-align: center;
  font-weight: bold;
  color: #002d72;
  background-color: #ffed00;
  white-space: nowrap;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-font-smoothing: antialiased;
  font-size: 0.6875rem;
}
a .m-badge {
  cursor: pointer;
}
.m-badge-isLarge {
  font-size: 1rem;
  height: 1.5em;
}

.m-profilePicture {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #002d72;
}
.m-profilePicture-isLarge {
  text-align: center;
  padding: 1.75rem 0;
  flex-direction: column;
  color: #ffffff;
}
.m-profilePicture-isLarge a {
  color: #99c1ff;
  font-size: 1rem;
}
.m-profilePicture-button {
  position: relative;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  background-position: 50% 50%;
  background-color: transparent;
  transition: 150ms background-color;
}
.m-profilePicture-button:focus {
  outline: none;
}
.m-profilePicture-isSmall .m-profilePicture-button {
  width: 2rem;
  height: 2rem;
}
.m-profilePicture-isLarge .m-profilePicture-button {
  width: 7.5rem;
  height: 7.5rem;
  margin: 0.5rem;
}
.m-profilePicture-badge {
  position: absolute;
  top: -0.25rem;
  right: calc(100% - 3rem);
  font-size: 0.75rem;
}
.m-profilePicture-isSmall .m-profilePicture-badge {
  right: calc(100% - 2.375rem);
  font-size: 0.625rem;
  box-shadow: 0 0.1875rem 0 0.1875rem rgba(0, 0, 0, 0.15);
}
.m-profilePicture-badge-enter {
  opacity: 0.01;
  transform: scale(0.01);
  transform-origin: 45% 55%;
  transition: 300ms transform 700ms, 300ms opacity 700ms;
  transition-timing-function: cubic-bezier(0.2, 1.38, 0.68, 1.36);
}
.m-profilePicture-badge-enter-active {
  opacity: 1;
  transform: scale(1);
}
.m-profilePicture-badge-exit {
  opacity: 1;
  transform: scale(1);
  transform-origin: 45% 55%;
  transition: 300ms transform, 300ms opacity;
  transition-timing-function: ease-in;
}
.m-profilePicture-badge-exit-active {
  opacity: 0;
  transform: scale(0);
}
.m-profilePicture-img {
  border-radius: 50%;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  transition: 150ms opacity;
}
.m-profilePicture-isStamped {
  border: 2px dashed #66a2fe;
}
.m-profilePicture-isStamped .m-profilePicture-placeholderBg {
  fill: #f2f7ff;
}
.m-profilePicture-isStamped .m-profilePicture-placeholderHead {
  fill: #cce0ff;
}
.m-profilePicture-abbreviation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #3383fe;
  color: #ffffff;
  line-height: 1.6875;
  font-weight: bolder;
  font-size: 1rem;
}
.m-profilePicture-isSmall .m-profilePicture-abbreviation {
  font-size: 1rem;
}
.m-profilePicture-isLarge .m-profilePicture-abbreviation {
  font-size: 3.25rem;
}
.m-profilePicture-editIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.0625rem;
  color: white;
  opacity: 0;
  transition: 150ms opacity;
  transform: translate(-50%, -50%);
}
.m-profilePicture-content {
  line-height: 1.37;
  margin-left: 1rem;
}
.m-profilePicture-content a {
  display: inline;
}
.m-profilePicture-isLarge .m-profilePicture-content {
  margin-left: 0;
  line-height: 1.07;
  font-size: 1.75rem;
}

html:not(.touchevents) a.m-profilePicture-button:hover {
  background-color: #0064fe;
}
html:not(.touchevents) a.m-profilePicture-button:hover .m-profilePicture-editIcon {
  opacity: 1;
}
html:not(.touchevents) a.m-profilePicture-button:hover .m-profilePicture-img {
  opacity: 0.4;
}

.m-address {
  position: relative;
  margin: 0.25rem 0;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.5;
  color: #99a1ad;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.m-address strong {
  color: #002d72;
  font-weight: bold;
}
.m-address a {
  display: inline-block;
}
.m-address .m-badge {
  position: absolute;
  right: 0;
  top: 0.1875rem;
  z-index: 1;
}

.m-labelList {
  font-size: 1rem;
  list-style: none;
  padding: 0;
  margin: 0;
}
.m-labelList-label {
  position: relative;
  padding: 0.375rem 1.5rem 0.375rem 0.75rem;
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
  box-shadow: inset 0 -1px 0 0 #e6e8eb;
  color: #99a1ad;
  background-color: #ffffff;
  font-size: 0.6875rem;
  line-height: 1.25rem;
  font-weight: bolder;
  text-transform: uppercase;
}
.m-labelList-label .m-button {
  padding: 0;
  height: auto;
}
.m-labelList-item {
  min-height: 2rem;
  padding: 0.1px 0;
  background-color: #ffffff;
  border-bottom: 1px solid #e6e8eb;
  /* SIDE-EFFECTS */
}
.m-labelList-item h1,
.m-labelList-item h2,
.m-labelList-item h3,
.m-labelList-item p {
  margin: 1rem 0;
}
.m-labelList-item .m-media-content {
  padding-top: 0;
  padding-bottom: 0;
}
.m-labelList-item .m-address {
  margin: 0;
}
.m-labelList-item .m-media {
  background: transparent;
}
.m-labelList-link {
  padding: 0;
  min-height: 0;
}
.m-labelList-link > a {
  display: block;
  min-height: 4.25rem;
  padding: 0.1px 0;
}
.m-labelList-link > a:hover, .m-labelList-link > a:focus {
  text-decoration: none;
}
html:not(.touchevents) .m-labelList-link > a:focus {
  text-decoration: none;
}
html:not(.touchevents) .m-labelList-link > a:hover {
  text-decoration: none;
  background-color: #f2f7ff;
}
.m-labelList-isMuted {
  box-shadow: inset 0 1px 0 0 #f2f3f5;
  background-color: transparent;
}
html:not(.touchevents) .m-labelList-isMuted > a:hover {
  text-decoration: none;
  background-color: white;
}
html.touchevents .m-labelList-isMuted *, .m-labelList-isMuted:not(:hover) * {
  color: #99a1ad;
}
html.touchevents .m-labelList-isMuted .m-badge, .m-labelList-isMuted:not(:hover) .m-badge {
  background-color: #e6e8eb;
  color: #99a1ad;
}
html.touchevents .m-labelList-isMuted .m-profilePicture-abbreviation, .m-labelList-isMuted:not(:hover) .m-profilePicture-abbreviation {
  /* !important is being used, because the abbreviation is colored via inline styles  */
  background-color: #ccd0d6 !important;
  color: #ffffff;
}

.m-toastNotification {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 1rem auto 0;
  border-radius: 0.125rem;
  overflow: hidden;
  pointer-events: auto;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.m-toastNotification-enter {
  animation-name: toastNotification-enter;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: ease-out;
}
.m-toastNotification-exit {
  animation-name: toastNotification-exit;
  animation-duration: 0.2s;
  -webkit-animation-timing-function: ease-in;
}
@keyframes toastNotification-enter {
  0% {
    transform: translateY(-100px);
    opacity: 0;
    max-height: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    max-height: 100px;
  }
}
@keyframes toastNotification-exit {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}
.m-toastNotification-content {
  padding: 1rem 1.5rem 0.8rem;
  background: #34c082;
  color: #ffffff;
  font-size: 1rem;
}
@supports (display: flex) {
  .m-toastNotification-hasAction .m-toastNotification-content {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}
.m-toastNotification-icon {
  margin-right: 0.5rem;
}
.m-toastNotification-action {
  position: relative;
  right: 0;
  top: -6px;
  float: right;
  margin-left: 12px;
  padding: 0 15px;
  border: none;
  border-radius: 0.125rem;
  background: rgba(51, 51, 51, 0.07);
  line-height: 35px;
  font-size: 13px;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@supports (display: flex) {
  .m-toastNotification-action {
    top: 0;
  }
}
.m-toastNotification-action:hover {
  box-shadow: inset 0 -35px rgba(51, 51, 51, 0.07);
}

.m-toastNotification-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 90;
  pointer-events: none;
}

.m-select-input {
  background: #ffffff;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: inherit;
  /* IE11+ special treatment */
  /* Firefox special treatment */
}
.m-select-input::-ms-expand {
  display: none;
}
.m-select-input:focus::-ms-value {
  background: #ffffff;
  color: #000000;
}
.m-select-input:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}
.m-select--not-selected, .m-select--not-selected:focus {
  color: #99a1ad;
  font-style: italic;
}
.m-select-options, .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0;
  margin: 2px 0 0;
  max-height: 19.2rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #ffffff;
  list-style: none;
  z-index: 1;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
}
.m-select-option, .dropdown-menu .dropdown-item {
  display: block;
  padding: 8px 8px 9px;
  width: 100%;
  font-size: 0.75rem;
  text-decoration: none;
  color: #001432;
  background: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  text-align: left;
  cursor: pointer;
}
.m-select-isActive {
  font-weight: bold;
}
.m-select-isFocused {
  background-color: #e6f0ff;
}
.m-select-isDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.m-select-value {
  /* SelectSearchable only */
  position: absolute;
  top: 0;
  left: 0.125rem;
  display: flex;
  padding: 0 0.75rem;
  height: 2.5rem;
  align-items: center;
  pointer-events: none;
}
[readonly] ~ .m-select-value {
  left: 1.75rem;
  /* In readonly mode, the value should be selectable */
  pointer-events: initial;
}
.m-select .m-select-input-isHidden {
  /* Hide the "blinking" cursor when select has a value */
  color: transparent;
}
.m-select-chevron {
  position: absolute;
  right: 0.5rem;
  top: 0;
  height: 2rem;
  width: 1rem;
  font-size: 0.8125rem;
  color: #001432;
  pointer-events: none;
}

.m-separator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: italic;
  margin: 1rem 0;
}
.m-separator hr {
  border-top: 1px solid #e6e8eb;
  margin-top: 1.5rem;
  min-width: 0.625rem;
  flex: 1 1 0;
}
.m-separator-text {
  padding: 0 1rem;
  color: #667284;
  text-align: center;
}

.m-summary {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  border: 1px solid #ccd0d6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px 0 rgba(0, 45, 114, 0.2);
  overflow: hidden;
}
.m-summary-isDisabled {
  background-color: #ffffff;
  background-image: repeating-linear-gradient(40deg, rgba(230, 232, 235, 0.5) 0, rgba(230, 232, 235, 0.5) 2px, rgba(230, 232, 235, 0) 2px, rgba(230, 232, 235, 0) 10px);
  pointer-events: none;
  box-shadow: none;
  border-color: #e6e8eb;
}
.m-summary-isDisabled .m-summary-buttons {
  display: none;
}
.m-summary-isDisabled .m-summary-content *:not(.m-summary-errorMessage) {
  color: #99a1ad;
}
.m-summary-content {
  flex-grow: 1;
  padding: 1.5rem 0;
  line-height: 1.375rem;
}
.m-summary-icon, .m-summary-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  padding: 1.5rem 0;
}
.m-summary-icon {
  width: 5rem;
  flex-grow: 0;
  align-items: center;
  color: #99a1ad;
  font-size: 1.3125rem;
}
.m-summary-buttons {
  padding: 0 1rem;
  flex-grow: 0;
  flex-flow: row nowrap;
}
.m-summary-errorMessage {
  font-size: 1rem;
  line-height: 137.5%;
  color: #de351b;
}
.m-summary-hasWarning {
  border-color: #eb8676;
  box-shadow: 0 0 0 1px #eb8676;
}
.m-summary-hasWarning .m-summary-icon {
  color: #de351b;
}
.m-summary-button {
  padding: 0.625rem 0.3125rem 0.3125rem 0.3125rem;
  line-height: 0;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
html:not(.touchevents) .m-summary-button:hover {
  background-color: #0064fe;
  transition: 150ms background-color;
}
html:not(.touchevents) .m-summary-button:hover svg {
  color: #ffffff;
}
html:not(.touchevents) .m-summary-button:disabled:hover svg {
  color: #99a1ad;
}

.m-bar {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 2rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
}
.m-bar-item {
  min-width: 0;
}
.m-bar-isStretchy {
  flex-grow: 1;
  text-overflow: ellipsis;
}
.m-bar-isFullyCentered {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1 1 auto;
}
.m-bar-isFullyCentered + .m-bar-item {
  /* Push a following item to the right, in case there was no item before the fully centered one */
  margin-left: auto;
}
.m-bar-pushRight {
  margin-left: auto;
}
.m-bar-item:not(:last-child, .m-bar-isFullyCentered) .m-page-top-content {
  padding-right: 0;
}
.m-bar-wrapper {
  display: flex;
  width: 100%;
}

.m-stepProgress {
  height: 0.375rem;
  background-color: #f2f3f5;
  overflow: hidden;
}
.m-stepProgress-hasUnknownTotal {
  box-shadow: inset 0 1px 0 0 #e6e8eb;
  background-color: #ffffff;
}
.m-stepProgress-inner {
  position: relative;
  height: 100%;
  width: 0;
  background-color: #34c082;
  will-change: width;
  transition: 150ms width;
}
.m-stepProgress-hasUnknownTotal .m-stepProgress-inner {
  background-color: #ffffff;
}
.m-stepProgress-active {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-color: #cce0ff;
  will-change: width;
  transition: 150ms width;
  animation: 2s stepProgressPulse ease-in-out infinite;
}
.m-stepProgress-hasUnknownTotal .m-stepProgress-active {
  background-color: #ffffff;
  animation: none;
}
@keyframes stepProgressPulse {
  0% {
    background-color: #dbe9ff;
  }
  50% {
    background-color: #adcdff;
  }
  100% {
    background-color: #dbe9ff;
  }
}

.m-paragraph {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #33435b;
}

.m-sidebarNavigation {
  font-size: 1rem;
  margin: 0 0 4rem;
  padding: 0 0.5rem;
}
.m-sidebarNavigation-isFullWidth {
  margin: 0;
}
.m-sidebarNavigation-title {
  padding: 1rem 0.5rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.0625rem;
  text-transform: uppercase;
  color: #667284;
}
.m-sidebarNavigation-isFullWidth .m-sidebarNavigation-title {
  border-bottom: 1px solid #e6e8eb;
  padding: 0.9375rem 0 1rem;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.125rem;
}
.m-sidebarNavigation-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.5rem;
  /* assure pointer cursor for all tagName properties */
  cursor: pointer;
  color: #0064fe;
  transition: background-color 0.125s ease-in-out 0s;
  font-size: 0.8125rem;
  line-height: 1rem;
}
.m-sidebarNavigation-link:focus, .m-sidebarNavigation-link:hover {
  /* Disable underline for mobile *and* desktop */
  text-decoration: none;
}
html:not(.touchevents) .m-sidebarNavigation-link:hover {
  /* Add hover only for desktop */
  text-decoration: none;
  color: #002d72;
  background-color: #e6f0ff;
}
html:not(.touchevents) .m-sidebarNavigation-link:focus {
  text-decoration: none;
}
.m-sidebarNavigation-isFullWidth .m-sidebarNavigation-link {
  padding: 1.25rem 1rem;
  border-bottom: 1px solid #e6e8eb;
  line-height: 1.5rem;
}
.m-sidebarNavigation-link-hasIcon {
  padding-left: 2.5rem;
}
.m-sidebarNavigation-isFullWidth .m-sidebarNavigation-link-hasIcon {
  padding-left: 4rem;
}
.m-sidebarNavigation-link:active {
  background-color: #e6eaf1;
}
.m-sidebarNavigation-isActive, .m-sidebarNavigation-isActive-reloadable {
  font-weight: bold;
  color: #002d72;
}
.m-sidebarNavigation-isActive:not(self-isFullWidth), .m-sidebarNavigation-isActive-reloadable:not(self-isFullWidth) {
  pointer-events: none;
  background-color: #e6f0ff;
}
.m-sidebarNavigation-isFullWidth .m-sidebarNavigation-isActive, .m-sidebarNavigation-isFullWidth .m-sidebarNavigation-isActive-reloadable {
  border-left: 0.25rem solid #e6f0ff;
  pointer-events: auto;
}
.m-sidebarNavigation-isActive-reloadable:not(self-isFullWidth) {
  pointer-events: all;
}
.m-sidebarNavigation-icon {
  position: absolute;
  left: 0.5rem;
  top: 0.3125rem;
  bottom: 0;
}
.m-sidebarNavigation-isFullWidth .m-sidebarNavigation-icon {
  top: 1.25rem;
}
.m-sidebarNavigation-badge {
  position: relative;
  left: 0.5rem;
  top: -0.125rem;
}
.m-sidebarNavigation-isFullWidth .m-sidebarNavigation-badge {
  position: absolute;
  left: auto;
  right: 3.5rem;
  top: 1.5625rem;
}
.m-sidebarNavigation-chevron {
  position: absolute;
  right: 1rem;
  top: 0.875rem;
}
.m-sidebarNavigation-isFullWidth .m-sidebarNavigation-chevron {
  top: 1.125rem;
}

.m-scrollToTopButton {
  position: fixed;
  bottom: 4.5rem;
  left: 50%;
  transform: translateX(-2rem);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  font-size: 1.3125rem;
  text-align: center;
  background: #002d72;
  color: #ffed00;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border: 0 none;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: visibility, opacity;
  transition-duration: 0s, 0.3s;
  transition-delay: 0.3s, 0s;
}
.m-scrollToTopButton:focus {
  outline: none;
}
html:not(.touchevents) .m-scrollToTopButton:hover {
  background-color: #33578e;
}
.m-scrollToTopButton-isVisible {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0s, 0s;
}

.m-iconButton {
  color: #0064fe;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 1rem;
  line-height: inherit;
  padding: 0.5rem;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.m-iconButton:focus {
  outline: none;
}
.m-iconButton:disabled {
  color: #99a1ad;
  cursor: not-allowed;
}

.m-description {
  color: #667284;
  font-style: italic;
}

.mm-datepicker.ng-dirty.ng-invalid input {
  box-shadow: 0 0 0 2px #eb8676;
}

.m-datePicker.bs-datepicker {
  background-color: #ffffff;
}
.m-datePicker.bs-datepicker .bs-datepicker-container {
  position: relative;
  font-size: 0.8125rem;
  padding: 0;
}
.m-datePicker.bs-datepicker .bs-datepicker-container bs-days-calendar-view:not(:first-child) {
  padding-left: 0.625rem;
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-head {
  height: 2.5rem;
  padding: 0.3125rem;
  background-color: #0064fe;
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-head .current {
  color: #ffffff;
  font-weight: bold;
  font-size: 1rem;
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-body {
  min-height: 205px;
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-body table {
  border-collapse: collapse;
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-body table.days td .select-start::before {
  background-color: #cce0ff;
  left: 1.125rem;
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-body table td:not(.week) {
  position: relative;
  width: 2.8125rem;
  height: 2rem;
  color: #000000;
  font-weight: bold;
  border: 1px solid #f2f3f5;
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-body table td:not(.week) span {
  position: initial;
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-body table td:not(.week) span.disabled {
  border-radius: 0;
  width: 100%;
  font-weight: normal;
  color: #99a1ad;
  background-color: #e6e8eb;
  background-image: repeating-linear-gradient(40deg, #e0e1e3 0, #e0e1e3 2px, #e9eaed 2px, #e9eaed 10px);
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-body table td:not(.week) span.selected {
  color: #ffffff;
  background: #0064fe;
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-body table.years td span {
  line-height: 2.875rem;
  margin: 0 0.53125rem;
  padding: 0;
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-body table.months td {
  height: 2.875rem;
}
.m-datePicker.bs-datepicker .bs-datepicker-container .bs-datepicker-body table.months td span {
  margin: 0 0.25rem;
  padding: 0.25rem 0.5rem;
}
.m-datePicker.bs-datepicker .m-table-today::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  border: 0.3125rem solid transparent;
  border-top-color: #34c082;
  border-right-color: #34c082;
}
.m-datePicker.bs-datepicker .bs-datepicker-body table.days span.is-highlighted.in-range::before,
.m-datePicker.bs-datepicker .bs-datepicker-body table.days span.in-range.select-end::before {
  background-color: #cce0ff;
  right: 1.125rem;
}
.m-datePicker.bs-datepicker .bs-datepicker-body table.days td.active:not(.select-start)::before,
.m-datePicker.bs-datepicker .bs-datepicker-body table.days td.in-range:not(.select-start)::before,
.m-datePicker.bs-datepicker .bs-datepicker-body table.days span.active:not(.select-start)::before,
.m-datePicker.bs-datepicker .bs-datepicker-body table.days span.in-range:not(.select-start)::before {
  background-color: #cce0ff;
}

.mm-contextual-menu {
  box-shadow: 0 8px 32px 0 rgba(0, 45, 114, 0.2);
  max-width: 20rem;
  background: #ffffff;
  position: relative;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 80;
}
.mm-contextual-menu.mm-action-table-menu {
  min-width: 9.5rem;
  padding: 1rem 0.5rem;
  box-shadow: 0 0.125rem 0.5rem 0 #e5e5e5;
  border: 0.0625rem solid #e5e5e5;
  border-radius: 0.25rem;
}
.mm-contextual-menu.mm-action-table-menu .mm-contextual-menu__content {
  margin: 0;
}
.mm-contextual-menu.mm-action-table-menu .mm-action-table-menu-item {
  font-size: 0.75rem;
  line-height: 1.25rem;
  box-shadow: none;
  padding: 0 0.5rem;
  color: #000000;
}
.mm-contextual-menu.mm-action-table-menu .mm-action-table-menu-item:hover {
  text-decoration: none;
}
.mm-contextual-menu.mm-action-table-menu .mm-action-table-menu-item a,
.mm-contextual-menu.mm-action-table-menu .mm-action-table-menu-item span {
  color: #000000;
}
.mm-contextual-menu.mm-action-table-menu .mm-action-table-menu-item a:hover,
.mm-contextual-menu.mm-action-table-menu .mm-action-table-menu-item span:hover {
  text-decoration: none;
}
.mm-contextual-menu.mm-action-table-menu .mm-action-table-menu-item-disabled {
  pointer-events: none;
  color: #999999;
}
.mm-contextual-menu.mm-action-table-menu .mm-action-table-menu-item-disabled a,
.mm-contextual-menu.mm-action-table-menu .mm-action-table-menu-item-disabled span {
  color: #999999;
}
.mm-contextual-menu.mm-action-table-menu hr {
  margin: 0.5rem;
  border-top: 0.0625rem solid #cccccc;
}
.mm-contextual-menu.mm-action-table-menu.mm-menu__placement-bottom, .mm-contextual-menu.mm-action-table-menu.mm-menu__placement-bottomLeft, .mm-contextual-menu.mm-action-table-menu.mm-menu__placement-bottomRight {
  top: -0.25rem;
}
.mm-contextual-menu .mm-contextual-menu__content {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
}
@media (min-width: 768px) {
  .mm-contextual-menu .mm-contextual-menu__content {
    margin-bottom: 0;
  }
}
.mm-contextual-menu .m-contextual-menu__description {
  display: block;
  font-size: 0.8125rem;
  font-style: italic;
  line-height: 1.125rem;
  color: #667284;
  padding: 0.625rem 1rem 1.75rem;
}
.mm-contextual-menu .mm-contextual-menu__placement-top,
.mm-contextual-menu .mm-contextual-menu__placement-topLeft,
.mm-contextual-menu .mm-contextual-menu__placement-topRight {
  padding-bottom: 12px;
}
.mm-contextual-menu .mm-contextual-menu__placement-topLeft {
  left: -10px;
}
.mm-contextual-menu .mm-contextual-menu__placement-topRight {
  left: auto;
  right: -10px;
}
.mm-contextual-menu .mm-contextual-menu__placement-bottom,
.mm-contextual-menu .mm-contextual-menu__placement-bottomLeft,
.mm-contextual-menu .mm-contextual-menu__placement-bottomRight {
  padding-top: 12px;
}
.mm-contextual-menu .mm-contextual-menu__placement-bottomLeft {
  left: -10px;
}
.mm-contextual-menu .mm-contextual-menu__placement-bottomRight {
  left: auto;
  right: -10px;
}

.mm-contextual-menu-item,
.mm-action-table-menu-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0rem 1rem;
  border: none;
  border-radius: 0;
  color: #0064fe;
  text-align: left;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.375rem;
  background-color: transparent;
  box-shadow: inset 0 -0.0625rem 0 #e6e8eb;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (min-width: 768px) {
  .mm-contextual-menu-item,
  .mm-action-table-menu-item {
    padding: 0.5rem 1rem 0.5rem;
  }
}
.mm-contextual-menu-item:focus,
.mm-action-table-menu-item:focus {
  outline: none;
}
html:not(.touchevents) .mm-contextual-menu-item:hover,
html:not(.touchevents) .mm-action-table-menu-item:hover {
  text-decoration: underline;
  background-color: #f2f7ff;
  cursor: pointer;
}
.mm-contextual-menu-item.mm-contextual-menu-item--active,
.mm-action-table-menu-item.mm-contextual-menu-item--active {
  font-weight: bold;
  color: #002d72;
}
.mm-contextual-menu-item.mm-contextual-menu-item--warning,
.mm-action-table-menu-item.mm-contextual-menu-item--warning {
  color: #e55d49;
}

.m-button-dot-three {
  padding: 0.25rem 0.5rem;
}
.m-button-dot-three .m-icon {
  margin: 0;
}
.m-button-dot-three-table.m-button {
  padding: 0 0.25rem;
  color: #0064fe;
  background-color: transparent;
}
html:not(.touchevents) .m-button-dot-three-table.m-button:hover {
  background-color: #e5f0fe;
}
html:not(.touchevents) .m-button-dot-three-table.m-button:focus {
  background-color: #e5f0fe;
}
.m-button-dot-three-table.m-button .m-icon {
  margin: 0;
}

.m-countryFlag {
  position: relative;
  display: inline-block;
  width: 1.46em;
  height: 1.46em;
  flex-grow: 0;
  margin-right: 0.5rem;
  border-radius: 50%;
  vertical-align: text-bottom;
  overflow: hidden;
}
.m-countryFlag-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  box-shadow: inset 0 0 0 0.125rem rgba(0, 0, 0, 0.25);
}
.m-countryFlag .m-countryFlag-img {
  width: 100%;
  height: auto;
  vertical-align: text-bottom;
}
@media screen and (max-width: 321px) {
  .m-countryFlag .m-countryFlag-img {
    transform: none;
  }
}

.m-contentPlaceholder {
  position: relative;
  width: 100%;
  background: #ffffff;
  /* Only required when the component is being resized ratio (padding-top: x%) */
}
.m-contentPlaceholder-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 22rem;
  height: 100%;
  margin: 0 auto;
  padding: 1.5rem;
  text-align: center;
}
.m-contentPlaceholder-contentWrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.m-toolbar {
  background-color: #f2f3f5;
  box-shadow: inset 0 -1px 0 0 #ccd0d6;
  padding: 0.75rem 1rem;
  flex: 0 0 auto;
}
@media (min-width: 768px) {
  .m-toolbar {
    background-color: #ffffff;
    box-shadow: inset 0 1px 0 #f2f3f5;
    padding: 1rem 1.5rem;
  }
}
.m-toolbar + .m-page-container {
  margin-top: 0;
}

.m-tree-parent {
  height: 15.625rem;
  overflow: auto;
}

.m-tree-child__label {
  display: flex;
}
.m-tree-child__label > * {
  padding-left: 0.6rem;
}
.m-tree-child__label--icon {
  cursor: pointer;
  display: block;
  padding-top: 0.2rem;
  padding-left: 0 !important;
  width: 1.2rem;
  z-index: 10;
}
.m-tree-child__label--icon + * {
  margin-left: -1.2rem;
}
.m-tree-child__label .m-checkbox {
  padding: 0.5rem 1rem;
}
.m-tree-child__children-container {
  padding-left: 1rem;
}
.m-tree-child .m-dropdown-container .m-checkbox {
  padding: 0.4rem 1rem 0 0;
}

/**
 * Settings provide general overarching settings split up in various specialzied partials
 *
 * @author christian.nies@uxi.de
 */
/* Base colors */
.ngx-datatable.sms-table, .ngx-datatable.chart-legend-table, .ngx-datatable.sms-table.material {
  box-shadow: none;
}
.ngx-datatable.sms-table.table-smallest-padding-cell .datatable-body .datatable-body-row .datatable-body-cell, .ngx-datatable.table-smallest-padding-cell.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell,
.ngx-datatable.sms-table.table-smallest-padding-cell .datatable-header .datatable-header-cell,
.ngx-datatable.table-smallest-padding-cell.chart-legend-table .datatable-header .datatable-header-cell, .ngx-datatable.sms-table.material.table-smallest-padding-cell .datatable-body .datatable-body-row .datatable-body-cell,
.ngx-datatable.sms-table.material.table-smallest-padding-cell .datatable-header .datatable-header-cell {
  padding: 0.5rem;
}
.ngx-datatable.sms-table.table-smallest-padding-cell .datatable-body .datatable-body-row .datatable-body-cell:first-of-type, .ngx-datatable.table-smallest-padding-cell.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell:first-of-type,
.ngx-datatable.sms-table.table-smallest-padding-cell .datatable-header .datatable-header-cell:first-of-type,
.ngx-datatable.table-smallest-padding-cell.chart-legend-table .datatable-header .datatable-header-cell:first-of-type, .ngx-datatable.sms-table.material.table-smallest-padding-cell .datatable-body .datatable-body-row .datatable-body-cell:first-of-type,
.ngx-datatable.sms-table.material.table-smallest-padding-cell .datatable-header .datatable-header-cell:first-of-type {
  padding: 0.5rem;
}
.ngx-datatable.sms-table.table-smallest-padding-cell .datatable-body .datatable-body-row .datatable-body-cell:last-of-type, .ngx-datatable.table-smallest-padding-cell.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell:last-of-type,
.ngx-datatable.sms-table.table-smallest-padding-cell .datatable-header .datatable-header-cell:last-of-type,
.ngx-datatable.table-smallest-padding-cell.chart-legend-table .datatable-header .datatable-header-cell:last-of-type, .ngx-datatable.sms-table.material.table-smallest-padding-cell .datatable-body .datatable-body-row .datatable-body-cell:last-of-type,
.ngx-datatable.sms-table.material.table-smallest-padding-cell .datatable-header .datatable-header-cell:last-of-type {
  padding: 0.5rem;
}
.ngx-datatable.sms-table:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.chart-legend-table:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.sms-table.material:not(.cell-selection) .datatable-body-row:hover {
  background-color: #f2f7ff;
}
.ngx-datatable.sms-table:not(.cell-selection) .datatable-body-row:hover .datatable-row-group, .ngx-datatable.chart-legend-table:not(.cell-selection) .datatable-body-row:hover .datatable-row-group, .ngx-datatable.sms-table.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #f2f7ff;
}
.ngx-datatable.sms-table.no-data-header-visible, .ngx-datatable.no-data-header-visible.chart-legend-table, .ngx-datatable.sms-table.material.no-data-header-visible {
  margin: 0 0 1rem;
}
.ngx-datatable.sms-table.no-data-header-visible .datatable-body .empty-row, .ngx-datatable.no-data-header-visible.chart-legend-table .datatable-body .empty-row,
.ngx-datatable.sms-table.no-data-header-visible .datatable-footer,
.ngx-datatable.no-data-header-visible.chart-legend-table .datatable-footer, .ngx-datatable.sms-table.material.no-data-header-visible .datatable-body .empty-row,
.ngx-datatable.sms-table.material.no-data-header-visible .datatable-footer {
  display: none;
}
.ngx-datatable.sms-table .datatable-body datatable-selection .datatable-scroll, .ngx-datatable.chart-legend-table .datatable-body datatable-selection .datatable-scroll, .ngx-datatable.sms-table.material .datatable-body datatable-selection .datatable-scroll {
  display: block;
}
.ngx-datatable.sms-table .datatable-body .datatable-row-wrapper:first-child .datatable-body-row, .ngx-datatable.chart-legend-table .datatable-body .datatable-row-wrapper:first-child .datatable-body-row, .ngx-datatable.sms-table.material .datatable-body .datatable-row-wrapper:first-child .datatable-body-row {
  box-shadow: none;
}
.ngx-datatable.sms-table .datatable-body .datatable-body-row .datatable-body-cell, .ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell, .ngx-datatable.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell {
  color: #001432;
  padding: 0.5rem 1.2rem;
  font-size: 0.75rem;
}
.ngx-datatable.sms-table .datatable-body .datatable-body-row .datatable-body-cell.strong, .ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell.strong, .ngx-datatable.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell.strong {
  font-weight: 900;
}
.ngx-datatable.sms-table .datatable-body .datatable-body-row .datatable-body-cell:first-of-type, .ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell:first-of-type, .ngx-datatable.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell:first-of-type {
  padding: 0.5rem 1.2rem 0.5rem 0.5rem;
}
.ngx-datatable.sms-table .datatable-body .datatable-body-row .datatable-body-cell:last-of-type, .ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell:last-of-type, .ngx-datatable.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell:last-of-type {
  padding: 0.5rem 0.5rem 0.5rem 1.2rem;
}
.ngx-datatable.sms-table .datatable-body .datatable-body-row .datatable-body-cell.text-overflow .datatable-body-cell-label, .ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell.text-overflow .datatable-body-cell-label, .ngx-datatable.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell.text-overflow .datatable-body-cell-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ngx-datatable.sms-table .datatable-body .datatable-body-row .datatable-body-cell.text-bold .datatable-body-cell-label, .ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell.text-bold .datatable-body-cell-label, .ngx-datatable.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell.text-bold .datatable-body-cell-label {
  font-weight: bold;
}
.ngx-datatable.sms-table .datatable-body .datatable-body-row .datatable-body-cell.cell-action, .ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell.cell-action, .ngx-datatable.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell.cell-action {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.ngx-datatable.sms-table .datatable-body .datatable-body-row:hover, .ngx-datatable.chart-legend-table .datatable-body .datatable-body-row:hover, .ngx-datatable.sms-table.material .datatable-body .datatable-body-row:hover {
  background: #f2f7ff;
}
.ngx-datatable.sms-table .datatable-body .datatable-summary-row .datatable-body-row, .ngx-datatable.chart-legend-table .datatable-body .datatable-summary-row .datatable-body-row, .ngx-datatable.sms-table.material .datatable-body .datatable-summary-row .datatable-body-row {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.ngx-datatable.sms-table .datatable-body .datatable-summary-row .datatable-body-row .datatable-body-cell, .ngx-datatable.chart-legend-table .datatable-body .datatable-summary-row .datatable-body-row .datatable-body-cell, .ngx-datatable.sms-table.material .datatable-body .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: normal;
}
.ngx-datatable.sms-table .datatable-header, .ngx-datatable.chart-legend-table .datatable-header, .ngx-datatable.sms-table.material .datatable-header {
  border-bottom: 2px solid #002d72;
}
.ngx-datatable.sms-table .datatable-header .datatable-header-cell, .ngx-datatable.chart-legend-table .datatable-header .datatable-header-cell, .ngx-datatable.sms-table.material .datatable-header .datatable-header-cell {
  color: #002d72;
  padding: 0.5rem 1.2rem;
  font-size: 0.75rem;
  font-weight: 900;
  -ms-overflow-x: visible;
}
.ngx-datatable.sms-table .datatable-header .datatable-header-cell:first-of-type, .ngx-datatable.chart-legend-table .datatable-header .datatable-header-cell:first-of-type, .ngx-datatable.sms-table.material .datatable-header .datatable-header-cell:first-of-type {
  padding: 0.5rem 1.2rem 0.5rem 0.5rem;
}
.ngx-datatable.sms-table .datatable-header .datatable-header-cell:last-of-type, .ngx-datatable.chart-legend-table .datatable-header .datatable-header-cell:last-of-type, .ngx-datatable.sms-table.material .datatable-header .datatable-header-cell:last-of-type {
  padding: 0.5rem 0.5rem 0.5rem 1.2rem;
}
.ngx-datatable.sms-table .datatable-header .datatable-header-cell__name, .ngx-datatable.chart-legend-table .datatable-header .datatable-header-cell__name, .ngx-datatable.sms-table.material .datatable-header .datatable-header-cell__name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ngx-datatable.sms-table .datatable-footer, .ngx-datatable.chart-legend-table .datatable-footer, .ngx-datatable.sms-table.material .datatable-footer {
  color: #001432;
  border: none;
}
.ngx-datatable.sms-table .datatable-footer .datatable-footer-inner, .ngx-datatable.chart-legend-table .datatable-footer .datatable-footer-inner, .ngx-datatable.sms-table.material .datatable-footer .datatable-footer-inner {
  padding: 0 0.5rem;
}
.ngx-datatable.sms-table .datatable-footer .datatable-footer-inner .page-count, .ngx-datatable.chart-legend-table .datatable-footer .datatable-footer-inner .page-count, .ngx-datatable.sms-table.material .datatable-footer .datatable-footer-inner .page-count {
  padding: 0 1.5rem 0 0;
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager {
  margin: 0;
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li a, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li a, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li a {
  color: #001432;
  margin: 0;
  padding: 0 0.5rem;
  min-width: unset;
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li a:hover, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li a:hover, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li a:hover {
  background-color: transparent;
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li.active a, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li.active a, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li.active a {
  background-color: #e6f0ff;
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li:last-of-type a, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li:last-of-type a, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li:last-of-type a {
  padding: 0 0 0 0.5rem;
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li.pages a:hover, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li.pages a:hover, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li.pages a:hover {
  background-color: #f2f7ff;
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li .datatable-icon-skip, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li .datatable-icon-skip,
.ngx-datatable.sms-table .datatable-footer .datatable-pager li .datatable-icon-prev,
.ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li .datatable-icon-prev,
.ngx-datatable.sms-table .datatable-footer .datatable-pager li .datatable-icon-left,
.ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li .datatable-icon-left,
.ngx-datatable.sms-table .datatable-footer .datatable-pager li .datatable-icon-right,
.ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li .datatable-icon-right, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li .datatable-icon-skip,
.ngx-datatable.sms-table.material .datatable-footer .datatable-pager li .datatable-icon-prev,
.ngx-datatable.sms-table.material .datatable-footer .datatable-pager li .datatable-icon-left,
.ngx-datatable.sms-table.material .datatable-footer .datatable-pager li .datatable-icon-right {
  display: inline-block;
  padding: 0;
  line-height: normal;
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li .datatable-icon-skip, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li .datatable-icon-skip, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li .datatable-icon-skip {
  transform: rotate(180deg);
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li .datatable-icon-prev::before, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li .datatable-icon-prev::before,
.ngx-datatable.sms-table .datatable-footer .datatable-pager li .datatable-icon-skip::before,
.ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li .datatable-icon-skip::before, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li .datatable-icon-prev::before,
.ngx-datatable.sms-table.material .datatable-footer .datatable-pager li .datatable-icon-skip::before {
  display: inline-block;
  content: "";
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2716px%27%20height%3D%2716px%27%20viewBox%3D%270%200%2016%2016%27%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%3E%3Cpath%20fill%3D%27rgb(0%2C100%2C254)%27%20d%3D%27M9.75462993%2C7.9999971%20L13.331284%2C11.9331911%20C13.5381137%2C12.1161559%2013.5574601%2C12.4321467%2013.3744954%2C12.6389764%20C13.1915307%2C12.845806%2012.8755399%2C12.8651524%2012.6687102%2C12.6821877%20L8.66871016%2C8.37449538%20C8.44375942%2C8.17550049%208.44375942%2C7.82449371%208.66871016%2C7.62549882%20C11.1974904%2C4.87568043%2012.5308237%2C3.439783%2012.6687102%2C3.31780651%20C12.8755399%2C3.13484179%2013.1915307%2C3.15418816%2013.3744954%2C3.36101785%20C13.5574601%2C3.56784754%2013.5381137%2C3.88383835%2013.331284%2C4.06680308%20L9.75462993%2C7.9999971%20Z%20M4.75462993%2C7.9999971%20L8.33128404%2C11.9331911%20C8.53811373%2C12.1161559%208.55746011%2C12.4321467%208.37449538%2C12.6389764%20C8.19153066%2C12.845806%207.87553985%2C12.8651524%207.66871016%2C12.6821877%20L3.66871016%2C8.37449538%20C3.44375942%2C8.17550049%203.44375942%2C7.82449371%203.66871016%2C7.62549882%20C6.19749037%2C4.87568043%207.5308237%2C3.439783%207.66871016%2C3.31780651%20C7.87553985%2C3.13484179%208.19153066%2C3.15418816%208.37449538%2C3.36101785%20C8.55746011%2C3.56784754%208.53811373%2C3.88383835%208.33128404%2C4.06680308%20L4.75462993%2C7.9999971%20Z%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  width: 1rem;
  height: 1rem;
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li .datatable-icon-left::before, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li .datatable-icon-left::before,
.ngx-datatable.sms-table .datatable-footer .datatable-pager li .datatable-icon-right::before,
.ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li .datatable-icon-right::before, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li .datatable-icon-left::before,
.ngx-datatable.sms-table.material .datatable-footer .datatable-pager li .datatable-icon-right::before {
  display: inline-block;
  content: "";
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2716px%27%20height%3D%2716px%27%20viewBox%3D%270%200%2016%2016%27%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%3E%3Cpath%20fill%3D%27rgb(0%2C100%2C254)%27%20d%3D%27M8%2C9.24536718%20L4.06680597%2C5.66871306%20C3.88384125%2C5.46188337%203.56785044%2C5.44253699%203.36102075%2C5.62550172%20C3.15419106%2C5.80846644%203.13484469%2C6.12445725%203.31780941%2C6.33128694%20C3.43978589%2C6.4691734%204.87568333%2C7.80250673%207.62550172%2C10.3312869%20C7.82449661%2C10.5562377%208.17550339%2C10.5562377%208.37449828%2C10.3312869%20L12.6821906%2C6.33128694%20C12.8651553%2C6.12445725%2012.8458089%2C5.80846644%2012.6389792%2C5.62550172%20C12.4321496%2C5.44253699%2012.1161588%2C5.46188337%2011.933194%2C5.66871306%20L8%2C9.24536718%20Z%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E%0A%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  width: 1rem;
  height: 1rem;
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li .datatable-icon-right, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li .datatable-icon-right, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li .datatable-icon-right {
  transform: rotate(-90deg);
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li .datatable-icon-left, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li .datatable-icon-left, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li .datatable-icon-left {
  transform: rotate(90deg);
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li.disabled .datatable-icon-skip::before, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li.disabled .datatable-icon-skip::before,
.ngx-datatable.sms-table .datatable-footer .datatable-pager li.disabled .datatable-icon-prev::before,
.ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li.disabled .datatable-icon-prev::before, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li.disabled .datatable-icon-skip::before,
.ngx-datatable.sms-table.material .datatable-footer .datatable-pager li.disabled .datatable-icon-prev::before {
  display: inline-block;
  content: "";
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2716px%27%20height%3D%2716px%27%20viewBox%3D%270%200%2016%2016%27%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%3E%3Cpath%20fill%3D%27rgb(153%2C161%2C173)%27%20d%3D%27M9.75462993%2C7.9999971%20L13.331284%2C11.9331911%20C13.5381137%2C12.1161559%2013.5574601%2C12.4321467%2013.3744954%2C12.6389764%20C13.1915307%2C12.845806%2012.8755399%2C12.8651524%2012.6687102%2C12.6821877%20L8.66871016%2C8.37449538%20C8.44375942%2C8.17550049%208.44375942%2C7.82449371%208.66871016%2C7.62549882%20C11.1974904%2C4.87568043%2012.5308237%2C3.439783%2012.6687102%2C3.31780651%20C12.8755399%2C3.13484179%2013.1915307%2C3.15418816%2013.3744954%2C3.36101785%20C13.5574601%2C3.56784754%2013.5381137%2C3.88383835%2013.331284%2C4.06680308%20L9.75462993%2C7.9999971%20Z%20M4.75462993%2C7.9999971%20L8.33128404%2C11.9331911%20C8.53811373%2C12.1161559%208.55746011%2C12.4321467%208.37449538%2C12.6389764%20C8.19153066%2C12.845806%207.87553985%2C12.8651524%207.66871016%2C12.6821877%20L3.66871016%2C8.37449538%20C3.44375942%2C8.17550049%203.44375942%2C7.82449371%203.66871016%2C7.62549882%20C6.19749037%2C4.87568043%207.5308237%2C3.439783%207.66871016%2C3.31780651%20C7.87553985%2C3.13484179%208.19153066%2C3.15418816%208.37449538%2C3.36101785%20C8.55746011%2C3.56784754%208.53811373%2C3.88383835%208.33128404%2C4.06680308%20L4.75462993%2C7.9999971%20Z%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E%0A%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  width: 1rem;
  height: 1rem;
}
.ngx-datatable.sms-table .datatable-footer .datatable-pager li.disabled .datatable-icon-left::before, .ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li.disabled .datatable-icon-left::before,
.ngx-datatable.sms-table .datatable-footer .datatable-pager li.disabled .datatable-icon-right::before,
.ngx-datatable.chart-legend-table .datatable-footer .datatable-pager li.disabled .datatable-icon-right::before, .ngx-datatable.sms-table.material .datatable-footer .datatable-pager li.disabled .datatable-icon-left::before,
.ngx-datatable.sms-table.material .datatable-footer .datatable-pager li.disabled .datatable-icon-right::before {
  display: inline-block;
  content: "";
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2716px%27%20height%3D%2716px%27%20viewBox%3D%270%200%2016%2016%27%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%3E%3Cpath%20fill%3D%27rgb(153%2C161%2C173)%27%20d%3D%27M8%2C9.24536718%20L4.06680597%2C5.66871306%20C3.88384125%2C5.46188337%203.56785044%2C5.44253699%203.36102075%2C5.62550172%20C3.15419106%2C5.80846644%203.13484469%2C6.12445725%203.31780941%2C6.33128694%20C3.43978589%2C6.4691734%204.87568333%2C7.80250673%207.62550172%2C10.3312869%20C7.82449661%2C10.5562377%208.17550339%2C10.5562377%208.37449828%2C10.3312869%20L12.6821906%2C6.33128694%20C12.8651553%2C6.12445725%2012.8458089%2C5.80846644%2012.6389792%2C5.62550172%20C12.4321496%2C5.44253699%2012.1161588%2C5.46188337%2011.933194%2C5.66871306%20L8%2C9.24536718%20Z%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E%0A%0A%0A");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  width: 1rem;
  height: 1rem;
}
.ngx-datatable.sms-table .datatable-checkbox, .ngx-datatable.chart-legend-table .datatable-checkbox, .ngx-datatable.sms-table.material .datatable-checkbox {
  vertical-align: initial;
}
.ngx-datatable.sms-table .datatable-checkbox input[type=checkbox], .ngx-datatable.chart-legend-table .datatable-checkbox input[type=checkbox], .ngx-datatable.sms-table.material .datatable-checkbox input[type=checkbox] {
  display: block;
}
.ngx-datatable.sms-table .datatable-checkbox input[type=checkbox]:checked::before, .ngx-datatable.chart-legend-table .datatable-checkbox input[type=checkbox]:checked::before, .ngx-datatable.sms-table.material .datatable-checkbox input[type=checkbox]:checked::before {
  border-color: #002d72;
}
.ngx-datatable.table-small-padding-cell.sms-table .datatable-body .datatable-body-row .datatable-body-cell, .ngx-datatable.table-small-padding-cell.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell, .ngx-datatable.table-small-padding-cell.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell, .ngx-datatable.table-small-padding-cell.sms-table .datatable-header .datatable-header-cell, .ngx-datatable.table-small-padding-cell.chart-legend-table .datatable-header .datatable-header-cell, .ngx-datatable.table-small-padding-cell.sms-table.material .datatable-header .datatable-header-cell {
  padding: 0.5rem 0.75rem;
}
.ngx-datatable.table-small-padding-cell.sms-table .datatable-body .datatable-body-row .datatable-body-cell:first-of-type, .ngx-datatable.table-small-padding-cell.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell:first-of-type, .ngx-datatable.table-small-padding-cell.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell:first-of-type, .ngx-datatable.table-small-padding-cell.sms-table .datatable-header .datatable-header-cell:first-of-type, .ngx-datatable.table-small-padding-cell.chart-legend-table .datatable-header .datatable-header-cell:first-of-type, .ngx-datatable.table-small-padding-cell.sms-table.material .datatable-header .datatable-header-cell:first-of-type {
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
}
.ngx-datatable.table-small-padding-cell.sms-table .datatable-body .datatable-body-row .datatable-body-cell:last-of-type, .ngx-datatable.table-small-padding-cell.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell:last-of-type, .ngx-datatable.table-small-padding-cell.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell:last-of-type, .ngx-datatable.table-small-padding-cell.sms-table .datatable-header .datatable-header-cell:last-of-type, .ngx-datatable.table-small-padding-cell.chart-legend-table .datatable-header .datatable-header-cell:last-of-type, .ngx-datatable.table-small-padding-cell.sms-table.material .datatable-header .datatable-header-cell:last-of-type {
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
}
.ngx-datatable.expandable datatable-body-row.datatable-body-row[ng-reflect-expanded=true] {
  border: none;
}
.ngx-datatable.expandable datatable-body-row.datatable-body-row ~ .datatable-row-detail {
  background-color: white;
  border-bottom: 1px solid #ccd0d6;
}
.ngx-datatable.expandable-row-trigger datatable-body-row.datatable-body-row {
  cursor: pointer;
}
.ngx-datatable.material.sms-table.sms-table-basic, .ngx-datatable.material.sms-table-basic.chart-legend-table {
  border-top: 2px solid #002d72;
}
.ngx-datatable .datatable-header-cell .resize-handle {
  border-right: solid 1px #ccd0d6;
}
.ngx-datatable .datatable-body-row {
  border-bottom: 1px solid #ccd0d6;
}
.ngx-datatable-zebra .datatable-row-even {
  background-color: #f2f3f5;
}
.ngx-datatable .sortable-icon-wrapper {
  cursor: pointer;
  margin: 0 0 0 0.25rem;
}
.ngx-datatable .datatable-header-cell-template-wrap {
  display: flex;
}
.ngx-datatable.chart-legend-table .datatable-body .datatable-body-row {
  padding: 0 !important;
  border-bottom: 0 !important;
  height: 1rem;
}
.ngx-datatable.chart-legend-table .datatable-body .datatable-body-row.active {
  background-color: #e6eaf1 !important;
}
.ngx-datatable.chart-legend-table .datatable-body .datatable-body-row.active .datatable-row-group {
  background-color: #e6eaf1 !important;
}
.ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell {
  height: 1rem !important;
  padding: 0 !important;
}
.ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell:last-child {
  padding-right: 2rem !important;
}
.ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell .datatable-body-cell-label {
  height: 1rem;
  display: flex;
  align-items: center;
}
.ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell.flex-end .datatable-body-cell-label {
  display: flex;
  justify-content: flex-end;
}
.ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell.datatable-cell-bullet {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem !important;
  padding: 0 0.4rem !important;
}
.ngx-datatable.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell.datatable-cell-bullet .datatable-body-cell-label {
  overflow: inherit;
  margin-bottom: 0.125rem;
}
.ngx-datatable.pagination-footer-provided .datatable-footer-inner {
  height: 100% !important;
  padding: 0 !important;
  display: flex;
  flex-flow: column;
}
.ngx-datatable.pagination-footer-provided .datatable-footer-inner .footer-wrapper {
  flex: 1;
  padding: 0.25rem 0.5rem 0;
}
.ngx-datatable.pagination-footer-provided .datatable-footer-inner .footer-provided {
  flex: 1;
  width: 100%;
}
.ngx-datatable .btn-tree {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  padding: 0;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
}

.ngx-datatable.sms-table.summary-footer, .ngx-datatable.summary-footer.chart-legend-table {
  background: #f2f3f5;
  width: 100%;
  border-top: 1px solid #ccd0d6;
}
.ngx-datatable.sms-table.summary-footer .datatable-row-wrapper:last-child .datatable-body-row, .ngx-datatable.summary-footer.chart-legend-table .datatable-row-wrapper:last-child .datatable-body-row {
  border-bottom: 0;
}
.ngx-datatable.sms-table.summary-footer .datatable-body .datatable-body-row .datatable-body-cell, .ngx-datatable.summary-footer.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}
.ngx-datatable.sms-table.summary-footer .datatable-body .datatable-body-row .datatable-body-cell.invalid, .ngx-datatable.summary-footer.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell.invalid {
  color: #de351b;
}

.ngx-datatable.fixed-footer {
  transform: none;
}
.ngx-datatable.fixed-footer .datatable-row-wrapper:last-child .datatable-body-row {
  border-bottom: 0;
}
.ngx-datatable.fixed-footer:not(.preserve-footer-height) .datatable-footer-inner {
  height: 0 !important;
}

.ngx-datatable.material.sms-table .datatable-body, .ngx-datatable.material.chart-legend-table .datatable-body {
  min-height: 0.3125rem;
}
.ngx-datatable.material.sms-table .datatable-body .progress-linear, .ngx-datatable.material.chart-legend-table .datatable-body .progress-linear {
  top: unset;
  z-index: 5;
  height: 0.3125rem;
}
.ngx-datatable.material.sms-table .datatable-body .progress-linear .container, .ngx-datatable.material.chart-legend-table .datatable-body .progress-linear .container {
  background-color: #e6f0ff;
}
.ngx-datatable.material.sms-table .datatable-body .progress-linear .container .bar, .ngx-datatable.material.chart-legend-table .datatable-body .progress-linear .container .bar {
  background-color: #002d72;
  animation: reverse-query 1.9s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
}

@keyframes reverse-query {
  0% {
    opacity: 0.6;
    transform: translateX(-35%) scale(0.3, 1);
  }
  15% {
    opacity: 1;
  }
  30% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: translateX(50%) scale(0, 1);
  }
}
.footer-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.footer-content {
  align-items: center;
  display: flex;
}
.footer-divider {
  border-left: 1px solid #002d72;
  display: block;
  height: 0.75rem;
  margin: 0 0.25rem;
  width: 0.0625rem;
}
.footer-info {
  align-items: center;
  display: flex;
}
.footer-info__text {
  margin-right: 0.5rem;
}
.footer-info__button {
  background: transparent;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  margin: 0;
  margin-right: 0.5rem;
  padding: 0;
  position: relative;
  width: initial;
  cursor: pointer;
}
.footer-info__button--active {
  font-weight: bold;
}
.footer-info__button--active::after {
  content: "";
  position: absolute;
  background-color: #0064fe;
  left: 0;
  height: 0.125rem;
  top: 102%;
  width: 100%;
}
html:not(.touchevents) .footer-info__button:hover::after {
  content: "";
  position: absolute;
  background-color: #0064fe;
  left: 0;
  height: 0.125rem;
  top: 102%;
  width: 100%;
}
html:not(.touchevents) .footer-info__button:focus {
  outline: none;
}
.footer-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  z-index: 15;
  background: #ffffff;
  border-top: 1px solid #ccd0d6;
}

.m-segmentedControlComplex {
  margin: 1rem 0;
}
.m-segmentedControlComplex-options {
  position: relative;
}
.m-segmentedControlComplex-option {
  position: relative;
  display: block;
  width: 100%;
  padding: 1rem 1.5rem 1.125rem;
  border: 0 none;
  box-shadow: inset 0 0 0 0.0625rem #99a1ad;
  margin-bottom: -1px;
  font-family: inherit;
  font-size: inherit;
  text-align: left;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: 200ms box-shadow, 200ms background-color;
}
.m-segmentedControlComplex-option-isActive {
  z-index: 1;
  box-shadow: inset 0 0 0 0.125rem #66a2fe;
  background: rgba(242, 247, 255, 0.5); /* stylelint-disable-line color-function-notation */
}
.m-segmentedControlComplex-option:focus {
  outline: none;
  background: rgba(242, 247, 255, 0.5); /* stylelint-disable-line color-function-notation */
}

.m-checklist-title {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}
.m-checklist-ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.m-checklist-item {
  position: relative;
  padding-left: 1.5rem;
  margin: 0.125rem 0;
}
.m-checklist-item-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.6875rem;
  color: #001432;
}
.m-checklist-isHighlighted .m-checklist-item-icon {
  color: #0064fe;
}

.m-profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 1.5rem;
  border: 1px solid #ccd0d6;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 45, 114, 0.2);
}
.m-profile-title {
  margin-bottom: 0.0625rem;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.5rem;
  color: #001432;
}
.m-profile-content {
  flex: 1 1 auto;
  padding: 1rem;
  font-size: 1rem;
}
.m-profile-buttons {
  display: flex;
  flex-direction: row;
}

.m-iconLink {
  /* prevent word wrap for short labels */
  display: inline-block;
  line-height: 1.375;
}
.m-iconLink-isBlock {
  display: block;
  padding: 0.5rem 0;
  text-align: center;
}
.m-iconLink .m-icon {
  margin: 0 0.25rem;
  width: 1.1875em;
  height: 1.1875em;
  vertical-align: sub;
}

.m-chip {
  display: inline-flex;
  align-items: center;
  margin-right: 0.75rem;
  padding: 0 0 0 1em;
  min-width: 1.6em;
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: 0.5rem;
  white-space: nowrap;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-font-smoothing: antialiased;
  color: #002d72;
  background-color: #cce0ff;
  transition: background-color 125ms ease, color 125ms ease;
}
html:not(.touchevents) .m-chip:hover {
  color: #ffffff;
  background-color: #0064fe;
}
html:not(.touchevents) .m-chip:focus {
  color: #ffffff;
  background-color: #0064fe;
}
.m-chip a {
  color: inherit;
  padding: 0 0.75rem;
  font-size: 0.4rem;
}
.m-chip-isMedium a {
  padding: 0.125rem 0.75rem 0.125rem 1.5rem;
}
.m-chip .m-icon {
  width: 0.75rem;
}

.m-mediaList .m-media {
  box-shadow: inset 0 -1px 0 0 #e6e8eb;
}
.m-mediaList .m-media:last-child:not(:first-child) {
  box-shadow: none;
}
.m-mediaList .m-media-content {
  padding-top: 0;
  padding-bottom: 0;
}
.m-mediaList .m-media .m-address {
  margin-top: 0;
  margin-bottom: 0;
}

.m-media {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  min-height: 5rem;
  width: 100%;
}
.m-media-isPadded {
  padding: 1rem;
}
@media (min-width: 768px) {
  .m-media-isPadded {
    padding: 1rem;
  }
}
button.m-media {
  width: 100%;
  border: 0 none;
  text-align: inherit;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
button.m-media:active {
  background-color: rgba(102, 162, 254, 0.15); /* stylelint-disable-line color-function-notation */
}
html:not(.touchevents) button.m-media:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px #66a2fe;
}

.m-media-content {
  flex: 1 1 auto;
  min-width: 0;
  padding: 0.25rem 0;
  text-align: left;
  font-size: 1rem;
  line-height: 1.375rem;
}
.m-media-visual + .m-media-content {
  padding-left: 1rem;
}
.m-media-aside {
  display: flex;
  flex-direction: row;
  color: #002d72;
}
.m-media-content + .m-media-aside {
  padding-left: 1rem;
}
@media (min-width: 768px) {
  .m-media-aside {
    font-size: 1.3125rem;
  }
}

.m-scrollableContainer {
  overflow-y: scroll;
  max-height: 150px;
  padding: 0 1rem;
  border: 1px solid #ccd0d6;
}
@media (min-width: 768px) {
  .m-scrollableContainer {
    max-height: 350px;
  }
}

.m-tag {
  display: inline-block;
  font-size: 0.6875rem;
  line-height: 1rem;
  margin-right: 0.75rem;
  padding: 0 0.75rem;
  min-width: 1.6em;
  border-radius: 0.5rem;
  color: #33435b;
  background-color: #e6e8eb;
  white-space: nowrap;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  text-transform: uppercase;
}

.mm-tag-list__container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.3125rem;
  -moz-column-gap: 0.3125rem;
  column-gap: 0.3125rem;
}
.mm-tag-list__tag {
  display: flex;
}
.mm-tag-list__tag span {
  font-size: 0.75rem;
  max-width: 8rem;
  padding: 0.3125rem 0.3125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mm-tag-list__button {
  display: flex;
  align-items: center;
  margin-top: 0.3125rem;
  font-size: 0.8125rem;
  font-weight: bold;
  color: #0064fe;
  cursor: pointer;
  -moz-column-gap: 0.625rem;
  column-gap: 0.625rem;
}

.m-fileDropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem 0;
  transition: ease-out 0.2s;
  padding: 2rem 1.5rem;
  border: 0.125rem dashed #cce0ff;
  background: rgba(242, 247, 255, 0.5); /* stylelint-disable-line color-function-notation */
  color: #002d72;
}
.m-fileDropzone__label {
  font-size: 0.875rem;
  text-align: center;
}
.m-fileDropzone__icon {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.m-fileDropzone__icon mm-icon svg {
  transition: ease-out 0.2s;
  color: #99a1ad;
}
.m-fileDropzone:hover:not(.m-fileDropzone-inactive), .m-fileDropzone-dragover:not(.m-fileDropzone-inactive) {
  cursor: pointer;
  transition: ease-in 0.2s;
  border-color: #66a2fe !important;
}
.m-fileDropzone:hover:not(.m-fileDropzone-inactive) .m-fileDropzone__icon mm-icon svg, .m-fileDropzone-dragover:not(.m-fileDropzone-inactive) .m-fileDropzone__icon mm-icon svg {
  transition: ease-in 0.2s;
  color: #0064fe !important;
}
.m-fileDropzone-inactive .m-fileDropzone__label,
.m-fileDropzone-inactive .m-fileDropzone__icon {
  opacity: 0.5;
}
.m-fileDropzone-button {
  position: relative;
  color: #0064fe;
}
html:not(.touchevents) .m-fileDropzone-button:hover {
  text-decoration: underline;
}
.m-fileDropzone-button:focus-within {
  text-decoration: underline;
}
.m-fileDropzone-button input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.m-fileDropzone span {
  line-height: 1.25rem;
  font-size: 0.875rem;
}

.m-checkboxGroup {
  margin: 0 0 1.5rem;
}
.m-checkboxGroup-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.m-checkboxGroup .m-checkbox {
  margin: 0 0 1.5rem;
  flex: 1 1 50%;
}
@media (min-width: 321px) {
  .m-checkboxGroup .m-checkbox {
    flex-basis: 33.3333%;
  }
}
@media (min-width: 768px) {
  .m-checkboxGroup .m-checkbox {
    flex-basis: 25%;
  }
}

.m-filePreview {
  box-shadow: 0 1px 2px 0 rgba(0, 45, 114, 0.2);
  position: relative;
  margin: 0 0 1.5rem;
  padding-top: 147.3684210526%;
  border-radius: 2px;
  background-color: #ffffff;
  overflow: hidden;
}
.m-filePreview-label {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e6e8eb;
  color: #667284;
  background-color: #ffffff;
  font-size: 0.8125rem;
}
.m-filePreview-labelText {
  display: block;
  padding: 0 0 0 0.5rem;
  font-style: italic;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.m-filePreview-action {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  border: 0 none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0 0.5rem;
  height: 3rem;
  color: #0064fe;
}
.m-filePreview-action:hover {
  color: #0064fe;
}
.m-filePreview-action:focus {
  outline: none;
}
html:not(.touchevents) .m-filePreview-action:focus {
  background-color: #0064fe;
  color: #ffffff;
}
@media (min-width: 768px) {
  .m-filePreview-action {
    height: 2.5rem;
    color: #99a1ad;
  }
}
.m-filePreview-image {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  border: 0 none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 3rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-filePreview-image > img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}
.m-filePreview-button {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  border: 0 none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  border-radius: 2px;
  background: rgba(242, 247, 255, 0.5); /* stylelint-disable-line color-function-notation */
}
.m-filePreview-button:focus {
  outline: none;
}
html:not(.touchevents) .m-filePreview-button:focus {
  box-shadow: inset 0 0 0 1rem rgba(204, 224, 255, 0.5); /* stylelint-disable-line color-function-notation */
}
.m-filePreview-buttonContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 8px;
  border-radius: 2px;
  border: 2px dashed #66a2fe;
  color: #0064fe;
  font-weight: bold;
  font-size: 1rem;
}
.m-filePreview-buttonContent .m-icon {
  font-size: 1.375rem;
}
.m-filePreview-button input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.m-filePreview .m-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 2rem;
  stroke-width: 0.08rem;
  color: #0064fe;
}
@media (min-width: 768px) {
  .m-filePreview .m-spinner {
    font-size: 1.125rem;
    stroke-width: 0.2rem;
  }
}

.m-fullscreenDropzone {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(52, 192, 130, 0.8); /* stylelint-disable-line color-function-notation */
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: z-index, visibility, opacity;
  transition-delay: 0.15s, 0.15s, 0s;
  transition-duration: 0s, 0s, 0.15s;
}
.m-fullscreenDropzone-isVisible {
  transition-delay: 0s, 0s, 0s;
  z-index: 2020;
  visibility: visible;
  opacity: 1;
}
.m-fullscreenDropzone-content {
  position: absolute;
  top: 0.9375rem;
  left: 0.9375rem;
  right: 0.9375rem;
  bottom: 0.9375rem;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed white;
}
.m-fullscreenDropzone-label {
  margin: 1rem 0;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: bold;
}
.m-fullscreenDropzone input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.m-colorSelect {
  margin: 1.5rem 0;
}
.m-colorSelect-label {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5rem;
  display: block;
  margin: 1.5rem 0;
  color: #000000;
}
.m-colorSelect-option {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  border: 0 none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  position: relative;
  width: 3rem;
  height: 3rem;
  margin: 0 0.75rem 0.75rem 0;
  border-radius: 50%;
  color: #ffffff;
  line-height: 0;
  vertical-align: middle;
  transform: scale(1);
  transition: 250ms transform;
}
.m-colorSelect-option:active {
  transform: scale(1.1);
  transition-duration: 50ms;
}
.m-colorSelect-option:focus {
  outline: none;
}
html:not(.touchevents) .m-colorSelect-option:focus {
  box-shadow: inset 0 0 0 0.25rem rgba(0, 0, 0, 0.15); /* stylelint-disable-line color-function-notation */
}
.m-colorSelect-checkIcon {
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
  right: 0.625rem;
  bottom: 0.625rem;
  width: 1.75rem;
  height: 1.75rem;
}
.m-colorSelect-checkIcon polyline {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 1.125rem;
  stroke-dashoffset: 1.125rem;
  will-change: stroke-dashoffset;
  transition: stroke-dashoffset 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}
.m-colorSelect-option-isSelected .m-colorSelect-checkIcon polyline {
  stroke-dashoffset: 0rem;
}

.m-microAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.m-microAnimation-pulse {
  transform: translate(-6.375rem, -6.375rem);
}
.m-microAnimation-pulse span {
  position: absolute;
  top: 0;
  left: 0;
  width: 12.75rem;
  height: 12.75rem;
  border-radius: 50%;
  transform: scale(0);
  background-color: rgba(0, 100, 254, 0.9); /* stylelint-disable-line color-function-notation */
}
.m-microAnimation-pulse-isPlaying span {
  animation: 1500ms m-ma-pulse cubic-bezier(0.12, 0.22, 0.17, 0.98);
}
@keyframes m-ma-pulse {
  0% {
    opacity: 1;
    transform: scale(0.01);
  }
  100% {
    opacity: 0.001;
    transform: scale(1);
  }
}

.m-inputShortCode.m-input-element {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 3.5rem;
  cursor: text;
}
.m-inputShortCode.m-input-element:focus-within {
  border: 1px solid #66a2fe;
  box-shadow: 0 0 0 1px #66a2fe;
}
.m-inputShortCode-chars {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.9375rem 0 0.75rem;
}
.m-inputShortCode-char {
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  align-items: center;
  margin: 0 0.25rem;
  padding: 0.125rem 0 0.25rem;
  overflow: visible;
  width: 1.5rem;
  height: 1.8125rem;
  font-size: 1.5rem;
  line-height: 1.625rem;
  font-family: sans-serif;
  text-align: center;
  border: none;
  box-shadow: 0 0.125rem #99a1ad;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}
.m-inputShortCode-char:focus {
  outline: none;
  box-shadow: 0 0.125rem #667284;
}
.m-inputShortCode input:not([value=""]).m-inputShortCode-char, .m-inputShortCode-char:not(:empty) {
  box-shadow: none;
}

.m-alert-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 110;
  width: 100%;
}
.m-alert-container-isFloating {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  max-width: 24rem;
  animation: 0.3s mAlertFloatingIntro 0.3s 1 backwards ease-out;
}
@keyframes mAlertFloatingIntro {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
.m-alert-container-isFloating.m-alert-container-isCenter {
  margin: 1.5rem auto 0 auto;
}
.m-alert-container-isFloating.m-alert-container-isLeft {
  margin: 1.5rem 0 0 1.5rem;
}
.m-alert-container-isFloating.m-alert-container-isRight {
  margin: 1.5rem 1.5rem 0 auto;
}
.m-alert-container .m-alert {
  margin: 0 0 1rem 0;
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.2);
}

.m-alert {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1rem 0;
  border-radius: 0.25rem;
  color: #33435b;
}
.m-alert-isOverarching {
  margin: 0;
  border-radius: 0;
}
.m-alert-isBlocking {
  z-index: 110;
}
.m-alert-isBlocking::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 20, 50, 0.15); /* stylelint-disable-line color-function-notation */
  z-index: 100;
}
.m-alert-isSuccess {
  background-color: #d6f2e6;
}
.m-alert-isSuccess .m-alert-icon {
  color: #34c082;
}
.m-alert-isSuccess.m-alert-isSmall .m-alert-icon {
  color: #33435b;
  margin-bottom: 0.125rem;
  margin-top: 0;
}
.m-alert-isInfo {
  background-color: #cce0ff;
}
.m-alert-isInfo .m-alert-icon {
  color: #0050cb;
}
.m-alert-isInfo.m-alert-isSmall .m-alert-icon {
  color: #33435b;
  margin-bottom: 0.125rem;
  margin-top: 0;
}
.m-alert-isInfo.m-alert-isOverarching {
  background-color: #0064fe;
  color: #ffffff;
}
.m-alert-isInfo.m-alert-isOverarching .m-alert-icon.m-icon {
  color: #ffffff;
}
.m-alert-isWarning {
  background-color: #fffbcc;
}
.m-alert-isWarning .m-alert-icon {
  color: #33435b;
}
.m-alert-isWarning.m-alert-isSmall .m-alert-icon {
  color: #33435b;
  margin-bottom: 0.125rem;
  margin-top: 0;
}
.m-alert-isWarning.m-alert-isOverarching {
  background-color: #ffed00;
  color: #33435b;
}
.m-alert-isWarning.m-alert-isOverarching .m-alert-icon.m-icon {
  color: #33435b;
}
.m-alert-isError {
  background-color: #f8d7d1;
}
.m-alert-isError .m-alert-icon {
  color: #de351b;
}
.m-alert-isError.m-alert-isSmall .m-alert-icon {
  color: #33435b;
  margin-bottom: 0.125rem;
  margin-top: 0;
}
.m-alert-isError.m-alert-isOverarching {
  background-color: #de351b;
  color: #ffffff;
}
.m-alert-isError.m-alert-isOverarching .m-alert-icon.m-icon {
  color: #ffffff;
}
.m-alert-isOffline {
  background-color: #ccd0d6;
  color: #001432;
}
.m-alert-isOffline.m-alert-isOverarching {
  background-color: #33435b;
  color: #ffffff;
}
.m-alert-isOffline.m-alert-isOverarching .m-alert-icon.m-icon {
  color: #ffffff;
}
.m-alert-isFloating {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  max-width: 37.5rem;
  animation: 0.3s mAlertFloatingIntro 0.3s 1 backwards ease-out;
}
@keyframes mAlertFloatingIntro {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
.m-alert-isFloating.m-alert-isCenter {
  margin: 0 auto;
}
.m-alert-isFloating.m-alert-isLeft {
  margin: 0 0 0 1rem;
}
.m-alert-isFloating.m-alert-isRight {
  margin: 0 1rem 0 auto;
}
.m-alert-link {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  border: 0 none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  color: currentColor;
}
.m-alert-link:focus {
  outline: none;
}
html:not(.touchevents) .m-alert-link:focus {
  box-shadow: inset 0 0 0 0.25rem rgba(0, 20, 50, 0.1); /* stylelint-disable-line color-function-notation */
}
.m-alert-link:active {
  box-shadow: inset 0 0 0 0.25rem rgba(0, 20, 50, 0.1); /* stylelint-disable-line color-function-notation */
}
.m-alert-link .m-icon {
  width: 1rem;
  height: 1rem;
  color: currentColor;
  transform: translateY(-0.125em);
}
.m-alert-isSmall .m-alert-link .m-icon {
  transform: translateY(-0.0625em);
}
.m-alert-link-isText {
  text-decoration: underline;
}
.m-alert-content {
  position: relative;
  max-width: calc(100% - 2.25rem);
  display: flex;
  justify-content: flex-start;
  flex: 1 1 auto;
  align-items: flex-start;
}
.m-alert-isBlocking .m-alert-content {
  z-index: 110;
}
.m-alert-isLarge .m-alert-content {
  margin-left: 1.6875rem;
  flex-direction: column;
  max-width: calc(100% - 3rem);
}
@media (min-width: 768px) {
  .m-alert-isLarge .m-alert-content {
    display: block;
    margin-left: 2.625rem;
  }
}
.m-alert-content, .m-alert-link {
  padding: 0.4375rem 0.625rem;
  font-size: 0.8125rem;
  line-height: 1.375;
}
.m-alert-isMedium .m-alert-content, .m-alert-isMedium .m-alert-link {
  padding: 0.5625rem 0.75rem;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .m-alert-isMedium .m-alert-content, .m-alert-isMedium .m-alert-link {
    padding: 0.5625rem 1rem;
  }
}
.m-alert-isLarge .m-alert-content, .m-alert-isLarge .m-alert-link {
  padding: 0.75rem 0.75rem 1rem;
  font-size: 1rem;
}
@media (min-width: 768px) {
  .m-alert-isLarge .m-alert-content, .m-alert-isLarge .m-alert-link {
    padding: 1.0625rem 1rem 1.0625rem;
  }
}
.m-alert-isLarge.m-alert-isOverarching .m-alert-content, .m-alert-isLarge.m-alert-isOverarching .m-alert-link {
  padding: 0.625rem 0.75rem 0.5625rem;
}
.m-alert-title, .m-alert-message, .m-alert-actions {
  padding-right: 0.5rem;
}
.m-alert-isLarge .m-alert-title, .m-alert-isLarge .m-alert-message, .m-alert-isLarge .m-alert-actions {
  flex: 1;
}
@media (min-width: 768px) {
  .m-alert-isLarge .m-alert-title, .m-alert-isLarge .m-alert-message, .m-alert-isLarge .m-alert-actions {
    display: inline-block;
  }
}
.m-alert-icon.m-icon {
  flex: 0 0 auto;
  margin-top: 0.125rem;
  margin-right: 0.5rem;
  width: 0.8125rem;
  height: 0.8125rem;
}
.m-alert-isMedium .m-alert-icon.m-icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-bottom: 0.125rem;
  margin-top: 0;
}
.m-alert-isLarge .m-alert-icon.m-icon {
  position: absolute;
  left: -0.875rem;
  top: 0.75rem;
  width: 1.125rem;
  height: 1.125rem;
}
.m-alert-isLarge.m-alert-isOverarching .m-alert-icon.m-icon {
  top: 0.625rem;
}
@media (min-width: 768px) {
  .m-alert-isLarge:not(.m-alert-isOverarching) .m-alert-icon.m-icon {
    left: -0.75rem;
    top: 1.125rem;
  }
}
.m-alert-message {
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
}
.m-alert-isLarge .m-alert-message {
  flex-grow: 0;
}
.m-alert-isLarge .m-alert-title {
  font-weight: bold;
}
.m-alert-isLarge .m-alert-actions {
  margin-top: 0.25rem;
}
@media (min-width: 768px) {
  .m-alert-isLarge .m-alert-actions {
    margin-top: 0;
  }
}
.m-alert-action {
  color: currentColor;
  text-decoration: underline;
  padding-right: 0.5rem;
}

.m-notification {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 0 #e6e8eb;
}
.m-notification-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 auto;
  padding: 1rem 0;
}
.m-notification-summary > .m-icon {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin: 0 0.8125rem 0 1.1875rem;
  color: #667284;
}
@media (min-width: 768px) {
  .m-notification-summary > .m-icon {
    margin: 0 1.5rem;
  }
}
.m-notification-expandButton {
  margin-right: 1rem;
}
.m-notification-content {
  flex: 1 1 auto;
  color: #001432;
  line-height: 1.5;
}
.m-notification-expandedDetail {
  padding: 0 1.5rem 1.75rem;
  line-height: 1.5;
}
.m-notification-title {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.375rem;
}
.m-notification-isHighlighted {
  box-shadow: inset 0.375rem 0 0 #ffed00, inset 0 -1px 0 0 #e6e8eb;
}

.m-storeID {
  display: inline-block;
  font-size: 0.8125rem;
  line-height: 0.875rem;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  color: #002d72;
  background-color: #e6e8eb;
  padding: 0.25rem 0.5625rem;
  border-radius: 1.375rem;
}
.m-storeID-isPrimary {
  background-color: #f7a800;
}
.m-storeID-isCompact {
  padding: 0.125rem 0.5625rem;
  border-radius: 1.125rem;
}

.m-navigation {
  margin: 0;
}
.m-navigation-link {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #002d72;
  cursor: pointer;
}
html:not(.touchevents) .m-navigation-link:hover {
  text-decoration: none;
  color: #0050cb;
  background: #f2f7ff;
}
.m-navigation-link:last-child:not(:first-child) {
  margin-bottom: 0.75rem;
}
.m-navigation-link-isMobileLink {
  border-bottom: 0.0625rem solid #e6e8eb;
  padding: 0.75rem 1rem;
}
.m-navigation-link-isActive {
  box-shadow: inset 0.25rem 0 0 0 #002d72;
}
.m-navigation-link .m-badge {
  vertical-align: middle;
}
.m-navigation-link .m-icon {
  width: 1rem;
  height: 1.25rem;
  vertical-align: -0.25rem;
}
.m-navigation-link-icon {
  display: inline-block;
  margin-right: 0.625rem;
}
.m-navigation-link-chevron {
  float: right;
}
.m-navigation-link-isBackLink {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  color: #0064fe;
  border-bottom: 0.0625rem solid #e6e8eb;
}
.m-navigation-link-isBackLink .m-icon {
  width: 1.75rem;
  height: 1.75rem;
  vertical-align: -0.5rem;
}
.m-navigation-separator {
  margin: 0.5rem 0;
  height: 0.0625rem;
  border: none;
  background-color: #e6e8eb;
}
.m-navigation-separator-isMarginless {
  margin: 0;
}

.m-nestedView {
  overflow: hidden;
  width: 100%;
  flex-grow: 1;
}
.m-nestedView-scroller {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  transition: 320ms transform;
  transition-timing-function: ease-in-out;
}
.m-nestedView-page {
  box-shadow: 0 8px 32px 0 rgba(0, 45, 114, 0.2);
  flex: 0 0 auto;
  width: 100%;
  padding-bottom: 1rem;
  background-color: #ffffff;
  transform: translateX(0);
  transition: 320ms transform ease-in-out;
}
.m-nestedView-page-isTail {
  transform: translateX(70%);
}
@supports (filter: brightness(0.8)) {
  .m-nestedView-page {
    transition: 320ms transform ease-in-out, 320ms filter ease-in-out;
  }
  .m-nestedView-page-isTail {
    filter: brightness(0.8);
  }
}

.m-sideGrid {
  display: flex;
}
.m-sideGrid-sideNavigation {
  flex: 0 0 10rem;
}
.m-sideGrid-sideContent {
  flex: 1;
}
@media (min-width: 1280px) {
  .m-sideGrid-sideNavigation {
    flex: 0 0 12.25rem;
  }
}

.m-companyProfile.m-companyProfile-mobile.m-companyProfile-header strong {
  color: #ffffff;
}
.m-companyProfile.m-companyProfile-mobile.m-companyProfile-header em {
  color: #99abc7;
}
.m-companyProfile.m-companyProfile-disabled:last-child:not(:first-child) {
  box-shadow: inset 0 -1px 0 0 #e6e8eb;
}
.m-companyProfile.m-companyProfile-disabled.m-companyProfile-mobile:not(.m-companyProfile-header), .m-companyProfile.m-companyProfile-disabled.m-companyProfile-desktop {
  background-color: white;
  background-image: repeating-linear-gradient(40deg, rgba(230, 232, 235, 0.5) 0, rgba(230, 232, 235, 0.5) 2px, rgba(230, 232, 235, 0) 2px, rgba(230, 232, 235, 0) 10px);
}
.m-companyProfile.m-companyProfile-disabled strong {
  color: #99a1ad;
}
.m-companyProfile.m-companyProfile-disabled em {
  color: #667284;
}
.m-mediaList .m-companyProfile.m-media .m-address {
  margin-top: 1px;
  line-height: 1.3;
}
.m-mediaList .m-companyProfile.m-media .m-address strong {
  color: #011531;
}

.m-zzz {
  display: block;
  width: 3.0555555556em;
  height: 3.0555555556em;
  /* Allow override of margin-top/-bottom */
  margin-left: auto;
  margin-right: auto;
  padding: 0.5555555556em 0;
  position: relative;
  white-space: nowrap;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  /* Intentionally using px, to _prevent scaling_ on very small screens */
  font-size: 18px;
  font-weight: bold;
  color: #ccd0d6;
  overflow: hidden;
  flex: 0 0 auto;
  animation: 4s barcodeCardPendingZZ 0.2s infinite both;
}
@keyframes barcodeCardPendingZZ {
  0% {
    color: #e6e8eb; /* stylelint-disable-line color-function-notation */
  }
  30% {
    color: #ccd0d6; /* stylelint-disable-line color-function-notation */
  }
  90% {
    color: #ccd0d6; /* stylelint-disable-line color-function-notation */
  }
  100% {
    color: #e6e8eb; /* stylelint-disable-line color-function-notation */
  }
}
.m-zzz::before, .m-zzz::after {
  content: "Z";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  animation: inherit;
}
.m-zzz::before {
  animation-delay: 0s;
  transform: matrix(2, 0, 0, 2, -17, 38);
}
.m-zzz::after {
  animation-delay: 0.4s;
  transform: matrix(0.5, 0, 0, 0.5, 9.3, -4.2);
}
.m-zzz-isLarge {
  /* Intentionally using px, to _prevent scaling_ on very small screens */
  font-size: 30px;
  width: 3.0666666667em;
  height: 3.0666666667em;
  padding: 1em 0;
}
.m-zzz-isLarge::before {
  transform: matrix(2, 0, 0, 2, -28, 78);
}
.m-zzz-isLarge::after {
  transform: matrix(0.5, 0, 0, 0.5, 16, 4);
}

.m-CheckboxTogglePane-children {
  margin-top: 0.8125rem;
}
.m-CheckboxTogglePane .m-togglePane-content > *:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 320px) {
  .m-svgImage {
    transform: scale(0.85);
  }
}
.m-navigationHeaderBreadcrumb {
  font-size: 0;
}
.m-navigationHeaderBreadcrumb .m-navigationHeaderBreadcrumb-link {
  display: inline-block;
  color: #0064fe;
  text-decoration: none;
  font-size: 0.6875rem;
  line-height: 1.2;
}
.m-navigationHeaderBreadcrumb .m-navigationHeaderBreadcrumb-link:hover {
  text-decoration: underline;
}
.m-navigationHeaderBreadcrumb .m-navigationHeaderBreadcrumb-link .m-icon {
  width: 0.5rem;
  height: 0.5rem;
  vertical-align: bottom;
  margin-bottom: 0.125rem;
  margin-right: 0.125rem;
}
.m-navigationHeaderBreadcrumb .m-navigationHeaderBreadcrumb-active {
  color: rgb(65, 65, 65);
  cursor: default;
}
.m-navigationHeaderBreadcrumb .m-navigationHeaderBreadcrumb-active:hover, .m-navigationHeaderBreadcrumb .m-navigationHeaderBreadcrumb-active:focus, .m-navigationHeaderBreadcrumb .m-navigationHeaderBreadcrumb-active:focus-within {
  text-decoration: none;
}
.m-navigationHeaderBreadcrumb .m-navigationHeaderBreadcrumb-label {
  padding-right: 0.125rem;
}
.m-navigationHeaderBreadcrumb .m-navigationHeaderBreadcrumb-title {
  font-size: 1.375rem;
  line-height: 1.5rem;
  font-weight: bolder;
  color: #002d72;
  background: #ffffff;
}

.m-typeahead.ng-dirty.ng-invalid input {
  box-shadow: 0 0 0 2px #eb8676;
}
.m-typeahead.m-select-input,
.m-typeahead .m-select-input {
  cursor: auto;
}

.dropdown-menu {
  position: absolute !important;
}
.dropdown-menu .dropdown-item.active {
  background-color: #e6f0ff !important;
}
.dropdown-menu .dropdown-item a {
  color: #001432;
  text-decoration: none !important;
}

.m-file-preview {
  margin-top: 0.5rem;
}
.m-file-preview .m-file-preview__wrapper {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 1.25rem;
}
.m-file-preview .m-file-preview__icon {
  position: absolute;
  height: 1rem;
  width: 1rem;
  display: flex;
  align-items: center;
  bottom: 0.0625rem;
  left: 0;
}
.m-file-preview .m-file-preview__icon mm-icon {
  cursor: pointer;
}
.m-file-preview .m-file-preview__icon mm-icon svg {
  color: #e74c3c;
}
.m-file-preview .m-file-preview__info {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 1rem;
}
.m-file-preview .m-file-preview__info-name {
  font-size: 0.75rem;
  color: #001432;
  line-height: 1.33;
  height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  flex: 8;
}
.m-file-preview .m-file-preview__info-size {
  font-size: 0.6875rem;
  font-weight: bold;
  line-height: 1.2;
  flex: 1;
  text-align: right;
}
.m-file-preview .m-file-preview__image-wrapper {
  position: relative;
  display: inline-block;
  margin-right: 1.25rem;
  margin-bottom: 0.625rem;
  padding: 0.375rem 0.75rem;
  border: 0.0625rem solid rgb(229, 229, 229);
  background-size: contain;
  background-position: center;
  background-origin: content-box;
  background-repeat: no-repeat;
  height: 14rem;
  width: 10rem;
}
.m-file-preview .m-file-preview__image-icon {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  background-color: rgb(229, 229, 229);
  width: 1.375rem;
  height: 1.375rem;
}
.m-file-preview .m-file-preview__image-icon mm-icon {
  cursor: pointer;
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
}

.modal {
  z-index: 80;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  background-color: rgba(102, 129, 170, 0.3);
}

.modal-dialog {
  min-height: 100vh;
  text-align: center;
  pointer-events: none;
  white-space: nowrap;
}
.modal-dialog::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100vh;
  overflow: hidden;
}

.modal-content {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.m-modal {
  display: block;
  white-space: normal;
  -webkit-overflow-scrolling: touch;
  /* Specificy set to ensure override from .panel */
}
.m-modal-enter {
  will-change: background-color;
  animation: 210ms modal-bg-fade-in;
}
.m-modal-enter .m-modal-panel {
  will-change: transform, opacity;
  animation: 210ms modal-panel-fade-in;
}
@keyframes modal-bg-fade-in {
  from {
    background-color: rgba(102, 129, 170, 0);
  }
  to {
    background-color: rgba(102, 129, 170, 0.3);
  }
}
@keyframes modal-panel-fade-in {
  from {
    transform: translateY(2%) scale(0.95);
    opacity: 0;
  }
  to {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
}
.m-modal-exit {
  will-change: background-color;
  animation: 210ms modal-bg-fade-out both;
}
.m-modal-exit .m-modal-panel {
  will-change: transform, opacity;
  animation: 210ms modal-panel-fade-out;
}
@keyframes modal-bg-fade-out {
  from {
    background-color: rgba(102, 129, 170, 0.3);
  }
  to {
    background-color: rgba(102, 129, 170, 0);
  }
}
@keyframes modal-panel-fade-out {
  from {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }
  to {
    transform: translateY(2%) scale(0.95);
    opacity: 0;
  }
}
.m-modal.m-modal-compact .m-modal-panel {
  max-width: 505px;
}
@media (max-width: 768px) {
  .m-modal.m-modal-compact .m-modal-panel {
    max-width: none;
  }
}
.m-modal.m-modal-centeredContent .m-modal-content {
  text-align: center;
}
.m-modal.m-modal-bottom .m-modal-panel {
  max-width: none;
  width: 100%;
  min-height: 0;
  border-radius: 0;
  box-shadow: none;
  vertical-align: bottom;
}
.m-modal.m-modal-bottom.m-modal-enter {
  -webkit-backface-visibility: hidden;
  overflow: hidden;
}
.m-modal.m-modal-bottom.m-modal-enter.m-modal-enter-active .m-modal-panel {
  animation: 210ms m-modal-panel-slide-in;
}
.m-modal.m-modal-bottom.m-modal-exit {
  overflow: hidden;
}
.m-modal.m-modal-bottom.m-modal-exit .m-modal-panel {
  animation: 210ms m-modal-panel-slide-in reverse;
  will-change: transform;
}
@keyframes m-modal-panel-slide-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
.m-modal .m-modal-panel {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  max-width: 677px;
  width: 100%;
  pointer-events: auto;
}
.m-modal-card {
  box-shadow: 0 8px 32px 0 rgba(0, 45, 114, 0.2);
  border-radius: 3px;
  background-color: white;
}
.m-modal-contentBelow {
  margin: 0.875rem 0;
}
.m-modal-content {
  text-align: left;
  display: block;
}
.m-modal-header {
  font-size: 1rem;
  text-align: left;
}
.m-modal-footer {
  display: flex;
  justify-content: flex-start;
  margin-top: 0;
}
.m-modal-footer-isSpread {
  justify-content: space-between;
}
.m-modal-footer-isCentered {
  justify-content: center;
}
.m-modal-close {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 1rem;
  color: #002d72;
  float: right;
  font-size: 1rem;
  line-height: 0;
}
.m-modal-compact .m-modal-close {
  height: 4rem;
  padding: 0 1rem;
  font-size: 1.375rem;
}
@media (max-width: 768px) {
  .m-modal.m-modal-bottom .m-modal-panel {
    height: auto;
  }
  .m-modal .m-modal-panel {
    width: 100%;
    min-height: 100%;
    max-width: none;
  }
  .m-modal .m-modal-panel .m-modal-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }
  .m-modal .m-modal-panel .m-modal-header {
    flex-shrink: 0;
    background-color: #002d72;
    color: #ffed00;
  }
  .m-modal .m-modal-panel .m-modal-header .m-icon {
    color: white;
  }
  .m-modal .m-modal-panel .m-modal-content {
    flex-shrink: 0;
  }
  .m-modal .m-modal-panel .m-modal-footer {
    align-self: flex-start;
    flex-shrink: 0;
    width: 100%;
    padding: 1rem;
  }
}

.m-modalContainer {
  display: block;
  transition: 0s bottom, 0s height, 0s z-index;
  transition-delay: 200ms, 200ms, 200ms;
}
.m-modalContainer-active {
  bottom: 0;
  z-index: 80;
  transition-delay: 0s, 0s, 0s;
}

.m-panel-contentSmallPadding {
  margin: 1rem;
}

.m-progressStepper {
  max-width: 47.625rem;
}

.step {
  display: flex;
}
.step__item {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}
.step__item .circle-block {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
}
.step__item .divider-block {
  flex: 1;
}
.step__item:not(.step__item--last, .step__item--first) .circle-block {
  background-color: transparent;
  border: 0.125rem solid #99a1ad;
  height: 1rem;
  width: 1rem;
}
.step__item:not(.step__item--last, .step__item--first).step__item--done .circle-block {
  background-color: transparent;
  border-color: #002866;
  height: 1rem;
  width: 1rem;
}
.step__item:not(.step__item--last, .step__item--first).step__item--done .circle-block::after {
  border: 0.0625rem solid #002866;
  border-right: none;
  border-top: none;
  content: "";
  height: 0.25rem;
  left: 0.1875rem;
  position: absolute;
  top: 0.1875rem;
  transform: rotate(-45deg);
  width: 0.375rem;
}
.step__item:not(.step__item--last, .step__item--first).step__item--active .circle-block {
  background-color: #2a9a68;
  border: 0.25rem solid #d6f2e6;
  height: 1rem;
  width: 1rem;
}
.step__item--last .circle-block {
  background-color: #ccd0d6;
  border: none;
  height: 0.5rem;
  width: 0.5rem;
  margin: 0 0.25rem;
}
.step__item--last.step__item--done .circle-block {
  background-color: #2a9a68;
  border: 0.125rem solid white;
  height: 1rem;
  width: 1rem;
  margin: 0;
}
.step__item--last.step__item--done .circle-block::after {
  border: 0.0625rem solid white;
  border-right: none;
  border-top: none;
  content: "";
  height: 0.25rem;
  left: 0.1875rem;
  position: absolute;
  top: 0.1875rem;
  transform: rotate(-45deg);
  width: 0.375rem;
}
.step__item--first .circle-block {
  background-color: #2a9a68;
  border: 0.25rem solid #d6f2e6;
  height: 1rem;
  width: 1rem;
}
.step__item--first.step__item--done .circle-block {
  background-color: #002866;
  border: none;
  height: 0.5rem;
  width: 0.5rem;
  margin: 0 0.25rem;
}
.step__item:not(.step__item--last) .divider-block {
  border-left: 0.125rem solid #ccd0d6;
}
.step__item--done:not(.step__item--last) .divider-block {
  border-left: 0.125rem solid #002866 !important;
}
.step__point {
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
}
.step__point-title {
  color: #56b20d;
  font-size: 0.875rem;
  line-height: 0.5;
}
.step__point--end {
  background-color: #56b20d;
}
.step__title {
  font-style: italic;
  font-size: 0.75em;
  color: #99a1ad;
  line-height: 1.33;
  min-height: 1rem;
  white-space: pre-wrap;
}
.step__title--last {
  color: #2a9a68;
}
.step__date {
  font-size: 0.6875rem;
  line-height: 1.2;
  color: #99a1ad;
  height: 0.75rem;
}
.step__description {
  flex: 2;
  max-width: 29rem;
}
.step__description p {
  margin: 0;
  font-size: 0.75rem;
  line-height: 1.33;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #99a1ad;
}
.step__warning {
  color: #e55d49;
  cursor: pointer;
  display: inline-block;
  font-size: 0.6875rem;
  line-height: 1.2;
  height: 0.75rem;
  width: -moz-max-content;
  width: max-content;
  padding-right: 0.25rem;
}
.step__info {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-width: 0;
  width: 100%;
  padding-bottom: 1.5rem;
}
.step__info-content {
  flex: 1;
  max-width: 12rem;
  margin-right: 1rem;
}
.step__info--active .step__title {
  font-size: 0.8125rem;
  font-weight: bold;
  font-style: normal;
  line-height: 1.23;
  color: #002d72;
}
.step__info--active .step__description p {
  color: #001432;
}
.step__info--first, .step__info--last {
  margin-top: -0.25rem;
}
.step__info--done.step__info--last {
  margin-top: 0;
}

/* Base colors */
.mm-page-counter {
  display: inline-flex;
}
.mm-page-counter-number, .mm-page-counter-divider, .mm-page-counter-active {
  line-height: 1.25rem;
  font-size: 1rem;
  font-weight: bold;
  color: #002d72;
}

.m-textarea {
  overflow: hidden;
  padding: 0.5rem 0.75rem;
  height: 2rem;
  min-height: 2rem;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  line-break: auto;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  font-size: 0.75rem;
  word-wrap: break-word;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 0.375rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.375rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.375rem 0.375rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right],
.bs-tooltip-end {
  padding: 0 0.375rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-end .arrow {
  left: 0;
  width: 0.375rem;
  height: 0.75rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-end .arrow::before {
  right: 0;
  border-width: 0.375rem 0.375rem 0.375rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.375rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.375rem 0.375rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left],
.bs-tooltip-start {
  padding: 0 0.375rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-start .arrow {
  right: 0;
  width: 0.375rem;
  height: 0.75rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before,
.bs-tooltip-start .arrow::before {
  left: 0;
  border-width: 0.375rem 0 0.375rem 0.375rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem;
  color: #ffffff;
  text-align: left;
  background-color: #000000;
  border-radius: 0.25rem;
}

[containerClass=m-info-type-tooltip] ~ bs-tooltip-container .tooltip-inner {
  width: 17.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(136, 140, 147, 0.25);
  border: solid 0.0625rem #ccd0d4;
  background-color: #fffbf0;
  color: #414141;
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
}
[containerClass=m-info-type-tooltip] ~ bs-tooltip-container .tooltip-arrow {
  display: none;
}

.m-info-box {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1rem 0;
  border-radius: 0.25rem;
  background-color: #d6f2e6;
  padding: 0.5625rem 0.5625rem;
}
.m-info-box-title {
  color: #667284;
  font-size: 0.6875rem;
  line-height: 1.2;
  margin: 0 0 0.25rem 0;
}
.m-info-box-description {
  font-size: 0.75rem;
  line-height: 1.33;
  color: #001432;
}

.m-horizontal-progress-header-container {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}
.m-horizontal-progress-header-container--bottom {
  margin-bottom: 2.75rem;
  padding: 0 2.5rem;
}
.m-horizontal-progress-header-container--bottom .m-index--label {
  top: 1.5rem;
  text-align: center;
}
.m-horizontal-progress-header-container--right .m-index--label {
  top: 0;
  left: 1rem;
  background-color: white;
  padding: 0 0.5rem;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header {
  flex: 1;
  position: relative;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header:last-of-type {
  max-width: 1rem;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header .m-step-number {
  border-bottom: 0.125rem solid #ccd0d6;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header .m-step-number--last {
  border: none;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header .m-step-number .m-index {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.625rem;
  box-shadow: inset 0 0 0 0.125rem #ccd0d6;
  background-color: white;
  color: #99a1ad;
  border-radius: 100%;
  position: relative;
  top: 0.5625rem;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header .m-step-number .m-index--label {
  font-size: 0.75rem;
  position: absolute;
  color: black;
  min-width: -moz-max-content;
  min-width: max-content;
  line-height: 1rem;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header .m-step-number .m-index mm-icon {
  display: flex;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header--active .m-step-number {
  border-bottom: 0.125rem solid #ccd0d6;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header--active .m-step-number--last {
  border: none;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header--active .m-step-number .m-index {
  box-shadow: unset;
  background-color: #0064fe;
  color: white;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header--completed .m-step-number {
  border-bottom: 0.125rem solid #5dcd9b;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header--completed .m-step-number--last {
  border: none;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header--completed .m-step-number .m-index {
  box-shadow: unset;
  background-color: #5dcd9b;
  color: white;
}
.m-horizontal-progress-header-container .m-horizontal-progress-header--completed .m-step-number ::ng-deep mm-icon svg {
  margin-bottom: 0.125rem;
}

.m-horizontal-content-container .m-step-content {
  visibility: hidden;
  height: 0;
}
.m-horizontal-content-container .m-active-step {
  visibility: initial;
  height: initial;
}

.m-dropdown-container {
  position: relative;
}
.m-dropdown-container.open .dropdown-menu {
  display: block;
}
.m-dropdown-container .dropdown-menu {
  display: none;
  opacity: 0;
  min-width: 11.5625rem;
  margin: 0;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e5e5e5;
  box-shadow: 0 0.125rem 0.125rem 0 #e5e5e5;
  white-space: normal;
  z-index: 100;
  max-height: unset;
}
.m-dropdown-container .dropdown-menu li {
  padding: 0;
}
.m-dropdown-container .dropdown-menu li:hover {
  background: #e6f0ff;
}
.m-dropdown-container .dropdown-menu li:hover .dropdown-item {
  background: #e6f0ff;
}
.m-dropdown-container .dropdown-menu__divider {
  height: 1px;
  background-color: #e6e8eb;
  margin: 0.5rem 1rem;
}
.m-dropdown-container .dropdown-menu.datepicker {
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.m-dropdown-container .dropdown-menu.datepicker .m-datePicker {
  box-shadow: none;
  margin: 0.5rem;
}
.m-dropdown-container .dropdown-menu.search-input .search-wrapper {
  border: none;
}
.m-dropdown-container .dropdown-menu.tree-menu {
  min-width: 19rem;
}
.m-dropdown-container .list-wrapper {
  max-height: 15rem;
  overflow-x: auto;
}
.m-dropdown-container .dropdown-item {
  display: block;
  padding: 0.5rem 1rem;
}
.m-dropdown-container .m-checkbox {
  padding: 0.5rem 1rem;
}
.m-dropdown-container .search-wrapper {
  position: relative;
  border-bottom: 1px solid #e6e8eb;
  padding: 0.625rem;
  margin: 0;
}
.m-dropdown-container .reset-button-wrapper {
  border-top: 1px solid #e6e8eb;
  padding: 0.625rem;
}
.m-dropdown-container .reset-button-wrapper .reset-filter {
  padding: 0;
}
.m-dropdown-container .apply-button-wrapper {
  border-top: 1px solid #e6e8eb;
  padding: 0.625rem 0.625rem 0;
}
.m-dropdown-container .apply-button-wrapper .apply-filter {
  padding: 0;
}
.m-dropdown-container .apply-button-wrapper + .reset-button-wrapper {
  border-top: none;
}
.m-dropdown-container .m-input-element {
  padding: 0 0.5rem;
}
.m-dropdown-container .reset-filter,
.m-dropdown-container .apply-filter {
  font-size: 0.75rem;
  cursor: pointer;
  outline: none;
  border: 0;
  background: none;
}
.m-dropdown-container .reset-filter .icon-wrap,
.m-dropdown-container .apply-filter .icon-wrap {
  display: inline-block;
  vertical-align: top;
  margin-top: -0.125rem;
  margin-right: 0.25rem;
}
.m-dropdown-container .reset-filter {
  color: #de351b;
  padding: 0.5625rem 0.5rem 0.5rem;
}
.m-dropdown-container .apply-filter {
  color: #0064fe;
  font-size: 0.75rem;
}
.m-dropdown-container .apply-filter[disabled] {
  color: #ccd0d6;
  cursor: default;
}
.m-dropdown-container .dropdown-description {
  font-size: 0.75rem;
  padding: 0.625rem 0.625rem 0;
}
.m-dropdown-container .value-wrapper {
  align-items: center;
  display: flex;
}
.m-dropdown-container .value-wrapper .search-wrapper {
  width: 65%;
}
.m-dropdown-container .search-unit {
  font-size: 0.75rem;
}

.m-search {
  position: relative;
}
.m-search .search-container {
  position: relative;
}
.m-search .search-types mm-radiobutton-group div {
  display: flex;
}
.m-search .search-types mm-radiobutton-group div mm-radiobutton {
  margin-right: 1.25rem;
  margin-bottom: 0.125rem;
}
.m-search .search-types mm-radiobutton-group div mm-radiobutton .m-radioButton-label {
  margin-left: 0.5rem;
}
.m-search.with-dropdown {
  display: flex;
}
.m-search.with-dropdown .search-container {
  flex: 1 1 100%;
}
.m-search.with-dropdown .search-by-dropdown {
  cursor: pointer;
  border-radius: 0.125rem 0 0.125rem 0.125rem;
  box-shadow: 0 0 0 1px #ccd0d6;
  display: block;
  height: 2rem;
  padding: 0.3125rem 0.3125rem 0.3125rem 0rem;
  background-color: #f2f7ff;
}
.m-search.with-dropdown .search-by-dropdown__label {
  white-space: nowrap;
  color: #002d72;
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1.25rem;
  border: none;
  background-color: #f2f7ff;
  margin-bottom: 0.3125rem;
}
.m-search.with-dropdown .search-by-dropdown .m-dropdown-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100%;
}
.m-search.with-dropdown .search-types {
  flex: 1 0 8rem;
}
.m-search.with-dropdown .search-types .toggler {
  display: flex;
  justify-content: space-between;
}
.m-search.with-dropdown .search-types .toggler .search-by-dropdown__label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 6.5rem;
  display: block;
}
.m-search .icons {
  position: absolute;
  top: 0;
  display: flex;
  right: 0;
  color: #99a1ad;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgb(255, 255, 255) 30%);
}
.m-search .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2rem;
  cursor: pointer;
  padding: 0 0 0 0.5rem;
}
.m-search .icon-wrapper mm-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-search .clear-icon {
  display: none;
}
.m-search .clear-icon.active {
  display: flex;
}
.m-search.filled .icons {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgb(255, 255, 255) 15%);
}
.m-search.filled .icon-wrapper:nth-child(2) {
  padding: 0;
  width: 2rem;
}

.m-search-with-file-upload-dropzone {
  padding: 0;
  margin: 0;
}
.m-search-with-file-upload-dropzone.m-fileDropzone {
  border-color: white;
}
.m-search-with-file-upload-dropzone.m-fileDropzone:hover:not(.m-fileDropzone-inactive) {
  cursor: pointer;
  transition: ease-in 0.2s;
  border-color: white !important;
}
.m-search-with-file-upload-dropzone.m-fileDropzone .m-radioButton .m-radioButton-label {
  font-size: 0.75rem;
}
.m-search-with-file-upload-dropzone .m-fileDropzone-button {
  color: #99a1ad;
}
.m-search-with-file-upload-dropzone .search-types {
  background-color: white;
}
.m-search-with-file-upload-dropzone .m-search {
  width: 100%;
}
.m-search-with-file-upload-dropzone .m-search .icon-wrapper.clear-icon {
  width: 1.4rem;
}
.m-search-with-file-upload-dropzone .m-search .icon-wrapper.search-icon {
  padding-right: 1rem;
}
.m-search-with-file-upload-dropzone .m-search .icon-wrapper + .icon-wrapper {
  padding-left: 0.4rem;
  width: 1.8rem;
}

.m-tag-filters-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.25rem;
}
.m-tag-filters-container.search-bar-with-types {
  align-items: end;
}
.m-tag-filters-container > * {
  margin: 0 0.25rem 0.5rem;
}
.m-tag-filters-container--disabled {
  pointer-events: none;
}
.m-tag-filters-container--disabled .date-range-container .remove-icon,
.m-tag-filters-container--disabled .m-dropdown-container .remove-icon {
  pointer-events: none;
  color: #99a1ad;
}
.m-tag-filters-container .outer-content {
  display: inline-block;
  vertical-align: top;
  width: 21rem;
}
.m-tag-filters-container .add-filter, .m-tag-filters-container .reset-filter {
  padding: 0.5625rem 0.5rem 0.5rem;
  color: #0064fe;
  font-size: 0.75rem;
  cursor: pointer;
  outline: none;
  border: 0;
  background: none;
}
.m-tag-filters-container .add-filter + .dropdown-menu, .m-tag-filters-container .reset-filter + .dropdown-menu {
  min-width: 9.5rem;
}
.m-tag-filters-container .reset-filter {
  color: #de351b;
}
.m-tag-filters-container .icon-wrap {
  display: inline-block;
  vertical-align: top;
  margin-top: -0.125rem;
  margin-right: 0.25rem;
}
.m-tag-filters-container .filter-tag {
  position: relative;
  padding: 0.5rem 0.5rem 0.5625rem 0.5rem;
  font-size: 0.75rem;
  white-space: nowrap;
  background-color: #e6f0ff;
  cursor: pointer;
}
html:not(.touchevents) .m-tag-filters-container .filter-tag:hover {
  background-color: #cce0ff;
}
.m-tag-filters-container .filter-tag.removable {
  padding-right: 2.5rem;
}
.m-tag-filters-container .filter-tag.empty {
  background-color: #f2f3f5;
}
html:not(.touchevents) .m-tag-filters-container .filter-tag.empty:hover:hover,
html:not(.touchevents) .m-tag-filters-container .filter-tag.empty .remove-icon:hover {
  background-color: #e6e8eb;
}
.m-tag-filters-container .filter-tag.disabled {
  pointer-events: none;
  color: #667284;
  background-color: #e6e8eb;
  background-image: repeating-linear-gradient(40deg, rgba(204, 208, 214, 0.5) 0, rgba(204, 208, 214, 0.5) 2px, rgba(204, 208, 214, 0) 2px, rgba(204, 208, 214, 0) 10px);
}
html:not(.touchevents) .m-tag-filters-container .filter-tag.disabled:hover {
  background-color: #e6e8eb;
  text-decoration: none;
  box-shadow: none;
}
.m-tag-filters-container .m-dropdown-container.open .filter-tag,
.m-tag-filters-container .m-dropdown-container.open .filter-tag.empty,
.m-tag-filters-container .m-dropdown-container.open .remove-icon {
  background-color: #cce0ff;
}
html:not(.touchevents) .m-tag-filters-container .m-dropdown-container.open .filter-tag:hover,
html:not(.touchevents) .m-tag-filters-container .m-dropdown-container.open .filter-tag.empty:hover,
html:not(.touchevents) .m-tag-filters-container .m-dropdown-container.open .remove-icon:hover {
  background-color: #cce0ff;
}
.m-tag-filters-container .filter-name {
  margin-right: 0.25rem;
  font-size: 0.6875rem;
  color: #667284;
}
.m-tag-filters-container .remove-icon {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 0.375rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  color: #0064fe;
  cursor: pointer;
}
.m-tag-filters-container .remove-icon:hover {
  background-color: #cce0ff;
}
.m-tag-filters-container .remove-icon mm-icon {
  display: inline-block;
}

mm-uploader .m-uploader-wrapper {
  position: relative;
}
mm-uploader.ng-invalid.ng-dirty input.m-input-uploader {
  box-shadow: 0 0 0 0.125rem #eb8676;
}
mm-uploader mm-embed-link-icon span.m-input-iconLink {
  top: 0;
  right: 0;
}

.m-confirmation-alert .m-panel {
  height: auto;
}
.m-confirmation-alert .m-modal-content {
  text-align: center;
}
.m-confirmation-alert button {
  margin: 0 10px;
}

.m-no-data-message {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f2f3f5;
}
.m-no-data-message .m-no-data-message-text {
  font-size: 0.75rem;
  color: #667284;
}

.m-file-tile {
  max-width: 18.25rem;
  flex: 1;
  padding: 0.625rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  position: relative;
  transition: ease-out 0.4s;
  border: 1px solid #ccd0d6;
}
.m-file-tile .document-main-info {
  width: 96%;
  display: flex;
  align-items: center;
}
.m-file-tile__no-file-message {
  font-size: 0.75rem;
  line-height: 1.33;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #99a1ad;
  font-style: italic;
}
.m-file-tile--is-clickable {
  cursor: pointer;
}
.m-file-tile--is-clickable:hover {
  box-shadow: 0 -0.125rem 0.5rem 0 #e6e8eb;
  transition: ease-in 0.4s;
}
.m-file-tile--is-clickable:hover .m-file-tile__action-menu {
  background-color: #e6f0ff;
  transition: ease-in 0.4s;
}
.m-file-tile__icon {
  height: 1.5rem;
}
.m-file-tile__trash-icon {
  cursor: pointer;
  display: flex;
  right: 1.6rem;
  position: relative;
  bottom: 0.3rem;
}
.m-file-tile__action-menu {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: unset;
  right: 0.5rem;
  transition: ease-out 0.4s;
  border-radius: 0.125rem;
}
.m-file-tile__action-menu button:not(:focus) {
  background-color: unset;
  transition: ease-out 0.4s;
}
.m-file-tile__action-menu button:focus {
  background-color: #e6f0ff;
  transition: ease-in 0.4s;
}
.m-file-tile__info {
  flex: 1;
  max-width: calc(100% - 3rem);
  margin-left: 0.5rem;
  padding-right: 0.75em;
  display: flex;
  flex-direction: column;
}
.m-file-tile__info .document-secondary-info {
  align-items: center;
  line-height: 1.2;
  height: 0.75rem;
  display: flex;
}
.m-file-tile__info .m-file-tile__name {
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.33;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #002d72;
}
.m-file-tile__info .m-file-tile__size, .m-file-tile__info .m-file-tile__date {
  color: #99a1ad;
  font-size: 0.625rem;
  line-height: 1.2;
  margin: 0;
}
.m-file-tile__info .m-file-tile__flag {
  height: 1.3rem;
  margin-left: 0.5rem;
}

.mm-horizontal-tree {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: stretch;
}
.mm-horizontal-tree__column {
  overflow-y: auto;
  width: 25%;
  align-self: auto;
  padding: 0.75rem 0.25rem;
  border-radius: 2px;
  background-color: #e6e8eb;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node {
  padding: 0 0.5rem;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #001432;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node--opened {
  font-weight: bold;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node--opened .mm-horizontal-tree-node__icon {
  color: #001432;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node--selected {
  background: #ccd0d6;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node--selected .mm-horizontal-tree-node__icon {
  color: #33435b;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node__icon {
  color: #99a1ad;
  font-size: 0.5rem;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node__icon:not(:last-child) {
  margin-right: 0.25rem;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node__icon--disabled {
  background: inherit;
  pointer-events: none;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node__icon--extra-margin {
  margin-right: 1rem;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node--disabled {
  background: #f2f3f5;
  pointer-events: none;
  color: #99a1ad;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node--disabled .mm-horizontal-tree-node__icon > svg {
  color: #99a1ad;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node--selected-multi-selection {
  background: #e6f0ff;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node--selected-multi-selection .mm-horizontal-tree-node__icon {
  color: #0064fe;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node:hover {
  background: #e6f0ff;
}
.mm-horizontal-tree__column .mm-horizontal-tree-node:hover .mm-horizontal-tree-node__icon {
  color: #0064fe;
}
.mm-horizontal-tree__column--disabled {
  background: #f2f3f5;
  cursor: not-allowed;
}
.mm-horizontal-tree--loading .mm-horizontal-tree__column {
  position: relative;
  overflow-x: hidden;
}
.mm-horizontal-tree--loading .mm-horizontal-tree__column .mm-horizontal-tree__spinner {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mm-horizontal-tree--loading .mm-horizontal-tree__column .mm-horizontal-tree__spinner .m-spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mm-horizontal-tree__column:not(:last-child) {
  margin-right: 0.5rem;
}
.mm-horizontal-tree__column:last-child {
  background: #ffffff;
}

/* Base colors */
.mm-editable-table .m-typeahead .m-select-options,
.mm-editable-table .m-typeahead .dropdown-menu {
  display: none;
}
.mm-editable-table .ngx-datatable.sms-table, .mm-editable-table .ngx-datatable.chart-legend-table, .mm-editable-table .ngx-datatable.sms-table.material {
  overflow: visible;
}
.mm-editable-table .ngx-datatable.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell, .mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell,
.mm-editable-table .ngx-datatable.sms-table.material .datatable-header .datatable-header-inner .datatable-header-cell,
.mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-header .datatable-header-inner .datatable-header-cell {
  padding: 0.5rem 0.25rem;
}
.mm-editable-table .ngx-datatable.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell:first-child, .mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell:first-child,
.mm-editable-table .ngx-datatable.sms-table.material .datatable-header .datatable-header-inner .datatable-header-cell:first-child,
.mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-header .datatable-header-inner .datatable-header-cell:first-child {
  padding-left: 0.5rem;
}
.mm-editable-table .ngx-datatable.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell:last-child, .mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell:last-child,
.mm-editable-table .ngx-datatable.sms-table.material .datatable-header .datatable-header-inner .datatable-header-cell:last-child,
.mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-header .datatable-header-inner .datatable-header-cell:last-child {
  padding-right: 0.5rem;
}
.mm-editable-table .ngx-datatable.sms-table.material .datatable-body .datatable-body-row .datatable-body-cell.hidden, .mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-body .datatable-body-row .datatable-body-cell.hidden,
.mm-editable-table .ngx-datatable.sms-table.material .datatable-header .datatable-header-inner .datatable-header-cell.hidden,
.mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-header .datatable-header-inner .datatable-header-cell.hidden {
  padding: 0;
}
.mm-editable-table .ngx-datatable.sms-table.material .datatable-body .datatable-body-row.editing, .mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-body .datatable-body-row.editing {
  background: #f2f7ff;
}
.mm-editable-table .ngx-datatable.sms-table.material .datatable-body .datatable-body-row.editing .datatable-body-cell, .mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-body .datatable-body-row.editing .datatable-body-cell {
  overflow: visible !important;
}
.mm-editable-table .ngx-datatable.sms-table.material .datatable-body .datatable-body-row.editing .datatable-body-cell .m-typeahead .m-select-options, .mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-body .datatable-body-row.editing .datatable-body-cell .m-typeahead .m-select-options, .mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-body .datatable-body-row.editing .datatable-body-cell .m-typeahead .dropdown-menu,
.mm-editable-table .ngx-datatable.sms-table.material .datatable-body .datatable-body-row.editing .datatable-body-cell .m-typeahead .dropdown-menu {
  display: block;
  z-index: 100;
}
.mm-editable-table .ngx-datatable.sms-table.material .datatable-body .datatable-body-row.clickable .datatable-body-cell, .mm-editable-table .ngx-datatable.material.chart-legend-table .datatable-body .datatable-body-row.clickable .datatable-body-cell {
  cursor: pointer;
}
.mm-editable-table .m-select-chevron {
  height: 1.75rem;
}
.mm-editable-table .m-select-input {
  height: 1.75rem;
  padding-right: 1.5rem;
}
.mm-editable-table .m-input-element {
  height: 1.75rem;
}
.mm-editable-table .m-button-small {
  width: 100%;
  padding: 0.3125rem 1rem;
}
.mm-editable-table__datepicker .m-input-iconLink {
  padding: 0.2rem 0.4375rem 0.375rem;
}
.mm-editable-table__toggleButton .sb-toggleButton {
  margin: -2rem 0;
  display: inline-block;
}
.mm-editable-table__cell-control {
  height: 1.75rem;
}
.mm-editable-table__cell-control-input {
  display: none;
}
.mm-editable-table__edit-btns-wrap {
  display: flex;
  justify-content: space-around;
}
.mm-editable-table__edit-btn {
  width: 1.75rem;
  padding: 3px 7px 2px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.125rem;
}
.mm-editable-table__edit-btn--accept {
  padding: 7px;
  background: #0064fe;
}
.mm-editable-table__edit-btn--cancel {
  padding: 7px;
  background: #e6f0ff;
}
.mm-editable-table__edit-btn:disabled {
  cursor: not-allowed;
}
.mm-editable-table__edit-btn:disabled svg {
  color: #667284 !important;
}
.mm-editable-table__edit-btn svg {
  display: block;
  width: 0.875rem;
  height: 0.875rem;
}
.mm-editable-table__footer--button {
  margin-top: 0.4375rem;
  padding-bottom: 0.7rem;
  border-bottom: 1px solid #ccd0d6;
}
.mm-editable-table__footer--button mm-link-icon {
  font-size: 0.75rem;
}

.custom-donut-chart-wrapper {
  position: relative;
}
.custom-donut-chart-wrapper .custom-donut-total-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
}
.custom-donut-chart-wrapper .custom-donut-table-wrapper {
  position: absolute;
}

ngx-tooltip-content.ngx-charts-tooltip-content {
  padding: 0 !important;
}
ngx-tooltip-content.ngx-charts-tooltip-content .tooltip-content {
  padding: 0.5rem;
  max-width: 16.5rem;
  color: white;
  text-align: left;
  background-color: black;
  border-radius: 0.25rem;
}

.quick-filters {
  margin: 0 -0.25rem;
  display: flex;
  flex-wrap: wrap;
}
.quick-filters__filter {
  margin: 0 0.25rem 0.5rem 0.25rem;
  padding: 0.5625rem 0.625rem;
  border: 1px solid #ccd0d6;
  border-radius: 0.125rem;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 1;
  color: #0070f5;
}
.quick-filters__filter--active {
  font-weight: bold;
  color: #002d72;
  border-color: #0064fe;
}
.quick-filters--extended .quick-filters__filter {
  border-width: 2px;
  padding: 1rem;
  font-size: 0.8125rem;
  width: 16rem;
  height: 6rem;
  font-weight: bold;
}
.quick-filters__additional-label {
  color: #001432;
}
.quick-filters__summary {
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.33;
  margin: 1.5rem 0 0;
  color: #002d72;
}

.m-brief-panel {
  display: flex;
  border-radius: 2px;
  border: 1px solid #ccd0d6;
}

.m-brief-container {
  display: flex;
  align-items: stretch;
  padding: 1rem 0;
  flex-basis: 25%;
  flex-grow: 1;
}
.m-brief-container__inner {
  width: 100%;
  padding: 0 1rem;
  border-left: 1px solid #ccd0d6;
}
.m-brief-container:first-child .m-brief-container__inner {
  border-left: 0;
}
.m-brief-container__delimiter-full {
  border-left: 1px solid #ccd0d6;
  border-right: 1px solid #ccd0d6;
}
.m-brief-container__delimiter-full:first-child {
  border-left: 0;
}
.m-brief-container__delimiter-full:last-child {
  border-right: 0;
}
.m-brief-container__delimiter-full + .m-brief-container__delimiter-full {
  border-left: 0;
}
.m-brief-container__delimiter-full .m-brief-container__inner {
  border-left: 0;
}
.m-brief-container__delimiter-full + .m-brief-container .m-brief-container__inner {
  border-left: 0;
}

.m-switch-input {
  display: block;
  position: relative;
}
.m-switch-input.ng-dirty.ng-invalid input {
  box-shadow: 0 0 0 2px #eb8676;
}
.m-switch-input__current-mode {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.2;
  color: #0064fe;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mm-expandable-message {
  border-radius: 0.3125rem;
}
.mm-expandable-message__header {
  cursor: pointer;
  padding: 0.3125rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.mm-expandable-message__header__title {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 0.625rem;
  column-gap: 0.625rem;
  align-items: center;
}
.mm-expandable-message__header__icon {
  width: 1.25rem;
}
.mm-expandable-message__content {
  overflow: hidden;
  cursor: default;
}
.mm-expandable-message__content.ml-30 {
  margin-left: 1.875rem;
}
.mm-expandable-message__content.expanded {
  padding: 0.3125rem;
  max-height: 500px;
}
.mm-expandable-message__content.collapsed {
  padding: 0;
  max-height: 0;
}

.mm-action-card {
  border: 2px solid #ccd0d6;
  border-radius: 2px;
  color: #002d72;
  padding: 1.5rem;
  margin-bottom: 1rem;
}
.mm-action-card--focused {
  border: 2px solid #ff9f00;
}
.mm-action-card .mm-action-card__header {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}
.mm-action-card .mm-action-card__subtitle {
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  color: #001432;
}

.legend-position-top .ngx-charts-outer {
  display: flex;
  flex-direction: column-reverse;
}
.legend-position-top .ngx-charts-outer ngx-charts-legend ul.legend-labels {
  display: flex;
}

.legend-position-left .ngx-charts-outer {
  display: flex;
  flex-direction: row-reverse;
}
.legend-position-left .ngx-charts-outer ngx-charts-legend {
  display: flex;
  max-width: 16%;
}

.legend-labels-position-right ngx-charts-legend ul.legend-labels {
  display: flex;
  background-color: unset;
  justify-content: flex-end;
}

.m-mixed-input.ng-dirty.ng-invalid input,
.m-mixed-input.ng-dirty.ng-invalid select {
  box-shadow: 0 0 0 2px #eb8676;
}
.m-mixed-input__select--is-not-selected select,
.m-mixed-input__select--is-not-selected select:focus {
  color: #99a1ad;
  font-style: italic;
}

.mm-figure-badge {
  display: inline-flex;
  align-items: center;
  height: 1rem;
  border-radius: 2px;
  border: solid 1px;
  cursor: default;
}
.mm-figure-badge__icon {
  display: flex;
  height: 100%;
  width: 1.25rem;
}
.mm-figure-badge__icon mm-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
}
.mm-figure-badge__icon mm-icon svg {
  height: 0.5rem;
}
.mm-figure-badge__label {
  line-height: 0.75rem;
  padding: 0.0625rem 0.25em;
  font-size: 0.6875rem;
}

.m-overlay-panel {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  height: 100%;
  width: 100%;
  z-index: 60;
  background-color: white;
}
.m-overlay-panel--is-right {
  box-shadow: -4px 0 4px 0 rgba(229, 229, 229, 0.4);
}
.m-overlay-panel--is-left {
  box-shadow: 4px 0 4px 0 rgba(229, 229, 229, 0.4);
}

.m-bullet {
  position: absolute;
  top: -0.25rem;
  right: 0.5rem;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 70px;
  background: #de351b;
}

.mm-boxed-label {
  display: inline-block;
}
.mm-boxed-label__title {
  display: inline-block;
  padding: 0.25rem 0.3125rem;
}

.m-icon-list-item {
  display: flex;
  line-height: 1rem;
  font-size: 0.75rem;
}
.m-icon-list-item__icon-container {
  min-width: 2rem;
}
.m-icon-list-item__line-through {
  text-decoration: line-through;
}
.m-icon-list-item mm-icon {
  display: inline-block;
}

.m-icons-list {
  display: block;
}
.m-icons-list__item:not(:last-child) {
  margin-bottom: 0.5rem;
}

/**
 * _functions.scss partial
 *
 * Contains global functions.
 */
/* Base colors */
.m-side-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 17rem;
  background: #ffffff;
}
.m-side-panel__title-wrap {
  position: relative;
  min-height: 1.375rem;
  padding-right: 1.875rem;
  margin-bottom: 0.75rem;
}
.m-side-panel mm-link-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.m .m-side-panel__title {
  margin: 0;
  font-size: 0.8125rem;
}
.m-side-panel .m-overlay-panel {
  overflow: auto;
}
.m-side-panel .m-bar {
  align-items: flex-start;
}

.m-skeleton-loader {
  margin: 1rem 0;
  display: block;
  overflow: hidden;
}
.m-skeleton-loader__list-paragraph, .m-skeleton-loader__title {
  background: #f2f3f5;
}
.m-skeleton-loader__title {
  margin-bottom: 1.5rem;
  width: 40%;
}
.m-skeleton-loader__list-paragraph {
  width: 100%;
  list-style: none;
}
.m-skeleton-loader__list-paragraph + li {
  margin-top: 1rem;
}
.m-skeleton-loader--animated .m-skeleton-loader__list-paragraph,
.m-skeleton-loader--animated .m-skeleton-loader__title {
  background: linear-gradient(90deg, #f2f3f5 25%, #e6e8eb 37%, #f2f3f5 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
}
.m-skeleton-loader--round .m-skeleton-loader__list-paragraph,
.m-skeleton-loader--round .m-skeleton-loader__title {
  border-radius: 6.25rem;
}
.m-skeleton-loader--small .m-skeleton-loader__list-paragraph {
  height: 0.75rem;
}
.m-skeleton-loader--small .m-skeleton-loader__title {
  height: 1rem;
}
.m-skeleton-loader--medium .m-skeleton-loader__list-paragraph {
  height: 1rem;
}
.m-skeleton-loader--medium .m-skeleton-loader__title {
  height: 1.25rem;
}
.m-skeleton-loader--large .m-skeleton-loader__list-paragraph {
  height: 1.5rem;
}
.m-skeleton-loader--large .m-skeleton-loader__title {
  height: 1.75rem;
}
.m-skeleton-loader--xlarge .m-skeleton-loader__list-paragraph {
  height: 2rem;
}
.m-skeleton-loader--xlarge .m-skeleton-loader__title {
  height: 3rem;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}
.m-horizontal-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: #002d72;
  height: 2rem;
  z-index: 50;
}
.m-horizontal-header .hider {
  display: block;
  width: 1rem;
  height: 1.25rem;
  overflow: hidden;
}
.m-horizontal-header .m-bullet {
  top: 0.25rem;
  right: -0.25rem;
  height: 0.375rem;
  width: 0.375rem;
  border-radius: 2.1875rem;
}
.m-horizontal-header__logo {
  padding: 0 0.75rem;
  background-color: #002d72;
}
.m-horizontal-header__logo-img {
  height: 1.25rem;
  width: 5.5rem;
}
.m-horizontal-header__nav {
  background-color: #002d72;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}
.m-horizontal-header__nav--left, .m-horizontal-header__nav--right {
  display: flex;
  flex-direction: row;
}
.m-horizontal-header__nav--left .m-horizontal-header-nav-item__name + .m-horizontal-header-nav-item__icon-container {
  margin-left: 0.25rem;
}
.m-horizontal-header__nav--right .m-horizontal-header-nav-item__link {
  flex-direction: row-reverse;
}
.m-horizontal-header__nav--right .m-horizontal-header-nav-item__name + .m-horizontal-header-nav-item__icon-container {
  margin-right: 0.25rem;
}
.m-horizontal-header-nav-item {
  align-items: center;
  position: relative;
}
.m-horizontal-header-nav-item__name {
  color: #ffffff;
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
}
.m-horizontal-header-nav-item__icon-container {
  position: relative;
}
.m-horizontal-header-nav-item__icon {
  width: 1rem;
  height: 2rem;
}
html:not(.touchevents) .m-horizontal-header-nav-item:focus {
  color: #ffffff;
  outline: none;
}
.m-horizontal-header-nav-item__extra-link {
  border-radius: 2px;
  border: solid 1px #ffffff;
  background-color: transparent;
  padding: 0.0625rem 0.375rem;
  font-size: 0.5625rem;
  margin: 0 0 0 0.5rem;
}
.m-horizontal-header-nav-item__link {
  align-items: center;
  display: flex;
  text-decoration: none;
  height: 2rem;
  color: #e6f0ff;
  overflow: hidden;
  padding: 0 0.5rem;
}
.m-horizontal-header-nav-item__link--is-active {
  color: #ffffff;
  background-color: #001b44;
}
html:not(.touchevents) .m-horizontal-header-nav-item__link:focus {
  text-decoration: none;
}
html:not(.touchevents) .m-horizontal-header-nav-item__link:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #001b44;
}
.m-horizontal-header__navigation-window {
  cursor: pointer;
}
.m-horizontal-header__navigation-window-container.m-popover.popover {
  overflow: visible;
  min-height: 12rem;
  width: 15rem;
  margin-top: 0.25rem;
}
.m-horizontal-header__navigation-window-container.m-popover.popover .arrow {
  display: block;
}
.m-horizontal-header__navigation-window-container.m-popover.popover .arrow::before {
  border-bottom: none;
}

.m-side-menu__title {
  text-transform: uppercase;
  padding: 0.5rem 1.25rem 0.75rem 0.5rem;
}
.m-side-menu__sub-title {
  font-size: 0.75rem;
  text-transform: none;
  padding: 0.5rem;
}
.m-side-menu ::ng-deep .m-sidebarNavigation {
  margin: 0 0 1.5rem;
}
.m-side-menu__toggle-button {
  background: white;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  left: 9.4rem;
  top: 0.8rem;
  transform: rotate(0);
  z-index: 2;
}
@media (min-width: 1280px) {
  .m-side-menu__toggle-button {
    left: 11.65rem;
  }
}
.m-side-menu .m-sideGrid-sideNavigation {
  width: 10rem;
  transition: all ease-in-out 0.2s;
}
@media (min-width: 1280px) {
  .m-side-menu .m-sideGrid-sideNavigation {
    width: 12.25rem;
  }
}
.m-side-menu--is-closed .m-sideGrid-sideNavigation {
  margin-left: -9rem;
  position: relative;
  overflow: hidden;
}
@media (min-width: 1280px) {
  .m-side-menu--is-closed .m-sideGrid-sideNavigation {
    margin-left: -11rem;
  }
}
.m-side-menu--is-closed .m-side-menu__toggle-button {
  left: 0.4rem;
  transform: rotate(180deg);
}
@media (min-width: 1280px) {
  .m-side-menu--is-closed .m-side-menu__toggle-button {
    left: 0.6rem;
  }
}

.mm-table {
  display: block;
  position: relative;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #001432;
}
.mm-table--horizontal-scroll .mm-table__header,
.mm-table--horizontal-scroll .mm-table__body {
  overflow-x: auto;
}
.mm-table--horizontal-scroll .mm-table__header {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.mm-table--horizontal-scroll .mm-table__header::-webkit-scrollbar {
  display: none;
}
.mm-table__header, .mm-table__row {
  display: flex;
}
.mm-table__cell {
  flex: 1 1 0;
  width: 0;
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid #ccd0d6;
}
.mm-table__header {
  border-bottom: 2px solid #002d72;
  background-color: #ffffff;
}
.mm-table__header .mm-table__cell {
  font-weight: bold;
  color: #002d72;
}
.mm-table--sticky-header .mm-table__header {
  position: sticky;
  top: 0;
}
.mm-table__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.75rem;
  min-height: 4rem;
  background: #ffffff;
}
.mm-table__footer--with-meta {
  justify-content: space-between;
}
.mm-table--sticky-footer .mm-table__footer {
  position: sticky;
  bottom: 0;
}
.mm-table__meta {
  display: flex;
  align-items: center;
}
.mm-table__divider {
  margin: 0 0.25rem;
  height: 0.75rem;
  border-left: 1px solid #002d72;
}
.mm-table__tooltip-hidden {
  visibility: hidden;
}

.mm-sorter {
  margin-left: 0.25rem;
  padding: 0.25rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mm-progress {
  position: relative;
  display: block;
  margin-bottom: -0.3125rem;
}
.mm-table--sticky-progress .mm-progress {
  position: sticky;
  top: 0;
}
.mm-progress__container {
  background-color: #e6f0ff;
}
.mm-progress__bar {
  height: 0.3125rem;
  background-color: #002d72;
  animation: progress-animation 1.9s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
}
@keyframes progress-animation {
  0% {
    opacity: 0.6;
    transform: translateX(-35%) scale(0.3, 1);
  }
  15% {
    opacity: 1;
  }
  30% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: translateX(50%) scale(0, 1);
  }
}

.mm-pager {
  display: flex;
}
.mm-pager__page {
  padding: 0 0.5rem;
  color: #001432;
  line-height: 1.375rem;
  font-size: 0.75rem;
  border-radius: 3px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.mm-pager__page--active {
  font-weight: bold;
  background: #e6f0ff;
}
.mm-pager__page:hover {
  background-color: #f2f7ff;
}
.mm-pager__go-to-prev, .mm-pager__go-to-next, .mm-pager__go-to-first, .mm-pager__go-to-last {
  cursor: pointer;
  padding: 0 0.5rem;
}
.mm-pager__go-to-prev::before, .mm-pager__go-to-next::before, .mm-pager__go-to-first::before, .mm-pager__go-to-last::before {
  display: block;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  width: 1rem;
  height: 1.375rem;
}
.mm-pager__go-to-first::before, .mm-pager__go-to-last::before {
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2716px%27%20height%3D%2716px%27%20viewBox%3D%270%200%2016%2016%27%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%3E%3Cpath%20fill%3D%27rgb(0%2C100%2C254)%27%20d%3D%27M9.75462993%2C7.9999971%20L13.331284%2C11.9331911%20C13.5381137%2C12.1161559%2013.5574601%2C12.4321467%2013.3744954%2C12.6389764%20C13.1915307%2C12.845806%2012.8755399%2C12.8651524%2012.6687102%2C12.6821877%20L8.66871016%2C8.37449538%20C8.44375942%2C8.17550049%208.44375942%2C7.82449371%208.66871016%2C7.62549882%20C11.1974904%2C4.87568043%2012.5308237%2C3.439783%2012.6687102%2C3.31780651%20C12.8755399%2C3.13484179%2013.1915307%2C3.15418816%2013.3744954%2C3.36101785%20C13.5574601%2C3.56784754%2013.5381137%2C3.88383835%2013.331284%2C4.06680308%20L9.75462993%2C7.9999971%20Z%20M4.75462993%2C7.9999971%20L8.33128404%2C11.9331911%20C8.53811373%2C12.1161559%208.55746011%2C12.4321467%208.37449538%2C12.6389764%20C8.19153066%2C12.845806%207.87553985%2C12.8651524%207.66871016%2C12.6821877%20L3.66871016%2C8.37449538%20C3.44375942%2C8.17550049%203.44375942%2C7.82449371%203.66871016%2C7.62549882%20C6.19749037%2C4.87568043%207.5308237%2C3.439783%207.66871016%2C3.31780651%20C7.87553985%2C3.13484179%208.19153066%2C3.15418816%208.37449538%2C3.36101785%20C8.55746011%2C3.56784754%208.53811373%2C3.88383835%208.33128404%2C4.06680308%20L4.75462993%2C7.9999971%20Z%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E%0A");
}
.mm-pager__go-to-first--disabled, .mm-pager__go-to-last--disabled {
  pointer-events: none;
}
.mm-pager__go-to-first--disabled::before, .mm-pager__go-to-last--disabled::before {
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2716px%27%20height%3D%2716px%27%20viewBox%3D%270%200%2016%2016%27%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%3E%3Cpath%20fill%3D%27rgb(153%2C161%2C173)%27%20d%3D%27M9.75462993%2C7.9999971%20L13.331284%2C11.9331911%20C13.5381137%2C12.1161559%2013.5574601%2C12.4321467%2013.3744954%2C12.6389764%20C13.1915307%2C12.845806%2012.8755399%2C12.8651524%2012.6687102%2C12.6821877%20L8.66871016%2C8.37449538%20C8.44375942%2C8.17550049%208.44375942%2C7.82449371%208.66871016%2C7.62549882%20C11.1974904%2C4.87568043%2012.5308237%2C3.439783%2012.6687102%2C3.31780651%20C12.8755399%2C3.13484179%2013.1915307%2C3.15418816%2013.3744954%2C3.36101785%20C13.5574601%2C3.56784754%2013.5381137%2C3.88383835%2013.331284%2C4.06680308%20L9.75462993%2C7.9999971%20Z%20M4.75462993%2C7.9999971%20L8.33128404%2C11.9331911%20C8.53811373%2C12.1161559%208.55746011%2C12.4321467%208.37449538%2C12.6389764%20C8.19153066%2C12.845806%207.87553985%2C12.8651524%207.66871016%2C12.6821877%20L3.66871016%2C8.37449538%20C3.44375942%2C8.17550049%203.44375942%2C7.82449371%203.66871016%2C7.62549882%20C6.19749037%2C4.87568043%207.5308237%2C3.439783%207.66871016%2C3.31780651%20C7.87553985%2C3.13484179%208.19153066%2C3.15418816%208.37449538%2C3.36101785%20C8.55746011%2C3.56784754%208.53811373%2C3.88383835%208.33128404%2C4.06680308%20L4.75462993%2C7.9999971%20Z%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E%0A%0A");
}
.mm-pager__go-to-last::before {
  transform: rotate(180deg);
}
.mm-pager__go-to-prev::before, .mm-pager__go-to-next::before {
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2716px%27%20height%3D%2716px%27%20viewBox%3D%270%200%2016%2016%27%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%3E%3Cpath%20fill%3D%27rgb(0%2C100%2C254)%27%20d%3D%27M8%2C9.24536718%20L4.06680597%2C5.66871306%20C3.88384125%2C5.46188337%203.56785044%2C5.44253699%203.36102075%2C5.62550172%20C3.15419106%2C5.80846644%203.13484469%2C6.12445725%203.31780941%2C6.33128694%20C3.43978589%2C6.4691734%204.87568333%2C7.80250673%207.62550172%2C10.3312869%20C7.82449661%2C10.5562377%208.17550339%2C10.5562377%208.37449828%2C10.3312869%20L12.6821906%2C6.33128694%20C12.8651553%2C6.12445725%2012.8458089%2C5.80846644%2012.6389792%2C5.62550172%20C12.4321496%2C5.44253699%2012.1161588%2C5.46188337%2011.933194%2C5.66871306%20L8%2C9.24536718%20Z%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E%0A%0A");
}
.mm-pager__go-to-prev--disabled, .mm-pager__go-to-next--disabled {
  pointer-events: none;
}
.mm-pager__go-to-prev--disabled::before, .mm-pager__go-to-next--disabled::before {
  background-image: url("data:image/svg+xml, %3Csvg%20width%3D%2716px%27%20height%3D%2716px%27%20viewBox%3D%270%200%2016%2016%27%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%27%3E%3Cpath%20fill%3D%27rgb(153%2C161%2C173)%27%20d%3D%27M8%2C9.24536718%20L4.06680597%2C5.66871306%20C3.88384125%2C5.46188337%203.56785044%2C5.44253699%203.36102075%2C5.62550172%20C3.15419106%2C5.80846644%203.13484469%2C6.12445725%203.31780941%2C6.33128694%20C3.43978589%2C6.4691734%204.87568333%2C7.80250673%207.62550172%2C10.3312869%20C7.82449661%2C10.5562377%208.17550339%2C10.5562377%208.37449828%2C10.3312869%20L12.6821906%2C6.33128694%20C12.8651553%2C6.12445725%2012.8458089%2C5.80846644%2012.6389792%2C5.62550172%20C12.4321496%2C5.44253699%2012.1161588%2C5.46188337%2011.933194%2C5.66871306%20L8%2C9.24536718%20Z%27%3E%3C%2Fpath%3E%3C%2Fsvg%3E%0A%0A%0A");
}
.mm-pager__go-to-prev::before {
  transform: rotate(90deg);
}
.mm-pager__go-to-next::before {
  transform: rotate(-90deg);
}

.mm-limit-selector {
  display: inline-block;
}
.mm-limit-selector__title {
  margin-right: 0.5rem;
}
.mm-limit-selector__button {
  position: relative;
  padding: 0;
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  margin-right: 0.5rem;
  cursor: pointer;
  border: 0;
  background: transparent;
}
.mm-limit-selector__button:hover::after, .mm-limit-selector__button--active::after {
  content: "";
  position: absolute;
  top: 102%;
  left: 0;
  width: 100%;
  height: 0.125rem;
  background-color: #0064fe;
}
.mm-limit-selector__button--active {
  font-weight: bold;
}

.mm-accordion__header {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 0.3125rem;
  column-gap: 0.3125rem;
  cursor: pointer;
  align-items: center;
}
.mm-accordion__header__icon {
  display: flex;
}
.mm-accordion__header p {
  font-weight: bold;
}
.mm-accordion__content {
  margin-left: 1.875rem;
}
.mm-accordion__content__container {
  overflow: hidden;
  text-overflow: ellipsis;
}
.mm-accordion p {
  margin: 0;
  color: #002d72;
  font-size: 0.8125rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
 * _trumps.scss partial
 *
 * Contains utilities and helper classes with ability to override anything which goes before the triangle, e.g. hide
 * helper class
 */
.vcenter {
  display: flex;
  align-items: center;
}

.passwordHints {
  background-color: #f2f3f5;
  padding: 16px;
}

.passwordHints-headline {
  font-size: 0.875rem;
  font-weight: bold;
  color: #002d72;
}

.passwordHints-list {
  list-style: none;
  font-size: 0.8125rem;
  line-height: 1.25rem;
}

.inlineFormContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt4 {
  margin-top: 4rem;
}